<template>
  <div>
    <div class="box bg-blue text-white">
      <div class="">
        <h2>Gegevens 1<sup>e</sup> aanvraagperiode (NOW 1)</h2>

        <div class="form-calculator">
          <input autocomplete="false" name="hidden" type="text" class="display-none" readonly>

          <div class="mb-5">
            <b>Let op:</b> Voor de 1<sup>e</sup> aanvraagperiode NOW kunt u geen definitieve berekening meer aanvragen.

            <ModalWithButton title="Loket definitieve berekening gesloten" image="info">
              <p>
                Voor de 1<sup>e</sup> aanvraagperiode NOW kunt u geen definitieve berekening meer aanvragen.
                Als u nog geen definitieve berekening heeft aangevraagd, moet u het ontvangen voorschot terugbetalen.
                <span v-if="!gteJul27_2023">
                  <br /><br />
                  In uitzonderlijke gevallen kan een werkgever extra tijd krijgen om een definitieve berekening aan te vragen.
                  UWV neemt met die werkgevers contact op.
                </span>
              </p>
            </ModalWithButton>
          </div>

          <!-- Loonsom januari 2020 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="h9">
                Loonsom januari 2020 (of november 2019)
              </label>
              <ModalWithButton title="Loonsom januari 2020" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 maart 2020 bij de Belastingdienst bekend waren.</li>
                  <li>Als u over januari 2020 geen loonsom heeft opgegeven, dan geldt de loonsom van november 2019.</li>
                  <li>Er geldt een maximale loonsom per werknemer van &euro;&nbsp;9.538 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan voor de berekening van het voorschot de loonsom in het 1e aangiftetijdvak (in plaats van januari) of het 12e aangiftetijdvak (in plaats van november).
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="h9"> </label>
              <div :class="'input-group mb-2' + (error.h9 ? ' is-invalid' : '') + (valid.h9 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.h9 ? ' is-invalid' : '') + (valid.h9 ? ' is-valid' : '')" id="h9" placeholder=""
                       data-min="0" data-max="500000000" v-model="h9" autocomplete="off">
              </div>
              <!-- tonen als is-invalid gezet wordt -->
              <div class="invalid-feedback text-right mb-2">{{ error.h9 }}</div>
            </div>
          </div>

          <!-- Loonsom maart 2020 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j12">
                Loonsom maart 2020
              </label>
              <ModalWithButton title="Loonsom maart 2020" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 19 juli 2020 bij de Belastingdienst bekend zijn.</li>
                  <li>
                    Is de opgetelde loonsom van maart, april en mei hoger dan 3&times; de loonsom van januari?
                    Dan gaat UWV voor de loonsom van maart uit van de gegevens die op 15 mei 2020 bij de Belastingdienst bekend waren.
                    De loonsom van maart is in dat geval de maximale loonsom.
                    Dus als de loonsom van april en/of mei hoger is dan de loonsom van maart, dan geldt ook voor die maand(en) de loonsom van maart.
                  </li>
                  <li>Er geldt een maximale loonsom per werknemer van &euro;&nbsp;9.538 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan voor de berekening van het voorschot de loonsom in het 3e aangiftetijdvak.
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5">
              <label class="sr-only" for="j12"> </label>
              <div :class="'input-group mb-2' + (error.j12 ? ' is-invalid' : '') + (valid.j12 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.j12 ? ' is-invalid' : '') + (valid.j12 ? ' is-valid' : '')" id="j12" placeholder=""
                       data-min="0" data-max="500000000" v-model="j12" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j12 }}</div>
            </div>
          </div>

          <!-- Loonsom april 2020 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j13">
                Loonsom april 2020
              </label>
              <ModalWithButton title="Loonsom april 2020" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 19 juli 2020 bij de Belastingdienst bekend zijn.</li>
                  <li>Er geldt een maximale loonsom per werknemer van &euro;&nbsp;9.538 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan voor de berekening van het voorschot de loonsom in het 4e aangiftetijdvak.
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5">
              <label class="sr-only" for="j13"> </label>
              <div :class="'input-group mb-2' + (error.j13 ? ' is-invalid' : '') + (valid.j13 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.j13 ? ' is-invalid' : '') + (valid.j13 ? ' is-valid' : '')" id="j13" placeholder=""
                       data-min="0" data-max="500000000" v-model="j13" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j13 }}</div>
            </div>
          </div>

          <!-- Loonsom mei 2020 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j14">
                Loonsom mei 2020
              </label>
              <ModalWithButton title="Loonsom mei 2020" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 19 juli 2020 bij de Belastingdienst bekend zijn.</li>
                  <li>Er geldt een maximale loonsom per werknemer van &euro;&nbsp;9.538 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan voor de berekening van het voorschot de loonsom in het 5e aangiftetijdvak.
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="j14"> </label>
              <div :class="'input-group mb-2' + (error.j14 ? ' is-invalid' : '') + (valid.j14 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.j14 ? ' is-invalid' : '') + (valid.j14 ? ' is-valid' : '')" id="j14" placeholder=""
                       data-min="0" data-max="500000000" v-model="j14" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j14 }}</div>
            </div>
          </div>

          <!-- Ontvangen voorschot -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="e21">
                Totaal ontvangen voorschot
              </label>
              <ModalWithButton title="Hoogte voorschot" image="info">
                <p>
                  Als u recht had op een voorschot tegemoetkoming NOW in deze aanvraagperiode, heeft u daarover een beslissing ontvangen.
                  In die beslissing staat hoe hoog het totale voorschot is.
                </p>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="e21"> </label>
              <div :class="'input-group mb-2' + (error.e21 ? ' is-invalid' : '') + (valid.e21 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input id="e21" :class="'form-control numeric' + (error.e21 ? ' is-invalid' : '') + (valid.e21 ? ' is-valid' : '')" type="text" placeholder=""
                       data-min="0" data-max="500000000" v-model="e21" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.e21 }}</div>
            </div>
          </div>

          <!-- Werkelijke omzetverlies -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j6">
                Werkelijk omzetverlies
              </label>
              <ModalWithButton title="Werkelijk omzetverlies" image="info">
                <p>
                  Dit is het werkelijke percentage omzetverlies over de periode in uw aanvraag van de tegemoetkoming.
                  Het definitieve percentage weet u pas nadat uw accountant of boekhouder dat heeft vastgesteld.
                  Als u zelf een inschatting van het omzetverlies wilt maken, kunt u hiervoor de
                  <a target="_blank" href="https://www.rekenhulpomzetverlies.nl/">Rekenhulp omzetverlies</a> gebruiken.
                </p>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="j6"> </label>
              <div :class="'input-group mb-2' + (error.j6 ? ' is-invalid' : '') + (valid.j6 ? ' is-valid' : '')">
                <input type="text" :class="'form-control numeric' + (error.j6 ? ' is-invalid' : '') + (valid.j6 ? ' is-valid' : '')" id="j6" placeholder=""
                       data-min="0" data-max="100" v-model="j6" autocomplete="off">
                <div class="input-group-prepend">
                  <div class="input-group-text input-group-text-right">%</div>
                </div>
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j6 }}</div>
            </div>
          </div>

          <!-- Bedrijfseconomisch ontslag (vinkje) -->
          <div class="form-row indent">
            <div class="col-md-12">
              <label class="mt-1" for="bedrijfseconomisch_ontslag">
                <input type="checkbox" name="bedrijfseconomisch_ontslag"
                       id="bedrijfseconomisch_ontslag"
                       v-model="bedrijfseconomisch_ontslag"
                       autocomplete="off"
                /> &nbsp;
                Ik heb bedrijfseconomisch ontslag aangevraagd

                <ModalWithButton title="Ontslagaanvraag om bedrijfseconomische redenen" image="info">
                  <p>
                    Als u een aanvraag heeft gedaan voor ontslag om bedrijfseconomische redenen tussen 17 maart en 1 juni 2020,
                    dan wordt de tegemoetkoming verlaagd.
                  </p>
                  <p>
                    Om de verlaging te berekenen, neemt u de loonsom over januari 2020 van alle werknemers voor wie u bedrijfseconomisch ontslag heeft aangevraagd.
                    Dit bedrag wordt eerst vermenigvuldigd met 3 en daarna met 130% &times; 90% &times; 150%.
                    De uitkomst wordt van de tegemoetkoming afgehaald.
                  </p>
                </ModalWithButton>
              </label>
            </div>
          </div>

          <!-- BE Ontslag (bedrag) -->
          <div class="form-row" v-if="values.bedrijfseconomisch_ontslag">
            <div class="col-md-7">
              <label class="mt-1" for="r14">
                Loonsom bedrijfseconomisch ontslag
              </label>
              <ModalWithButton title="Totale loonsom van werknemers voor wie bedrijfseconomisch ontslag is aangevraagd" image="info">
                <ul>
                  <li>Neem van alle werknemers voor wie u ontslag om bedrijfseconomische redenen aanvraagt de loonsom over januari 2020.</li>
                  <li>Voor de berekening van deze loonsom gelden dezelfde voorwaarden als hierboven.</li>
                  <li>Tel de loonsommen bij elkaar op. Het opgetelde bedrag vult u hier in.</li>
                  <li>
                    Dit bedrag wordt eerst vermenigvuldigd met 3 en daarna met 130% &times; 90% &times; 150%.
                    De uitkomst wordt van de tegemoetkoming afgehaald.
                  </li>
                </ul>
                <p>
                  <b>Let op:</b> het gaat hier dus niet om werknemers van wie het tijdelijk dienstverband is beëindigd,
                  werknemers die met pensioen zijn gegaan of werknemers die vrijwillig zijn weggegaan.
                  De loonsom van die werknemers telt niet mee voor deze verlaging.
                </p>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-2">
              <label class="sr-only" for="r14"> </label>
              <div :class="'input-group mb-2' + (error.r14 ? ' is-invalid' : '') + (valid.r14 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.r14 ? ' is-invalid' : '') + (valid.r14 ? ' is-valid' : '')" id="r14" placeholder=""
                       data-min="0" data-max="500000000" v-model="r14" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.r14 }}</div>
            </div>
          </div>

          <!-- Onderdeel van concern? -->
          <div class="form-row indent">
            <div class="col-md-12">
              <label class="mt-1" for="werkmaatschappij_onderdeel_van_concern">
                <input type="checkbox" name="werkmaatschappij_onderdeel_van_concern"
                       id="werkmaatschappij_onderdeel_van_concern"
                       v-model="werkmaatschappij_onderdeel_van_concern"
                       autocomplete="off"
                /> &nbsp;
                Ik ga uit van het omzetverlies van mijn werkmaatschappij

                <ModalWithButton title="Aanvraag op werkmaatschappij-niveau" image="info">
                  <p>
                    Als u onderdeel bent van een groep of concern en het totale concern heeft minder dan 20% omzetverlies,
                    dan kunt u ervoor kiezen om een aanvraag te doen op werkmaatschappijniveau.
                    Bij de definitieve berekening gaat u dan uit van het omzetverlies van het groeps- of concernonderdeel (de werkmaatschappij) in plaats van het omzetverlies van de groep of het concern als geheel.
                  </p>

                  <p>
                    Bij een aanvraag op werkmaatschappijniveau gelden er extra voorwaarden.
                    Vraag uw accountant om meer informatie hierover of kijk op de <a target="_blank" href="https://www.nba.nl/nba-helpt/helpdesk-now-regeling/">website van de Koninklijke Nederlandse Beroepsorganisatie van Accountants (NBA)</a>.
                  </p>

                  <p>
                    Of u onderdeel bent van een concern of groep kunt u navragen bij uw accountant of financieel adviseur.
                  </p>
                </ModalWithButton>
              </label>

              <div v-if="werkmaatschappij_onderdeel_van_concern">
                Als u onderdeel bent van een groep of concern en een definitieve berekening aanvraagt op werkmaatschappijniveau, gelden er extra voorwaarden.
              </div>
            </div>
          </div>
        </div>

        <b-collapse v-model="resultsVisible" id="results">
          <b-card>
            <div class="end-result-calculator" id="end-result">
              <h2 class="inline">Uw tegemoetkoming volgens de ingevulde gegevens</h2>

              <p class="mb-1" v-if="output.h27_class == 'negative'">
                U moet <strong>&euro; {{ output.h27 }}</strong> terugbetalen.
              </p>
              <p class="mb-1" v-else>
                U heeft een bedrag van <strong>&euro; {{ output.h27 }}</strong> tegoed.
              </p>

              <!-- Meesturen verklaring? -->
              <div class="mb-1">
                <span id="declaration1" class="declaration" v-if="output.declaration_type == 1">U hoeft <strong>geen verklaring</strong> mee te sturen met uw aanvraag van de definitieve berekening.</span>
                <span id="declaration2" class="declaration" v-if="output.declaration_type == 2">U moet een <strong>derdenverklaring</strong> meesturen met uw aanvraag van de definitieve berekening.</span>
                <span id="declaration3" class="declaration" v-if="output.declaration_type == 3">U moet een <strong>accountantsverklaring</strong> meesturen met uw aanvraag van de definitieve berekening.</span>

                <ModalWithButton title="Moet u een verklaring meesturen?" image="info">
                  <p>In sommige situaties heeft UWV voor de definitieve berekening
                    van de tegemoetkoming een accountantsverklaring of
                    derdenverklaring nodig. Deze verklaring moet u meesturen met
                    de aanvraag van de definitieve berekening.</p>
                  <p>In de volgende situaties moet u een <strong>accountantsverklaring</strong>
                    meesturen:</p>
                  <ul>
                    <li>bij een voorschot van € 100.000 of meer;
                    </li>
                    <li>bij een definitieve tegemoetkoming van € 125.000 of meer
                      (ongeacht de hoogte van het betaalde voorschot);
                    </li>
                    <li>voor de werkgever die onderdeel is van een groep of
                      concern geldt: tel de bedragen van alle aanvragen (van
                      de loonheffingennummers) binnen de groep of het concern
                      bij elkaar op. Het totaalbedrag bepaalt of een
                      accountants- of derdenverklaring nodig is;
                    </li>
                    <li>bij een aanvraag NOW op werkmaatschappijniveau (omdat
                      het concern of de groep onder de 20% omzetverlies komt).
                      In deze situatie maakt het niet uit hoe hoog de
                      tegemoetkoming of het voorschot is, er is altijd een
                      accountantsverklaring nodig.
                    </li>
                  </ul>
                  <p>In de volgende situaties moet u een
                    <strong>derdenverklaring</strong>
                    meesturen:</p>
                  <ul>
                    <li>bij een voorschot tussen € 20.000 en € 100.000;
                    </li>
                    <li> bij een definitieve tegemoetkoming tussen € 25.000 en €
                      125.000 (ongeacht de hoogte van het voorschot).
                    </li>
                  </ul>
                </ModalWithButton>
              </div>

              <p v-if="output.settlement_period_passed">De aanvraag voor deze periode is al <strong>gesloten</strong>.</p>
              <p v-if="output.settlement_period_open">Uiterlijk <strong>{{ output.settlement_period_text }}</strong> kunt u een aanvraag definitieve berekening indienen.</p>
              <p v-if="!output.settlement_period_passed && !output.settlement_period_open">Vanaf <strong>{{ output.settlement_period_text }}</strong> kunt u een aanvraag definitieve berekening indienen.</p>
            </div><!-- /end-result-calculator -->

            <div :class="'details-box ' + (showDetails ? 'details-box-show' : '')">
              <b-button variant="success" @click="showDetails = !showDetails; $event.currentTarget.blur()">
                <span v-if="showDetails"><img class="icon-chevron-right-solid up-down" src="../../assets/icon/arrow-up.svg" alt="up" /> Verberg details</span>
                <span v-else><img class="icon-chevron-right-solid up-down" src="../../assets/icon/arrow-down.svg" alt="up" /> Toon details</span>
              </b-button>
              <b-collapse class="mt-1 pb-2" v-model="showDetails">
                <b-card>
                  <!-- (0a) werkelijke omzetverlies < 20%, alles terugbetalen -->
                  <b-collapse id="j6-lt-20" class="scenarios-calculation" v-model="scenariosCalculationVisible.j6_lt_20">
                    <b-card>
                      <p>
                        Uw heeft minder dan 20% omzetverlies.
                        In dat geval heeft u geen recht op een tegemoetkoming NOW.
                      </p>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e22-j6_lt_20">
                            Definitieve tegemoetkoming
                          </label>
                          <ModalWithButton title="Geen recht op tegemoetkoming" image="calc">
                            <p>
                              Uw omzetverlies is minder dan 20%.
                              In dat geval heeft u geen recht op een tegemoetkoming.
                              Daarom wordt de definitieve tegemoetkoming vastgesteld op &euro; 0.
                              Dat betekent dat u alles wat u aan voorschot heeft ontvangen, moet terugbetalen.
                            </p>

                            <div class="container-fluid px-0">
                              <div class="row">
                                <div class="col-8">Uw definitieve tegemoetkoming</div>
                                <div class="col-4">&euro; 0</div>
                              </div>
                            </div>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e22-j6_lt_20"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">&euro;</div>
                            </div>
                            <input id="e22-j6_lt_20" class="form-control" type="text" placeholder=""
                                   readonly value="0">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- loonsom mrt-mei20 hoger dan (of gelijk aan) 3x loonsom jan20/nov19 -->
                  <b-collapse id="gte" class="explain-calculation" v-model="scenariosCalculationVisible.gte">
                    <b-card>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e22-gte">
                            Definitieve tegemoetkoming
                          </label>
                          <ModalWithButton title="Berekening van de tegemoetkoming" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span id="gte-explain-j6">{{ output.gte_explain_j6 }}</span>%
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom van maart, april en mei
                                  2020 (gemaximeerd op loonsom maart)
                                </div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-j16">{{ output.gte_explain_j16 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 130%</div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-times-130">{{ output.gte_explain_times_130 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk
                                  omzetverliespercentage
                                </div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-times-j6">{{ output.gte_explain_times_j6 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-times-90">{{ output.gte_explain_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">De tegemoetkoming is dus:</div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-end">{{ output.gte_explain_end }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsommen van maart, april en
                                mei 2020. De voorwaarden voor deze bedragen staan hierboven.
                              </li>
                              <li>De gemaximeerde loonsommen van maart, april en mei
                                2020 worden bij elkaar opgeteld.
                              </li>
                              <li>Als de loonsom van april en/of mei hoger is dan de
                                loonsom van maart, dan geldt ook voor die maand(en)
                                de loonsom van maart.
                              </li>
                              <li>De opgetelde en gemaximeerde loonsom wordt verhoogd
                                met 30% (forfaitaire verhoging, om kosten als
                                werkgeverspremies, pensioenbijdragen en de opbouw
                                van vakantiegeld te compenseren).
                              </li>
                              <li>De verhoogde loonsom wordt vermenigvuldigd met het
                                percentage werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 90% daarvan.</li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e22-gte"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e22-gte" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e22_gte">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- loonsom mrt-mei20 hoger dan (of gelijk aan) 3x loonsom jan20/nov19 + BE-ontslag -->
                  <b-collapse id="gte-be" class="explain-calculation" v-model="scenariosCalculationVisible.gte_be">
                    <b-card>
                      <p>Omdat u tussen 17 maart en 1 juni 2020 voor een of meer werknemers
                        bedrijfseconomisch
                        ontslag heeft aangevraagd, wordt de tegemoetkoming verlaagd.</p>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="p25-gte-be">
                            Basisbedrag
                          </label>
                          <ModalWithButton title="Berekening van het basisbedrag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span id="gte-be-explain-1-j6">{{ output.gte_be_explain_1_j6 }}</span>%</div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom van maart, april en mei 2020 (gemaximeerd op loonsom maart)</div>
                                <div class="col-4">&euro; <span id="gte-be-explain-1-j16">{{ output.gte_be_explain_1_j16 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 130%</div>
                                <div class="col-4">&euro; <span id="gte-be-explain-1-times-130">{{ output.gte_be_explain_1_times_130 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk omzetverliespercentage</div>
                                <div class="col-4">&euro; <span id="gte-be-explain-1-times-j6">{{ output.gte_be_explain_1_times_j6 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="gte-be-explain-1-times-90">{{ output.gte_be_explain_1_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het basisbedrag is dus:</div>
                                <div class="col-4">&euro; <span id="gte-be-explain-1-end">{{ output.gte_be_explain_1_end }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsommen van maart, april en
                                mei 2020. De voorwaarden voor deze bedragen staan hierboven.
                              </li>
                              <li>De gemaximeerde loonsommen van maart, april en mei
                                2020 worden bij elkaar opgeteld.
                              </li>
                              <li>Als de loonsom van april en/of mei hoger is dan de
                                loonsom van maart, dan geldt ook voor die maand(en)
                                de loonsom van maart.
                              </li>
                              <li>De opgetelde en gemaximeerde loonsom wordt verhoogd
                                met 30% (forfaitaire verhoging, om kosten als
                                werkgeverspremies, pensioenbijdragen en de opbouw
                                van vakantiegeld te compenseren).
                              </li>
                              <li>De verhoogde loonsom wordt vermenigvuldigd met het
                                percentage werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 90% daarvan.</li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="p25-gte-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="p25-gte-be" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.p25_gte_be">
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="be-gte-be">
                            Verlaging wegens bedrijfseconomisch ontslag
                          </label>
                          <ModalWithButton title="Berekening van de verlaging wegens bedrijfseconomisch ontslag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Loonsom januari 2020 van alle
                                  werknemers voor wie bedrijfseconomisch ontslag is aangevraagd
                                </div>
                                <div class="col-4">&euro; <span id="gte-be-explain-2-r14">{{ output.gte_be_explain_2_r14 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span id="gte-be-explain-2-times-3">{{ output.gte_be_explain_2_times_3 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 130%</div>
                                <div class="col-4">&euro; <span id="gte-be-explain-2-times-130">{{ output.gte_be_explain_2_times_130 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="gte-be-explain-2-times-90">{{ output.gte_be_explain_2_times_90 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 150%</div>
                                <div class="col-4">&euro; <span id="gte-be-explain-2-times-150">{{ output.gte_be_explain_2_times_150 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het bedrag van de verlaging is
                                  dus:
                                </div>
                                <div class="col-4">&euro; <span id="gte-be-explain-2-end">{{ output.gte_be_explain_2_end }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom over januari 2020 (of november 2019) van alle
                                werknemers voor wie u tussen maart en mei 2020 bedrijfseconomisch ontslag
                                heeft aangevraagd.
                              </li>
                              <li>Voor de berekening van deze loonsom gelden dezelfde voorwaarden als hierboven.</li>
                              <li>Deze loonsom wordt eerst vermenigvuldigd met 3 en
                                daarna met 130% (forfaitaire verhoging) &times; 90%
                                (percentage van de tegemoetkoming) &times; 150%.
                              </li>
                              <li>
                                Het omzetverliespercentage wordt niet in deze berekening meegenomen.
                                Daardoor is dit verlagingsbedrag misschien hoger dan u had verwacht.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="be-gte-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="be-gte-be" :class="'form-control border-right-0 ' + output.be_gte_be_class"
                                   type="text" placeholder=""
                                   readonly v-model="output.be_gte_be">
                            <div class="input-group-append">
                              <span :class="'input-group-text ' + (output.be_gte_be_class || 'bg-disabled')">&minus;</span>
                            </div>
                          </div>

                          <hr class="computation-line">
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e22-gte-be">
                            Definitieve tegemoetkoming
                          </label>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e22-gte-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e22-gte-be" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e22_gte_be">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- loonsom mrt-mei20 lager dan 3x loonsom jan20/nov19 -->
                  <b-collapse id="lt" class="explain-calculation" v-model="scenariosCalculationVisible.lt">
                    <b-card>
                      <p>Uw opgetelde loonsom over maart, april en mei 2020 is lager dan 3&times; de
                        loonsom van
                        januari 2020 (of november 2019). Daarom wordt de tegemoetkoming verlaagd.</p>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="p25-lt">
                            Basisbedrag
                          </label>
                          <ModalWithButton title="Berekening van het basisbedrag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span id="lt-explain-1-j6">{{ output.lt_explain_1_j6 }}</span>%</div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom van januari 2020 (of
                                  november 2019)
                                </div>
                                <div class="col-4">&euro; <span id="lt-explain-1-h9">{{ output.lt_explain_1_h9 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span id="lt-explain-1-times-3">{{ output.lt_explain_1_times_3 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 130%</div>
                                <div class="col-4">&euro; <span id="lt-explain-1-times-130">{{ output.lt_explain_1_times_130 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk omzetverliespercentage</div>
                                <div class="col-4">&euro; <span id="lt-explain-1-times-j6">{{ output.lt_explain_1_times_j6 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="lt-explain-1-times-90">{{ output.lt_explain_1_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het basisbedrag is dus:</div>
                                <div class="col-4">&euro; <span id="lt-explain-1-p25">{{ output.lt_explain_1_p25 }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom van januari 2020 (of
                                november 2019). De voorwaarden voor dit bedrag staan hierboven.
                              </li>
                              <li>Omdat het gaat om een tegemoetkoming voor drie
                                maanden, wordt de gemaximeerde loonsom
                                vermenigvuldigd met 3.
                              </li>
                              <li>Het bedrag dat daaruit volgt, wordt verhoogd met 30%
                                (forfaitaire verhoging, om kosten als
                                werkgeverspremies, pensioenbijdragen en de opbouw
                                van vakantiegeld te compenseren).
                              </li>
                              <li>Het verhoogde bedrag wordt vermenigvuldigd met het
                                percentage werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 90% daarvan.</li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="p25-lt"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="p25-lt" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.p25_lt">
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="p26-lt">
                            Verlaging wegens een lagere loonsom
                          </label>
                          <ModalWithButton title="Berekening van de verlaging wegens een lagere loonsom" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Loonsom januari 2020 (of november 2019)</div>
                                <div class="col-4">&euro; <span id="lt-explain-2-j9">{{ output.lt_explain_2_j9 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span id="lt-explain-2-p20">{{ output.lt_explain_2_p20 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom van maart, april en mei
                                  2020
                                </div>
                                <div class="col-4">&euro; <span id="lt-explain-2-p21">{{ output.lt_explain_2_p21 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">Verschil</div>
                                <div class="col-4">&euro; <span id="lt-explain-2-p22">{{ output.lt_explain_2_p22 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 130%</div>
                                <div class="col-4">&euro; <span id="lt-explain-2-times-130">{{ output.lt_explain_2_times_130 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="lt-explain-2-times-90">{{ output.lt_explain_2_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het bedrag van de verlaging is
                                  dus:
                                </div>
                                <div class="col-4">&euro; <span id="lt-explain-2-p26">{{ output.lt_explain_2_p26 }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij nemen het verschil tussen 3&times; de loonsom
                                van
                                januari 2020 (of november 2019) en de opgetelde
                                loonsommen van maart, april en mei 2020.
                              </li>
                              <li>Het verschil wordt vervolgens vermenigvuldigd met 130% (forfaitaire verhoging)
                                &times; 90% (percentage van de tegemoetkoming).
                              </li>
                              <li>
                                Het omzetverliespercentage wordt niet in deze berekening meegenomen.
                                Daardoor is dit verlagingsbedrag misschien hoger dan u had verwacht.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="p26-lt"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="p26-lt" :class="'form-control border-right-0 ' + output.p26_lt_class"
                                   type="text" placeholder=""
                                   readonly v-model="output.p26_lt">
                            <div class="input-group-append">
                              <span :class="'input-group-text ' + (output.p26_lt_class || 'bg-disabled')">&minus;</span>
                            </div>
                          </div>

                          <hr class="computation-line">
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e22-lt">
                            Definitieve tegemoetkoming
                          </label>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e22-lt"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e22-lt" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e22_lt">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- loonsom mrt-mei lager dan 3x loonsom jan20/nov19 + BE-ontslag -->
                  <b-collapse id="lt-be" class="explain-calculation" v-model="scenariosCalculationVisible.lt_be">
                    <b-card>
                      <p>De tegemoetkoming wordt verlaagd om twee redenen:</p>
                      <ul>
                        <li>Uw opgetelde loonsom over maart, april en mei 2020 is lager dan 3&times; de loonsom van januari 2020 (of november 2019).</li>
                        <li>U heeft tussen 17 maart en 1 juni 2020 voor een of meer werknemers bedrijfseconomisch ontslag aangevraagd.</li>
                      </ul>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="p25-lt-be">
                            Basisbedrag
                          </label>
                          <ModalWithButton title="Berekening van het basisbedrag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span id="lt-be-explain-1-j6">{{ output.lt_be_explain_1_j6 }}</span>%</div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom van januari 2020 (of
                                  november 2019)
                                </div>
                                <div class="col-4">&euro; <span id="lt-be-explain-1-h9">{{ output.lt_be_explain_1_h9 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-1-times-3">{{ output.lt_be_explain_1_times_3 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 130%</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-1-times-130">{{ output.lt_be_explain_1_times_130 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk
                                  omzetverliespercentage
                                </div>
                                <div class="col-4">&euro; <span id="lt-be-explain-1-times-j6">{{ output.lt_be_explain_1_times_j6 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-1-times-90">{{ output.lt_be_explain_1_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het basisbedrag is dus:</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-1-p25">{{ output.lt_be_explain_1_p25 }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom van januari 2020 (of
                                november 2019). De voorwaarden voor dit bedrag staan hierboven.
                              </li>
                              <li>Omdat het gaat om een tegemoetkoming voor drie
                                maanden, wordt de gemaximeerde loonsom
                                vermenigvuldigd met 3.
                              </li>
                              <li>Het bedrag dat daaruit volgt, wordt verhoogd met 30%
                                (forfaitaire verhoging, om kosten als
                                werkgeverspremies, pensioenbijdragen en de opbouw
                                van vakantiegeld te compenseren).
                              </li>
                              <li>Het verhoogde bedrag wordt vermenigvuldigd met het
                                percentage werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 90% daarvan.</li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="p25-lt-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="p25-lt-be" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.p25_lt_be">
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="p26-lt-be">
                            Verlaging wegens een lagere loonsom
                          </label>
                          <ModalWithButton title="Berekening van de verlaging wegens een lagere loonsom" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Loonsom januari 2020 (of november 2019)</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-2-j9">{{ output.lt_be_explain_2_j9 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-2-p20">{{ output.lt_be_explain_2_p20 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom van maart, april en mei
                                  2020
                                </div>
                                <div class="col-4">&euro; <span id="lt-be-explain-2-p21">{{ output.lt_be_explain_2_p21 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">Verschil</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-2-p22">{{ output.lt_be_explain_2_p22 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 130%</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-2-times-130">{{ output.lt_be_explain_2_times_130 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-2-times-90">{{ output.lt_be_explain_2_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het bedrag van de verlaging is
                                  dus:
                                </div>
                                <div class="col-4">&euro; <span id="lt-be-explain-2-p26">{{ output.lt_be_explain_2_p26 }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij nemen het verschil tussen 3&times; de loonsom
                                van
                                januari 2020 (of november 2019) en de opgetelde
                                loonsommen van maart, april en mei 2020.
                              </li>
                              <li>Het verschil wordt vervolgens vermenigvuldigd met 130% (forfaitaire verhoging)
                                &times; 90% (percentage van de tegemoetkoming).
                              </li>
                              <li>
                                Het omzetverliespercentage wordt niet in deze berekening meegenomen.
                                Daardoor is dit verlagingsbedrag misschien hoger dan u had verwacht.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="p26-lt-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="p26-lt-be" :class="'form-control border-right-0 ' + output.p26_lt_be_class"
                                   type="text" placeholder=""
                                   readonly v-model="output.p26_lt_be">
                            <div class="input-group-append">
                              <span :class="'input-group-text ' + (output.p26_lt_be_class || 'bg-disabled')">&minus;</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="be-lt-be">
                            Verlaging wegens bedrijfseconomisch ontslag
                          </label>
                          <ModalWithButton title="Berekening van de verlaging wegens bedrijfseconomisch ontslag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Loonsom januari 2020 van alle
                                  werknemers voor wie bedrijfseconomisch ontslag is aangevraagd
                                </div>
                                <div class="col-4">&euro; <span id="lt-be-explain-3-r14">{{ output.lt_be_explain_3_r14 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-3-times-3">{{ output.lt_be_explain_3_times_3 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 130%</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-3-times-130">{{ output.lt_be_explain_3_times_130 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-3-times-90">{{ output.lt_be_explain_3_times_90 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 150%</div>
                                <div class="col-4">&euro; <span id="lt-be-explain-3-times-150">{{ output.lt_be_explain_3_times_150 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het bedrag van de verlaging is
                                  dus:
                                </div>
                                <div class="col-4">&euro; <span id="lt-be-explain-3-end">{{ output.lt_be_explain_3_end }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom over januari 2020 (of november 2019) van alle
                                werknemers voor wie u tussen maart en mei 2020 bedrijfseconomisch ontslag
                                heeft aangevraagd.
                              </li>
                              <li>Voor de berekening van deze loonsom gelden dezelfde voorwaarden als hierboven.</li>
                              <li>Deze loonsom wordt eerst vermenigvuldigd met 3 en
                                daarna met 130% (forfaitaire verhoging) &times; 90%
                                (percentage van de tegemoetkoming) &times; 150%.
                              </li>
                              <li>
                                Het omzetverliespercentage wordt niet in deze berekening meegenomen.
                                Daardoor is dit verlagingsbedrag misschien hoger dan u had verwacht.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="be-lt-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="be-lt-be" :class="'form-control border-right-0 ' + output.be_lt_be_class"
                                   type="text" placeholder=""
                                   readonly v-model="output.be_lt_be">
                            <div class="input-group-append">
                              <span :class="'input-group-text ' + (output.be_lt_be_class || 'bg-disabled')">&minus;</span>
                            </div>
                          </div>

                          <hr class="computation-line">
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e22-lt-be">
                            Definitieve tegemoetkoming
                          </label>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e22-lt-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e22-lt-be" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e22_lt_be">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- Verschil voorschot en definitieve tegemoetkoming -->
                  <b-card class="pt-0">
                    <div class="form-row">
                      <div class="col-md-7">
                        <label class="mt-1" for="e21_out">
                          Ontvangen voorschot
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label class="sr-only" for="e21_out"> </label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">€</div>
                          </div>
                          <input id="e21_out" class="form-control border-right-0" type="text" placeholder="" readonly v-model="e21">
                          <div class="input-group-append">
                            <span class="input-group-text bg-disabled">&minus;</span>
                          </div>
                        </div>

                        <hr class="computation-line">
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-md-7">
                        <label class="mt-1" for="h27">
                          <span v-if="output.h27_class == 'negative'">Bedrag dat u moet terugbetalen</span>
                          <span v-else>Bedrag dat u nog tegoed heeft</span>
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label class="sr-only" for="h27"> </label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">€</div>
                          </div>
                          <input id="h27" :class="'form-control ' + output.h27_class" type="text" placeholder="" readonly v-model="output.h27">
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-card>
              </b-collapse>
            </div> <!-- /details-box -->
          </b-card>
        </b-collapse><!-- /end #result -->
      </div>
    </div>

    <div class="d-flex justify-content-between mb-3">
      <div class="width-more">
        <b-button variant="secondary" @click="$emit('prev')" class="mr-2 mb-2">
          <img src="../../assets/icon/arrow.svg" class="icon-chevron-right-solid rotate-180" alt="arrow" />
          Vorige
        </b-button>
      </div>
      <div class="text-right">
        <b-button variant="outline-warning" class="btn-lg mb-2" v-if="isDevelopment()" @click="devFillForm()">
          Random (DEV)
        </b-button>
        <b-button :variant="isLast?'success':'secondary'" class="btn-lg ml-2 mb-2" v-bind:disabled="!resultsVisible" @click="$emit('overview')">
          <img v-if="isLast" src="../../assets/icon/arrow.svg" class="icon-chevron-right-solid" alt="arrow"/>
          Overzicht
        </b-button>
        <b-button variant="success" class="btn-lg ml-2 mb-2" v-if="!isLast" v-bind:disabled="!resultsVisible" @click="$emit('next')">
          <img src="../../assets/icon/arrow.svg" class="icon-chevron-right-solid" alt="arrow"/>
          Volgende periode
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import {
  currencyFormat, excelFloor,
  fillAndMarkNegativeIfNotZero, isDevelopment, myIsNaN,
  parseNumber, randomBool, randomFloat, randomInt, randomIntLog,
  sanitizeInteger, sum,
} from '@/repository/functions';
import TrancheResult from '@/repository/models/result';
import ResultsRepository from '@/repository/results-repository';
import ModalWithButton from '@/components/ModalComponent.vue';
import SettlementPeriods from "@/repository/settlement-periods";

export default {
  name: 'Tranche1Tool',
  components: { ModalWithButton },
  data() {
    return {
      changeCounter: 0,
      isPdf: false,
      gteJul27_2023: SettlementPeriods.gteJul27_2023(),
      values: {
        bedrijfseconomisch_ontslag: false,
        werkmaatschappij_onderdeel_van_concern: false,
        j6: '',
        h9: '',
        j12: '',
        j13: '',
        j14: '',
        r14: '',
        e21: '',
      },
      error: {
        j6: '',
        h9: '',
        j12: '',
        j13: '',
        j14: '',
        r14: '',
        e21: '',
      },
      valid: {
        j6: null,
        h9: null,
        j12: null,
        j13: null,
        j14: null,
        r14: null,
        e21: null,
      },
      resultsVisible: false,
      showDetails: false,
      scenariosCalculationVisible: {
        j6_lt_20: false,
        gte: false,
        gte_be: false,
        lt: false,
        lt_be: false,
      },
      output: {
        'gte_explain_j6': '',
        'gte_explain_j16': '',
        'gte_explain_times_130': '',
        'gte_explain_times_j6': '',
        'gte_explain_times_90': '',
        'gte_explain_end': '',
        'be_gte_be': '',
        'be_gte_be_class': '',
        'p25_gte_be': '',
        'gte_be_explain_1_j6': '',
        'gte_be_explain_1_j16': '',
        'gte_be_explain_1_times_130': '',
        'gte_be_explain_1_times_j6': '',
        'gte_be_explain_1_times_90': '',
        'gte_be_explain_1_end': '',
        'gte_be_explain_2_r14': '',
        'gte_be_explain_2_times_3': '',
        'gte_be_explain_2_times_130': '',
        'gte_be_explain_2_times_90': '',
        'gte_be_explain_2_times_150': '',
        'gte_be_explain_2_end': '',
        'p26_lt': '',
        'p26_lt_class': '',
        'p25_lt': '',
        'lt_explain_1_j6': '',
        'lt_explain_1_h9': '',
        'lt_explain_1_times_3': '',
        'lt_explain_1_times_130': '',
        'lt_explain_1_times_j6': '',
        'lt_explain_1_times_90': '',
        'lt_explain_1_p25': '',
        'lt_explain_2_j9': '',
        'lt_explain_2_p20': '',
        'lt_explain_2_p21': '',
        'lt_explain_2_p22': '',
        'lt_explain_2_times_130': '',
        'lt_explain_2_times_90': '',
        'lt_explain_2_p26': '',
        'be_lt_be': '',
        'be_lt_be_class': '',
        'p26_lt_be': '',
        'p26_lt_be_class': '',
        'p25_lt_be': '',
        'lt_be_explain_1_j6': '',
        'lt_be_explain_1_h9': '',
        'lt_be_explain_1_times_3': '',
        'lt_be_explain_1_times_130': '',
        'lt_be_explain_1_times_j6': '',
        'lt_be_explain_1_times_90': '',
        'lt_be_explain_1_p25': '',
        'lt_be_explain_2_j9': '',
        'lt_be_explain_2_p20': '',
        'lt_be_explain_2_p21': '',
        'lt_be_explain_2_p22': '',
        'lt_be_explain_2_times_130': '',
        'lt_be_explain_2_times_90': '',
        'lt_be_explain_2_p26': '',
        'lt_be_explain_3_r14': '',
        'lt_be_explain_3_times_3': '',
        'lt_be_explain_3_times_130': '',
        'lt_be_explain_3_times_90': '',
        'lt_be_explain_3_times_150': '',
        'lt_be_explain_3_end': '',
        'e22_gte': '',
        'e22_gte_be': '',
        'e22_lt': '',
        'e22_lt_be': '',
        'h27': '',
        'h27_class': '',
        'result_text': '',
        'declaration_type': '',
        'settlement_period_open': false,
        'settlement_period_passed': false,
        'settlement_period_text': '',
      },
    };
  },
  mounted() {
    const result = ResultsRepository.getInstance().retrieve(1);
    if (result !== null) {
      this.bedrijfseconomisch_ontslag = result.trancheVars.values.bedrijfseconomisch_ontslag;
      this.werkmaatschappij_onderdeel_van_concern = result.trancheVars.values.werkmaatschappij_onderdeel_van_concern;
      this.j6 = result.trancheVars.values.j6;
      this.h9 = result.trancheVars.values.h9;
      this.j12 = result.trancheVars.values.j12;
      this.j13 = result.trancheVars.values.j13;
      this.j14 = result.trancheVars.values.j14;
      this.r14 = result.trancheVars.values.r14;
      this.e21 = result.trancheVars.values.e21;
    }
  },
  props: {
    isLast: Boolean,
  },
  computed: {
    j6: {
      get() {this.changeCounter; return this.values.j6;},
      set(val) {this.values.j6 = sanitizeInteger(val, 0, 100); this.calculate(); this.changeCounter++;},
    },
    h9: {
      get() {this.changeCounter; return this.values.h9;},
      set(val) {this.values.h9 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    j12: {
      get() {this.changeCounter; return this.values.j12;},
      set(val) {this.values.j12 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    j13: {
      get() {this.changeCounter; return this.values.j13;},
      set(val) {this.values.j13 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    j14: {
      get() {this.changeCounter; return this.values.j14;},
      set(val) {this.values.j14 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    r14: {
      get() {this.changeCounter; return this.values.r14;},
      set(val) {this.values.r14 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    e21: {
      get() {this.changeCounter; return this.values.e21;},
      set(val) {this.values.e21 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    bedrijfseconomisch_ontslag: {
      get() {this.changeCounter; return this.values.bedrijfseconomisch_ontslag;},
      set(val) {
        this.values.bedrijfseconomisch_ontslag = val;
        if (!val) {
          this.r14 = '0';
        }
        this.calculate();
        this.changeCounter++;
      },
    },
    werkmaatschappij_onderdeel_van_concern: {
      get() {this.changeCounter; return this.values.werkmaatschappij_onderdeel_van_concern;},
      set(val) {this.values.werkmaatschappij_onderdeel_van_concern = val; this.calculate(); this.changeCounter++;},
    },
  },
  methods: {
    isDevelopment,
    /*erase() {
      this.values.bedrijfseconomisch_ontslag = false;
      this.values.werkmaatschappij_onderdeel_van_concern = false;
      this.values.j6 = '';
      this.values.h9 = '';
      this.values.j12 = '';
      this.values.j13 = '';
      this.values.j14 = '';
      this.values.r14 = '';
      this.values.e21 = '';
      this.calculate();
      this.changeCounter++;
    },*/
    allFieldsFilled() {
      return this.values.j6 !== '' && this.values.h9 !== ''
          && this.values.j12 !== '' && this.values.j13 !== '' && this.values.j14 !== ''
          && this.values.e21 !== '';
      // r14 is not required
    },
    validate(validateAll) {
      let hasError = false;

      for (const key in this.values) {
        this.error[key] = '';
        this.valid[key] = this.values[key] === '' ? null : true;
      }

      if (validateAll) {
        for (const key in this.values) {
          if (key !== 'r14' && this.values[key] === '') {
            hasError = true;
            this.error[key] = 'Dit veld is verplicht';
          }
        }
      }

      const extraVal = this.extraValidate();

      return hasError === false && extraVal === false;
    },
    extraValidate() {
      let hasError = false;

      const h9 = parseNumber(this.values.h9);
      const r14 = parseNumber(this.values.r14);

      if (r14 > h9) {
        hasError = true;
        this.error.r14 = "Dit bedrag mag niet groter zijn dan de loonsom januari";
      } else if (r14 !== "" && !myIsNaN(r14)) {
        this.error.r14 = '';
      }

      return hasError;
    },
    calculate(validateAll) {
      if (this.validate(validateAll) && this.allFieldsFilled()) {
        this.calculateNow();
        this.resultsVisible = true;
      } else {
        this.resultsVisible = false;

        ResultsRepository.getInstance().add(1, null);
        this.$emit('updateResults');
      }
    },
    calculateNow() {
      // var h5 = parseNumber($('#h5').val()) / 100;  // Vervalt door direct uitvragen ontvangen voorschot
      const j6 = parseNumber(this.values.j6) / 100;

      const h9 = parseNumber(this.values.h9);
      const j9 = h9;//parseNumber($('#h9').val()); //j9 komt te vervallen

      const j12 = parseNumber(this.values.j12);
      const j13 = parseNumber(this.values.j13);
      const j14 = parseNumber(this.values.j14);

      const r14 = this.values.r14 === "" ? 0 : parseNumber(this.values.r14);

      const e21 = this.values.e21 === "" ? 0 : parseNumber(this.values.e21);

      const r21 = j13 > j12 ? j12 : j13;  // =ALS((J13>J12);J12;J13)
      const r22 = j14 > j12 ? j12 : j14;  // =ALS((J14>J12);J12;J14)

      const p19 = 3 * h9;  // 4*H9 Eigenlijk: =ALS((P19=WAAR);(4*H10);(4*H9)) maar h10 hoeft/bestaat niet

      const p20 = 3 * j9;  // 4*J9 Eigenlijk:  =ALS((P19=WAAR);(4*J10);(4*J9))maar j10 hoeft/bestaat niet

      const r18 = p20 < (j12 + r21 + r22) ? 'ja' : 'nee';  // =ALS(P20<(J12+R21+R22);"Ja";"Nee")

      const r19 = p20<(j12+j13+j14) ? 'ja' : 'nee';  // =ALS(P20<(J12+J13+J14);"Ja";"Nee")

      const p21 = r18 == 'ja' ? j12+r21+r22 : r19 == 'nee' ? j12+j13+j14 : p20;
      // =ALS(R18="Ja";(J12+R21+R22);ALS(R19="Nee";(J12+J13+J14);P20))
      const j16 = p21;  // =(P21)

      const j17 = r18 == 'ja' ? 'hoger' : 'lager (of gelijk)';  // =ALS(R18="Ja";"hoger";"lager (of gelijk)")

      // const p22 = j16 < p20 ? 'ja' : 'nee';//=ALS(J17<P21;"Ja";"Nee")

      const e22 = Math.max(0, (j6 > 0.19 ? (j17 == 'hoger' ? (j6 * j16 * 1.3 * 0.9) - (r14 * 1.5 * 3 * 1.3 * 0.9) : (j6 * p20 * 1.3 * 0.9) - ((p20 - j16) * 1.3 * 0.9) - (r14 * 1.5 * 3 * 1.3 * 0.9)) : 0));
      // =MAX(0;ALS((J6>0,19);ALS(J17="hoger";(J6*J16*1,3*0,9)-(U14*1,5*3*1,3*0,9);(((J6*P20*1,3*0,9)-((P20-J16)*1,3*0,9))-(U14*1,5*3*1,3*0,9)));0))

      // formule voor R20: =J17
      let p25 = j17 === 'lager (of gelijk)' ? j6 * p20 * 1.3 * 0.9 : 'hoger';  // =IF(R20="lager (of gelijk)",(J6*P20*1.3*0.9),"hoger")
      const p27 = e22 + (r14 * 1.5 * 3 * 1.3 * 0.9);  // =E22+(R14*1.5*3*1.3*0.9)
      // const p26 = j17 === 'lager (of gelijk)' ? p25 - p27 : 'geen correctie';  // =IF(R20="lager (of gelijk)",(P25-P27),"geen correctie")
      // Override p26: zodat de berekeningen en uitleggingen kloppen
      const p26 = (p20 - p21) * 1.3 * .9;

      // geen check of de loonsommen gezet zijn (j16 > 0). Zorgt er namelijk voor dat wanneer j12,13,14 de waarde 0 hebben
      // er geen volledige terugbetaling wordt getoond voor h26 (zou op 0 uitkomen terwijl er een negatief bedrag uit moet komen)
      const h26 = e22 - e21;//=ALS((R20=ONWAAR);(E22-E21);0)

      // === Laat de verschillende tussen-berekeningen zien ===
      const be = r14 * 3 * 1.3 * 0.9 * 1.5;
      let calcSet = '';

      this.scenariosCalculationVisible.j6_lt_20 = false;
      this.scenariosCalculationVisible.gte = false;
      this.scenariosCalculationVisible.gte_be = false;
      this.scenariosCalculationVisible.lt = false;
      this.scenariosCalculationVisible.lt_be = false;

      if (j6 < 0.2) {
        calcSet = 'j6_lt_20';
      } else if (p21 >= p20 && r14 === 0) {
        calcSet = 'gte';

        let explainValue = j16;

        this.output.gte_explain_j6 = Math.round(j6 * 100);
        this.output.gte_explain_j16 = currencyFormat(explainValue);
        this.output.gte_explain_times_130 = currencyFormat(explainValue *= 1.3);
        this.output.gte_explain_times_j6 = currencyFormat(explainValue *= j6);
        this.output.gte_explain_times_90 = currencyFormat(explainValue *= .9);
        this.output.gte_explain_end = currencyFormat(e22);
      } else if (p21 >= p20) {
        calcSet = 'gte_be';

        p25 = typeof p25 !== 'number' ? e22 + be : p25;

        fillAndMarkNegativeIfNotZero(this.output, 'be_' + calcSet, be);

        this.output['p25_' + calcSet] = currencyFormat(p25);

        let explainValue = j16;

        this.output.gte_be_explain_1_j6 = Math.round(j6 * 100);
        this.output.gte_be_explain_1_j16 = currencyFormat(explainValue);
        this.output.gte_be_explain_1_times_130 = currencyFormat(explainValue *= 1.3);
        this.output.gte_be_explain_1_times_j6 = currencyFormat(explainValue *= j6);
        this.output.gte_be_explain_1_times_90 = currencyFormat(explainValue *= .9);
        this.output.gte_be_explain_1_end = currencyFormat(p25);

        explainValue = r14;

        this.output.gte_be_explain_2_r14 = currencyFormat(r14);
        this.output.gte_be_explain_2_times_3 = currencyFormat(explainValue *= 3);
        this.output.gte_be_explain_2_times_130 = currencyFormat(explainValue *= 1.3);
        this.output.gte_be_explain_2_times_90 = currencyFormat(explainValue *= .9);
        this.output.gte_be_explain_2_times_150 = currencyFormat(explainValue *= 1.5);
        this.output.gte_be_explain_2_end = currencyFormat(be);
      } else if (p21 < p20 && r14 === 0) {
        calcSet = 'lt';

        fillAndMarkNegativeIfNotZero(this.output, 'p26_' + calcSet, p26);

        this.output['p25_' + calcSet] = currencyFormat(p25);

        let explainValue = h9;

        this.output.lt_explain_1_j6 = Math.round(j6 * 100);
        this.output.lt_explain_1_h9 = currencyFormat(h9);
        this.output.lt_explain_1_times_3 = currencyFormat(explainValue *= 3);
        this.output.lt_explain_1_times_130 = currencyFormat(explainValue *= 1.3);
        this.output.lt_explain_1_times_j6 = currencyFormat(explainValue *= j6);
        this.output.lt_explain_1_times_90 = currencyFormat(explainValue *= .9);
        this.output.lt_explain_1_p25 = currencyFormat(p25);

        explainValue = p20 - p21;

        this.output.lt_explain_2_j9 = currencyFormat(j9);
        this.output.lt_explain_2_p20 = currencyFormat(p20);
        this.output.lt_explain_2_p21 = currencyFormat(p21);
        this.output.lt_explain_2_p22 = currencyFormat(explainValue);
        this.output.lt_explain_2_times_130 = currencyFormat(explainValue *= 1.3);
        this.output.lt_explain_2_times_90 = currencyFormat(explainValue *= .9);
        this.output.lt_explain_2_p26 = currencyFormat(p26);
      } else if (p21 < p20) {
        calcSet = 'lt_be';

        fillAndMarkNegativeIfNotZero(this.output, 'be_' + calcSet, be);
        fillAndMarkNegativeIfNotZero(this.output, 'p26_' + calcSet, p26);

        this.output['p25_' + calcSet] = currencyFormat(p25);

        let explainValue = h9;

        this.output.lt_be_explain_1_j6 = Math.round(j6 * 100);
        this.output.lt_be_explain_1_h9 = currencyFormat(h9);
        this.output.lt_be_explain_1_times_3 = currencyFormat(explainValue *= 3);
        this.output.lt_be_explain_1_times_130 = currencyFormat(explainValue *= 1.3);
        this.output.lt_be_explain_1_times_j6 = currencyFormat(explainValue *= j6);
        this.output.lt_be_explain_1_times_90 = currencyFormat(explainValue *= .9);
        this.output.lt_be_explain_1_p25 = currencyFormat(p25);

        explainValue = p20 - p21;

        this.output.lt_be_explain_2_j9 = currencyFormat(j9);
        this.output.lt_be_explain_2_p20 = currencyFormat(p20);
        this.output.lt_be_explain_2_p21 = currencyFormat(p21);
        this.output.lt_be_explain_2_p22 = currencyFormat(explainValue);
        this.output.lt_be_explain_2_times_130 = currencyFormat(explainValue *= 1.3);
        this.output.lt_be_explain_2_times_90 = currencyFormat(explainValue *= .9);
        this.output.lt_be_explain_2_p26 = currencyFormat(p26);

        explainValue = r14;

        this.output.lt_be_explain_3_r14 = currencyFormat(r14);
        this.output.lt_be_explain_3_times_3 = currencyFormat(explainValue *= 3);
        this.output.lt_be_explain_3_times_130 = currencyFormat(explainValue *= 1.3);
        this.output.lt_be_explain_3_times_90 = currencyFormat(explainValue *= .9);
        this.output.lt_be_explain_3_times_150 = currencyFormat(explainValue *= 1.5);
        this.output.lt_be_explain_3_end = currencyFormat(be);
      }

      const explainValue = h9;

      this.output['e22_' + calcSet] = currencyFormat(e22);
      this.scenariosCalculationVisible[calcSet] = true;

      const h27Result = this.output.h27 = currencyFormat(h26);

      // Set some styling for the final output
      this.output.h27_class = '';
      if (h26 > 0) {
        this.output.result_text = "U ontvangt een nabetaling";
        this.output.h27_class = "positive";
      }
      if (h26 < 0) {
        this.output.h27_class = "negative";
        this.output.result_text = "U moet een bedrag terugbetalen";
        this.output.h27 = currencyFormat(h26 * -1);
      }
      if(h26 == 0){
        this.output.result_text = "Terugvordering / Nabetaling";
      }

      // Toon als verklaring meegestuurd moet worden bij welke bedragen.
      if(this.values.werkmaatschappij_onderdeel_van_concern || e21>=100000 || e22>=125000){
        this.output.declaration_type = 3;
      }else if(e21>=20000 || e22>=25000){
        this.output.declaration_type = 2;
      }else{
        this.output.declaration_type = 1;
      }

      this.output.settlement_period_open = SettlementPeriods.isOpen(1);
      this.output.settlement_period_passed = SettlementPeriods.hasPassed(1);
      this.output.settlement_period_text = SettlementPeriods.trancheComponentText(1);

      ResultsRepository.getInstance().add(1, new TrancheResult(this.output.declaration_type, h27Result, {
        values: this.values,
        scenariosCalculationVisible: this.scenariosCalculationVisible,
        output: this.output,
        gteJul27_2023: this.gteJul27_2023,
      }));
      this.$emit('updateResults');
    },
    devFillForm() {
      if (!isDevelopment()) {
        return;
      }
      this.j6 = '' + randomInt(0, 100);
      this.h9 = '' + randomIntLog(0, 500000000);
      this.j12 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.j13 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.j14 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.bedrijfseconomisch_ontslag = randomBool();
      this.r14 = this.bedrijfseconomisch_ontslag ? '' + Math.round(parseNumber(this.h9) * randomFloat(0, 1)) : '0';
      this.e21 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.werkmaatschappij_onderdeel_van_concern = randomBool();
    }
  },
};
</script>

<style scoped>
div.card-body {
  padding: 0
}

div.card {
  border: 0;
}
</style>
