<template>
  <div>
    <div class="box bg-blue text-white">
      <div class="">
        <h2>Gegevens 2<sup>e</sup> aanvraagperiode (NOW 2)</h2>

        <div class="form-calculator">
          <input autocomplete="false" name="hidden" type="text" class="display-none" readonly>

          <div class="mb-5">
            <b>Let op:</b> Voor de 2<sup>e</sup> aanvraagperiode NOW kunt u geen definitieve berekening meer aanvragen.

            <ModalWithButton title="Loket definitieve berekening gesloten" image="info">
              <p>
                Voor de 2<sup>e</sup> aanvraagperiode NOW kunt u geen definitieve berekening meer aanvragen.
                Als u nog geen definitieve berekening heeft aangevraagd, moet u het ontvangen voorschot terugbetalen.
                <span v-if="!gteJul27_2023">
                  <br /><br />
                  In uitzonderlijke gevallen kan een werkgever extra tijd krijgen om een definitieve berekening aan te vragen.
                  UWV neemt met die werkgevers contact op.
                </span>
              </p>
            </ModalWithButton>
          </div>

          <!-- Loonsom maart 2020 (of november 2019) -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="h9">
                Loonsom maart 2020 (of november 2019)
              </label>
              <ModalWithButton title="Loonsom maart 2020 (of november 2019)" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 mei 2020 bij de Belastingdienst bekend waren.</li>
                  <li>Als er geen loonaangifte over maart 2020 bekend is, dan geldt de loonsom van november 2019.</li>
                  <li>De maximale loonsom per werknemer is &euro;&nbsp;9.538 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan voor de berekening van het voorschot de loonsom in het 3e aangiftetijdvak (in plaats van maart) of het 12e aangiftetijdvak (in plaats van november).
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="h9"> </label>
              <div :class="'input-group mb-2' + (error.h9 ? ' is-invalid' : '') + (valid.h9 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.h9 ? ' is-invalid' : '') + (valid.h9 ? ' is-valid' : '')" id="h9" placeholder=""
                       data-min="0" data-max="500000000" v-model="h9" autocomplete="off">
              </div>
              <!-- tonen als is-invalid gezet wordt -->
              <div class="invalid-feedback text-right mb-2">{{ error.h9 }}</div>
            </div>
          </div>

          <!-- Loonsom juni 2020 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j12">
                Loonsom juni 2020
              </label>
              <ModalWithButton title="Loonsom juni 2020" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 november 2020 bij de Belastingdienst bekend zijn.</li>
                  <li>De maximale loonsom per werknemer is &euro;&nbsp;9.538 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan voor de berekening van het voorschot de loonsom in het 6e aangiftetijdvak.
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5">
              <label class="sr-only" for="j12"> </label>
              <div :class="'input-group mb-2' + (error.j12 ? ' is-invalid' : '') + (valid.j12 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.j12 ? ' is-invalid' : '') + (valid.j12 ? ' is-valid' : '')" id="j12" placeholder=""
                       data-min="0" data-max="500000000" v-model="j12" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j12 }}</div>
            </div>
          </div>

          <!-- Loonsom juli 2020 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j13">
                Loonsom juli 2020
              </label>
              <ModalWithButton title="Loonsom juli 2020" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 november 2020 bij de Belastingdienst bekend zijn.</li>
                  <li>De maximale loonsom per werknemer is &euro;&nbsp;9.538 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan voor de berekening van het voorschot de loonsom in het 7e aangiftetijdvak.
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5">
              <label class="sr-only" for="j13"> </label>
              <div :class="'input-group mb-2' + (error.j13 ? ' is-invalid' : '') + (valid.j13 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.j13 ? ' is-invalid' : '') + (valid.j13 ? ' is-valid' : '')" id="j13" placeholder=""
                       data-min="0" data-max="500000000" v-model="j13" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j13 }}</div>
            </div>
          </div>

          <!-- Loonsom augustus 2020 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j14">
                Loonsom augustus 2020
              </label>
              <ModalWithButton title="Loonsom augustus 2020" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 november 2020 bij de Belastingdienst bekend zijn.</li>
                  <li>De maximale loonsom per werknemer is &euro;&nbsp;9.538 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan voor de berekening van het voorschot de loonsom in het 8e aangiftetijdvak.
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5">
              <label class="sr-only" for="j14"> </label>
              <div :class="'input-group mb-2' + (error.j14 ? ' is-invalid' : '') + (valid.j14 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.j14 ? ' is-invalid' : '') + (valid.j14 ? ' is-valid' : '')" id="j14" placeholder=""
                       data-min="0" data-max="500000000" v-model="j14" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j14 }}</div>
            </div>
          </div>

          <!-- Loonsom september 2020 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j15">
                Loonsom september 2020
              </label>
              <ModalWithButton title="Loonsom september 2020" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 november 2020 bij de Belastingdienst bekend zijn.</li>
                  <li>De maximale loonsom per werknemer is &euro;&nbsp;9.538 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan voor de berekening van het voorschot de loonsom in het 9e aangiftetijdvak.
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="j15"> </label>
              <div :class="'input-group mb-2' + (error.j15 ? ' is-invalid' : '') + (valid.j15 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.j15 ? ' is-invalid' : '') + (valid.j15 ? ' is-valid' : '')" id="j15" placeholder=""
                       data-min="0" data-max="500000000" v-model="j15" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j15 }}</div>
            </div>
          </div>

          <!-- Ontvangen voorschot -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="e22">
                Totaal ontvangen voorschot
              </label>
              <ModalWithButton title="Hoogte voorschot" image="info">
                <p>
                  Als u recht had op een voorschot tegemoetkoming NOW in deze aanvraagperiode, heeft u daarover een beslissing ontvangen.
                  In die beslissing staat hoe hoog het totale voorschot is.
                </p>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="e22"> </label>
              <div :class="'input-group mb-2' + (error.e22 ? ' is-invalid' : '') + (valid.e22 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input id="e22" :class="'form-control numeric' + (error.e22 ? ' is-invalid' : '') + (valid.e22 ? ' is-valid' : '')" type="text" placeholder=""
                       data-min="0" data-max="500000000" v-model="e22" autocomplete="off">
              </div>
            </div>
          </div>

          <!-- Werkelijke omzetverlies -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j6">
                Werkelijk omzetverlies
              </label>
              <ModalWithButton title="Werkelijk omzetverlies" image="info">
                <p>
                  Dit is het werkelijke percentage omzetverlies over de periode in uw aanvraag van de tegemoetkoming.
                  Het definitieve percentage weet u pas nadat uw accountant of boekhouder dat heeft vastgesteld.
                  Als u zelf een inschatting van het omzetverlies wilt maken, kunt u hiervoor de
                  <a target="_blank" href="https://www.rekenhulpomzetverlies.nl/">Rekenhulp omzetverlies</a> gebruiken.
                </p>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="j6"> </label>
              <div :class="'input-group mb-2' + (error.j6 ? ' is-invalid' : '') + (valid.j6 ? ' is-valid' : '')">
                <input type="text" :class="'form-control numeric' + (error.j6 ? ' is-invalid' : '') + (valid.j6 ? ' is-valid' : '')" id="j6" placeholder=""
                       data-min="0" data-max="100" v-model="j6" autocomplete="off">
                <div class="input-group-prepend">
                  <div class="input-group-text input-group-text-right">%</div>
                </div>
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j6 }}</div>
            </div>
          </div>

          <!-- Bedrijfseconomisch ontslag (vinkje) -->
          <div class="form-row indent">
            <div class="col-md-12">
              <label class="mt-1" for="bedrijfseconomisch_ontslag">
                <input type="checkbox" name="bedrijfseconomisch_ontslag"
                       id="bedrijfseconomisch_ontslag"
                       v-model="bedrijfseconomisch_ontslag"
                       autocomplete="off"
                /> &nbsp;
                Ik heb bedrijfseconomisch ontslag aangevraagd

                <ModalWithButton title="Ontslagaanvraag om bedrijfseconomische redenen" image="info">
                  <p>
                    Als u een aanvraag heeft gedaan voor ontslag om bedrijfseconomische redenen tussen 1 juni en 30 september 2020,
                    dan wordt de tegemoetkoming verlaagd.
                  </p>
                  <p>
                    Om de verlaging te berekenen, neemt u de loonsom over maart 2020 (of november 2019) van alle werknemers voor wie u bedrijfseconomisch ontslag heeft aangevraagd.
                    Dit bedrag wordt eerst vermenigvuldigd met 3 en daarna met 140% &times; 90%.
                    De uitkomst wordt van de tegemoetkoming afgehaald.
                  </p>
                  <p>
                    <b>Let op:</b> Als u voor 20 of meer werknemers bedrijfseconomisch ontslag heeft aangevraagd, dan geldt er een extra verlaging van 5%, bovenop het berekende verlagingsbedrag.
                    Die extra verlaging is niet in deze rekenhulp meegenomen.
                  </p>
                </ModalWithButton>
              </label>
            </div>
          </div>

          <!-- Totale loonsom van werknemers voor wie ontslag is aangevraagd (bedrag) -->
          <div class="form-row" v-if="values.bedrijfseconomisch_ontslag">
            <div class="col-md-7">
              <label class="mt-1" for="r15">
                Loonsom bedrijfseconomisch ontslag
              </label>
              <ModalWithButton title="Totale loonsom van werknemers voor wie ontslag is aangevraagd" image="info">
                <ul>
                  <li>Neem van alle werknemers voor wie u bedrijfseconomisch ontslag heeft aangevraagd de loonsom over maart 2020 (of november 2019).</li>
                  <li>Voor de berekening van deze loonsom gelden dezelfde voorwaarden als hierboven.</li>
                  <li>Tel de loonsommen bij elkaar op. Het opgetelde bedrag vult u hier in.</li>
                  <li>Dit bedrag wordt eerst vermenigvuldigd met 3 en daarna met 140% &times; 90%. De uitkomst wordt van de tegemoetkoming afgehaald.</li>
                </ul>
                <p>
                  <b>Let op:</b> het gaat hier dus niet om werknemers van wie het tijdelijk dienstverband is beëindigd,
                  werknemers die met pensioen zijn gegaan of werknemers die vrijwillig zijn weggegaan.
                  De loonsom van die werknemers telt niet mee voor deze verlaging.
                </p>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-2">
              <label class="sr-only" for="r15"> </label>
              <div :class="'input-group mb-2' + (error.r15 ? ' is-invalid' : '') + (valid.r15 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text" :class="'form-control numeric' + (error.r15 ? ' is-invalid' : '') + (valid.r15 ? ' is-valid' : '')" id="r15" placeholder=""
                       data-min="0" data-max="500000000" v-model="r15" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.r15 }}</div>
            </div>
          </div>

          <!-- Onderdeel van concern? -->
          <div class="form-row indent">
            <div class="col-md-12">
              <label class="mt-1" for="werkmaatschappij_onderdeel_van_concern">
                <input type="checkbox" name="werkmaatschappij_onderdeel_van_concern"
                       id="werkmaatschappij_onderdeel_van_concern"
                       v-model="werkmaatschappij_onderdeel_van_concern"
                       autocomplete="off"
                /> &nbsp;
                Ik ga uit van het omzetverlies van mijn werkmaatschappij

                <ModalWithButton title="Aanvraag op werkmaatschappij-niveau" image="info">
                  <p>
                    Als u onderdeel bent van een groep of concern en het totale concern heeft minder dan 20% omzetverlies,
                    dan kunt u ervoor kiezen om een aanvraag te doen op werkmaatschappijniveau.
                    Bij de definitieve berekening gaat u dan uit van het omzetverlies van het groeps- of concernonderdeel (de werkmaatschappij) in plaats van het omzetverlies van de groep of het concern als geheel.
                  </p>

                  <p>
                    Bij een aanvraag op werkmaatschappijniveau gelden er extra voorwaarden.
                    Vraag uw accountant om meer informatie hierover of kijk op de <a target="_blank" href="https://www.nba.nl/nba-helpt/helpdesk-now-regeling/">website van de Koninklijke Nederlandse Beroepsorganisatie van Accountants (NBA)</a>.
                  </p>

                  <p>
                    Of u onderdeel bent van een concern of groep kunt u navragen bij uw accountant of financieel adviseur.
                  </p>
                </ModalWithButton>
              </label>

              <div v-if="werkmaatschappij_onderdeel_van_concern">
                Als u onderdeel bent van een groep of concern en een definitieve berekening aanvraagt op werkmaatschappijniveau, gelden er extra voorwaarden.
              </div>
            </div>
          </div>
        </div>

        <b-collapse v-model="resultsVisible" id="results">
          <b-card>
            <div class="end-result-calculator" id="end-result">
              <h2 class="inline">Uw tegemoetkoming volgens de ingevulde gegevens</h2>

              <p class="mb-1" v-if="output.h27_class == 'negative'">
                U moet <strong>&euro; {{ output.h27 }}</strong> terugbetalen.
              </p>
              <p class="mb-1" v-else>
                U heeft een bedrag van <strong>&euro; {{ output.h27 }}</strong> tegoed.
              </p>

              <!-- Meesturen verklaring? -->
              <div class="mb-1">
                <span id="declaration1" class="declaration" v-if="output.declaration_type == 1">U hoeft <strong>geen verklaring</strong> mee te sturen met uw aanvraag van de definitieve berekening.</span>
                <span id="declaration2" class="declaration" v-if="output.declaration_type == 2">U moet een <strong>derdenverklaring</strong> meesturen met uw aanvraag van de definitieve berekening.</span>
                <span id="declaration3" class="declaration" v-if="output.declaration_type == 3">U moet een <strong>accountantsverklaring</strong> meesturen met uw aanvraag van de definitieve berekening.</span>

                <ModalWithButton title="Moet u een verklaring meesturen?" image="info">
                  <p>In sommige situaties heeft UWV voor de definitieve berekening van de tegemoetkoming een accountantsverklaring of derdenverklaring nodig. Deze verklaring moet u meesturen met de aanvraag van de definitieve berekening.
                  </p>
                  <p>In de volgende situaties moet u een <b>accountantsverklaring</b> meesturen:</p>
                  <ul>
                    <li>bij een voorschot van &euro; 100.000 of meer;
                    </li>
                    <li>bij een definitieve tegemoetkoming van &euro; 125.000 of meer (ongeacht de hoogte van het betaalde voorschot);
                    </li>
                    <li>voor de werkgever die onderdeel is van een groep of concern geldt: tel de bedragen van alle aanvragen (van de loonheffingennummers) binnen de groep of het concern bij elkaar op. Het totaalbedrag bepaalt of een accountants- of derdenverklaring nodig is;
                    </li>
                    <li>bij een aanvraag NOW op werkmaatschappijniveau (omdat het concern of de groep onder de 20% omzetverlies komt). In deze situatie maakt het niet uit hoe hoog de tegemoetkoming of het voorschot is, er is altijd een accountantsverklaring nodig.
                    </li>
                  </ul>
                  <p>In de volgende situaties moet u een <b>derdenverklaring</b> meesturen:</p>
                  <ul>
                    <li>bij een voorschot tussen &euro; 20.000 en &euro; 100.000;
                    </li>
                    <li>bij een definitieve tegemoetkoming tussen &euro; 25.000 en &euro; 125.000 (ongeacht de hoogte van het voorschot).
                    </li>
                  </ul>
                </ModalWithButton>
              </div>

              <p v-if="output.settlement_period_passed">De aanvraag voor deze periode is al <strong>gesloten</strong>.</p>
              <p v-if="output.settlement_period_open">Uiterlijk <strong>{{ output.settlement_period_text }}</strong> kunt u een aanvraag definitieve berekening indienen.</p>
              <p v-if="!output.settlement_period_passed && !output.settlement_period_open">Vanaf <strong>{{ output.settlement_period_text }}</strong> kunt u een aanvraag definitieve berekening indienen.</p>
            </div><!-- /end-result-calculator -->

            <div :class="'details-box ' + (showDetails ? 'details-box-show' : '')">
              <b-button variant="success" @click="showDetails = !showDetails; $event.currentTarget.blur()">
                <span v-if="showDetails"><img class="icon-chevron-right-solid up-down" src="../../assets/icon/arrow-up.svg" alt="up" /> Verberg details</span>
                <span v-else><img class="icon-chevron-right-solid up-down" src="../../assets/icon/arrow-down.svg" alt="up" /> Toon details</span>
              </b-button>
              <b-collapse class="mt-1 pb-2" v-model="showDetails">
                <b-card>
                  <!-- (0a) werkelijke omzetverlies < 20%, alles terugbetalen -->
                  <b-collapse id="j6-lt-20" class="scenarios-calculation" v-model="scenariosCalculationVisible.j6_lt_20">
                    <b-card>
                      <p>
                        Uw heeft minder dan 20% omzetverlies.
                        In dat geval heeft u geen recht op een tegemoetkoming NOW.
                      </p>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e22-j6_lt_20">
                            Definitieve tegemoetkoming
                          </label>
                          <ModalWithButton title="Geen recht op tegemoetkoming" image="calc">
                            <p>
                              Uw omzetverlies is minder dan 20%.
                              In dat geval heeft u geen recht op een tegemoetkoming.
                              Daarom wordt de definitieve tegemoetkoming vastgesteld op &euro; 0.
                              Dat betekent dat u alles wat u aan voorschot heeft ontvangen, moet terugbetalen.
                            </p>

                            <div class="container-fluid px-0">
                              <div class="row">
                                <div class="col-8">Uw definitieve tegemoetkoming</div>
                                <div class="col-4">&euro; 0</div>
                              </div>
                            </div>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e22-j6_lt_20"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">&euro;</div>
                            </div>
                            <input id="e22-j6_lt_20" class="form-control" type="text" placeholder=""
                                   readonly value="0">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- scenario 1: geen verlaging -->
                  <b-collapse id="no-red" class="explain-calculation" v-model="scenariosCalculationVisible.no_red">
                    <b-card>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e23-no-red">
                            Definitieve tegemoetkoming
                          </label>
                          <ModalWithButton title="Berekening van de tegemoetkoming" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span id="no-red-explain-j6">{{ output.no_red_explain_j6 }}</span>%</div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom maart 2020 (of november 2019)</div>
                                <div class="col-4">&euro; <span id="no-red-explain-h9">{{ output.no_red_explain_h9 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 4</div>
                                <div class="col-4">&euro; <span id="no-red-explain-times-4">{{ output.no_red_explain_times_4 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span id="no-red-explain-times-140">{{ output.no_red_explain_times_140 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk omzetverliespercentage</div>
                                <div class="col-4">&euro; <span id="no-red-explain-times-j6">{{ output.no_red_explain_times_j6 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="no-red-explain-times-90">{{ output.no_red_explain_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">De tegemoetkoming is dus:</div>
                                <div class="col-4">&euro; <span id="no-red-explain-end">{{ output.no_red_explain_end }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom van maart 2020 (of november 2019). De voorwaarden voor dit bedrag staan hierboven.
                              </li>
                              <li>Omdat het gaat om een tegemoetkoming voor vier maanden, wordt de loonsom vermenigvuldigd met 4.
                              </li>
                              <li>Het bedrag dat daaruit volgt, wordt verhoogd met 40% (forfaitaire verhoging, om kosten als werkgeverspremies, pensioenbijdragen en de opbouw van vakantiegeld te compenseren).
                              </li>
                              <li>Het verhoogde bedrag wordt vermenigvuldigd met het percentage werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 90% daarvan.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e23-no-red"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e23-no-red" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e23_no_red">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- scenario 2: verlaging wegens bedrijfseconomisch ontslag (komt zelden voor) -->
                  <b-collapse id="red-be" class="explain-calculation" v-model="scenariosCalculationVisible.red_be">
                    <b-card>
                      <p>De tegemoetkoming wordt verlaagd, omdat u tussen 1 juni 2020 en 30 september voor een of meer werknemers bedrijfseconomisch ontslag heeft aangevraagd.</p>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="bb-red-be">
                            Basisbedrag
                          </label>
                          <ModalWithButton title="Berekening van het basisbedrag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span id="red-be-explain-1-j6">{{ output.red_be_explain_1_j6 }}</span>%</div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom maart 2020 (of november 2019)
                                </div>
                                <div class="col-4">&euro; <span id="red-be-explain-1-h9">{{ output.red_be_explain_1_h9 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 4
                                </div>
                                <div class="col-4">&euro; <span id="red-be-explain-1-times-4">{{ output.red_be_explain_1_times_4 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span id="red-be-explain-1-times-140">{{ output.red_be_explain_1_times_140 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk
                                  omzetverliespercentage
                                </div>
                                <div class="col-4">&euro; <span id="red-be-explain-1-times-j6">{{ output.red_be_explain_1_times_j6 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="red-be-explain-1-times-90">{{ output.red_be_explain_1_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het basisbedrag is dus:</div>
                                <div class="col-4">&euro; <span id="red-be-explain-1-end">{{ output.red_be_explain_1_end }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom van maart 2020 (of november 2019). De voorwaarden voor dit bedrag staan hierboven.
                              </li>
                              <li>Omdat het gaat om een tegemoetkoming voor vier maanden, wordt de loonsom vermenigvuldigd met 4.
                              </li>
                              <li>Het bedrag dat daaruit volgt, wordt verhoogd met 40% (forfaitaire verhoging, om kosten als werkgeverspremies, pensioenbijdragen en de opbouw van vakantiegeld te compenseren).
                              </li>
                              <li>Het verhoogde bedrag wordt vermenigvuldigd met het percentage werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 90% daarvan.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="bb-red-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="bb-red-be" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.bb_red_be">
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="be-red-be">
                            Verlaging wegens bedrijfseconomisch ontslag
                          </label>
                          <ModalWithButton title="Berekening van de verlaging wegens bedrijfseconomisch ontslag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Loonsom maart 2020 (of november 2019) van alle werknemers voor wie bedrijfseconomisch ontslag is aangevraagd</div>
                                <div class="col-4">&euro; <span id="red-be-explain-2-r15">{{ output.red_be_explain_2_r15 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span id="red-be-explain-2-times-3">{{ output.red_be_explain_2_times_3 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span id="red-be-explain-2-times-140">{{ output.red_be_explain_2_times_140 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="red-be-explain-2-times-90">{{ output.red_be_explain_2_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het bedrag van de verlaging is dus:</div>
                                <div class="col-4">&euro; <span id="red-be-explain-2-end">{{ output.red_be_explain_2_end }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom over maart 2020 (of november 2019) van alle werknemers voor wie u tussen juni en september 2020 bedrijfseconomisch ontslag heeft aangevraagd.
                              </li>
                              <li>Voor de berekening van deze loonsom gelden dezelfde voorwaarden als hierboven.
                              </li>
                              <li>Deze loonsom wordt eerst vermenigvuldigd met 3 en daarna met 140% &times; 90%.
                              </li>
                              <li>Het bedrag dat hieruit volgt, wordt van de tegemoetkoming afgehaald.
                              </li>
                              <li>
                                Het omzetverliespercentage wordt niet in deze berekening meegenomen.
                                Daardoor is dit verlagingsbedrag misschien hoger dan u had verwacht.
                              </li>
                            </ul>
                            <p><b>Let op:</b> Als u voor 20 of meer werknemers bedrijfseconomisch ontslag heeft aangevraagd, dan geldt er een extra verlaging van 5%, bovenop het berekende verlagingsbedrag. Die extra verlaging is niet in deze rekenhulp meegenomen.</p>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="be-red-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="be-red-be" :class="'form-control border-right-0 ' + output.be_red_be_class"
                                   type="text" placeholder=""
                                   readonly v-model="output.be_red_be">
                            <div class="input-group-append">
                              <span :class="'input-group-text ' + (output.be_red_be_class || 'bg-disabled')">&minus;</span>
                            </div>
                          </div>

                          <hr class="computation-line">
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e23-red-be">
                            Definitieve tegemoetkoming
                          </label>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e23-red-be"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e23-red-be" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e23_red_be">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- scenario 3: alleen verlaging wegens lagere loonsom, geen bedrijfseconomisch ontslag -->
                  <b-collapse id="red-ll" class="explain-calculation" v-model="scenariosCalculationVisible.red_ll">
                    <b-card>
                      <p>De tegemoetkoming wordt verlaagd, omdat de gemiddelde loonsom over de periode juni tot en met september 2020 lager is dan de loonsom van maart 2020 (of november 2019).</p>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="bb-red-ll">
                            Basisbedrag
                          </label>
                          <ModalWithButton title="Berekening van het basisbedrag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span id="red-ll-explain-1-j6">{{ output.red_ll_explain_1_j6 }}</span>%</div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom maart 2020 (of november 2019)</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-1-h9">{{ output.red_ll_explain_1_h9 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 4</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-1-times-4">{{ output.red_ll_explain_1_times_4 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-1-times-140">{{ output.red_ll_explain_1_times_140 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk omzetverliespercentage</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-1-times-j6">{{ output.red_ll_explain_1_times_j6 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-1-times-90">{{ output.red_ll_explain_1_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het basisbedrag is dus:</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-1-bb">{{ output.red_ll_explain_1_bb }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom van maart 2020 (of november 2019). De voorwaarden voor dit bedrag staan hierboven.
                              </li>
                              <li>Omdat het gaat om een tegemoetkoming voor vier maanden, wordt de loonsom vermenigvuldigd met 4.
                              </li>
                              <li>Het bedrag dat daaruit volgt, wordt verhoogd met 40% (forfaitaire verhoging, om kosten als werkgeverspremies, pensioenbijdragen en de opbouw van vakantiegeld te compenseren).
                              </li>
                              <li>Het verhoogde bedrag wordt vermenigvuldigd met het percentage werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 90% daarvan.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="bb-red-ll"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="bb-red-ll" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.bb_red_ll">
                          </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="ll-red-ll">
                            Verlaging wegens een lagere loonsom
                          </label>
                          <ModalWithButton title="Berekening van de verlaging wegens een lagere loonsom" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Loonsom van maart 2020 (of november 2019)</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-2-h9">{{ output.red_ll_explain_2_h9 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 4</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-2-times-4">{{ output.red_ll_explain_2_times_4 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">Loonsom juni, juli, augustus en september 2020</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-2-j17">{{ output.red_ll_explain_2_j17 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">Verschil</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-2-diff">{{ output.red_ll_explain_2_diff }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-2-times-140">{{ output.red_ll_explain_2_times_140 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-2-times-90">{{ output.red_ll_explain_2_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het bedrag van de verlaging is dus:</div>
                                <div class="col-4">&euro; <span id="red-ll-explain-2-ll">{{ output.red_ll_explain_2_ll }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>We nemen het verschil tussen 4&times; de loonsom van maart 2020 (of november 2019) en de opgetelde loonsommen van juni, juli, augustus en september 2020.
                              </li>
                              <li>Het verschil wordt vervolgens vermenigvuldigd met 140% (forfaitaire verhoging) &times; 90% (percentage van de tegemoetkoming).
                              </li>
                              <li>Het omzetverliespercentage wordt dus niet in deze berekening meegenomen. Daardoor is dit verlagingsbedrag misschien hoger dan u had verwacht.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="ll-red-ll"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="ll-red-ll" :class="'form-control border-right-0 ' + output.ll_red_ll_class"
                                   type="text" placeholder=""
                                   readonly v-model="output.ll_red_ll">
                            <div class="input-group-append">
                              <span :class="'input-group-text ' + (output.ll_red_ll_class || 'bg-disabled')">&minus;</span>
                            </div>
                          </div>

                          <hr class="computation-line">
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e23-red-ll">
                            Definitieve tegemoetkoming
                          </label>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e23-red-ll"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e23-red-ll" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e23_red_ll">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- scenario 4: verlaging wegens lagere loonsom + verlaging wegens bedrijfseconomisch ontslag -->
                  <b-collapse id="red-be-ll" class="explain-calculation" v-model="scenariosCalculationVisible.red_be_ll">
                    <b-card>
                      <p>De tegemoetkoming wordt verlaagd om twee redenen:</p>
                      <ul>
                        <li>De gemiddelde loonsom over de periode juni tot en met september 2020 is lager dan de loonsom van maart 2020 (of november 2019).
                        </li>
                        <li>U heeft tussen 1 juni 2020 en 30 september voor een of meer werknemers bedrijfseconomisch ontslag aangevraagd.
                        </li>
                      </ul>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="bb-red-be-ll">
                            Basisbedrag
                          </label>
                          <ModalWithButton title="Berekening van het basisbedrag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span id="red-be-ll-explain-1-j6">{{ output.red_be_ll_explain_1_j6 }}</span>%</div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom maart 2020 (of november 2019)
                                </div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-1-h9">{{ output.red_be_ll_explain_1_h9 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 4</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-1-times-4">{{ output.red_be_ll_explain_1_times_4 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-1-times-140">{{ output.red_be_ll_explain_1_times_140 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk
                                  omzetverliespercentage
                                </div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-1-times-j6">{{ output.red_be_ll_explain_1_times_j6 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-1-times-90">{{ output.red_be_ll_explain_1_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het basisbedrag is dus:</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-1-bb">{{ output.red_be_ll_explain_1_bb }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom van maart 2020 (of november 2019). De voorwaarden voor dit bedrag staan hierboven.
                              </li>
                              <li>Omdat het gaat om een tegemoetkoming voor vier maanden, wordt de loonsom vermenigvuldigd met 4.
                              </li>
                              <li>Het bedrag dat daaruit volgt, wordt verhoogd met 40% (forfaitaire verhoging, om kosten als werkgeverspremies, pensioenbijdragen en de opbouw van vakantiegeld te compenseren).
                              </li>
                              <li>Het verhoogde bedrag wordt vermenigvuldigd met het percentage werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 90% daarvan.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="bb-red-be-ll"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="bb-red-be-ll" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.bb_red_be_ll">
                          </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="ll-red-be-ll">
                            Verlaging wegens een lagere loonsom
                          </label>
                          <ModalWithButton title="Berekening van de verlaging wegens een lagere loonsom" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Loonsom van maart 2020 (of november 2019)</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-2-h9">{{ output.red_be_ll_explain_2_h9 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 4</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-2-times-4">{{ output.red_be_ll_explain_2_times_4 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">Loonsom van juni, juli, augustus en september 2020</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-2-j17">{{ output.red_be_ll_explain_2_j17 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">Verschil</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-2-diff">{{ output.red_be_ll_explain_2_diff }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-2-times-140">{{ output.red_be_ll_explain_2_times_140 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-2-times-90">{{ output.red_be_ll_explain_2_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het bedrag van de verlaging is dus:</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-2-ll">{{ output.red_be_ll_explain_2_ll }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>We nemen het verschil tussen 4&times; de loonsom van maart 2020 (of november 2019) en de opgetelde loonsommen van juni, juli, augustus en september 2020.
                              </li>
                              <li>Het verschil wordt vervolgens vermenigvuldigd met 140% (forfaitaire verhoging) &times; 90% (percentage van de tegemoetkoming).
                              </li>
                              <li>Het omzetverliespercentage wordt dus niet in deze berekening meegenomen. Daardoor is dit verlagingsbedrag misschien hoger dan u had verwacht.
                              </li>
                            </ul>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="ll-red-be-ll"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="ll-red-be-ll" :class="'form-control border-right-0 ' + output.ll_red_be_ll_class"
                                   type="text" placeholder=""
                                   readonly v-model="output.ll_red_be_ll">
                            <div class="input-group-append">
                              <span :class="'input-group-text ' + (output.ll_red_be_ll_class || 'bg-disabled')">&minus;</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="be-red-be-ll">
                            Verlaging wegens bedrijfseconomisch ontslag
                          </label>
                          <ModalWithButton title="Berekening van de verlaging wegens bedrijfseconomisch ontslag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Loonsom maart 2020 (of november 2019) van alle werknemers voor wie bedrijfseconomisch ontslag is aangevraagd
                                </div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-3-r15">{{ output.red_be_ll_explain_3_r15 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-3-times-3">{{ output.red_be_ll_explain_3_times_3 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-3-times-140">{{ output.red_be_ll_explain_3_times_140 }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90%</div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-3-times-90">{{ output.red_be_ll_explain_3_times_90 }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het bedrag van de verlaging is dus:
                                </div>
                                <div class="col-4">&euro; <span id="red-be-ll-explain-3-end">{{ output.red_be_ll_explain_3_end }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Wij gaan uit van de loonsom over maart 2020 (of november 2019) van alle werknemers voor wie u tussen juni en september 2020 bedrijfseconomisch ontslag heeft aangevraagd.
                              </li>
                              <li>Voor de berekening van deze loonsom gelden dezelfde voorwaarden als hierboven.
                              </li>
                              <li>Deze loonsom wordt eerst vermenigvuldigd met 3 en daarna met 140% &times; 90%.
                              </li>
                              <li>Het bedrag dat hieruit volgt, wordt van de tegemoetkoming afgehaald.
                              </li>
                              <li>
                                Het omzetverliespercentage wordt niet in deze berekening meegenomen.
                                Daardoor is dit verlagingsbedrag misschien hoger dan u had verwacht.
                              </li>
                            </ul>
                            <p><b>Let op:</b> Als u voor 20 of meer werknemers bedrijfseconomisch ontslag heeft aangevraagd, dan geldt er een extra verlaging van 5%, bovenop het berekende verlagingsbedrag. Die extra verlaging is niet in deze rekenhulp meegenomen.</p>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="be-red-be-ll"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="be-red-be-ll" :class="'form-control border-right-0 ' + output.be_red_be_ll_class"
                                   type="text" placeholder=""
                                   readonly v-model="output.be_red_be_ll">
                            <div class="input-group-append">
                              <span :class="'input-group-text ' + (output.be_red_be_ll_class || 'bg-disabled')">&minus;</span>
                            </div>
                          </div>

                          <hr class="computation-line">
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e23-red-be-ll">
                            Definitieve tegemoetkoming
                          </label>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e23-red-be-ll"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e23-red-be-ll" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e23_red_be_ll">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- Verschil voorschot en definitieve tegemoetkoming -->
                  <b-card class="pt-0">
                    <div class="form-row">
                      <div class="col-md-7">
                        <label class="mt-1" for="e22_out">
                          Ontvangen voorschot
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label class="sr-only" for="e22_out"> </label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">€</div>
                          </div>
                          <input id="e22_out" class="form-control border-right-0" type="text" placeholder="" readonly v-model="e22">
                          <div class="input-group-append">
                            <span class="input-group-text bg-disabled">&minus;</span>
                          </div>
                        </div>

                        <hr class="computation-line">
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-md-7">
                        <label class="mt-1" for="h27">
                          <span v-if="output.h27_class == 'negative'">Bedrag dat u moet terugbetalen</span>
                          <span v-else>Bedrag dat u nog tegoed heeft</span>
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label class="sr-only" for="h27"> </label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">€</div>
                          </div>
                          <input id="h27" :class="'form-control ' + output.h27_class" type="text" placeholder="" readonly v-model="output.h27">
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-card>
              </b-collapse>
            </div> <!-- /end-result-calculator -->
          </b-card>
        </b-collapse><!-- /end #result -->
      </div>
    </div>

    <div class="d-flex justify-content-between mb-3">
      <div class="width-more">
        <b-button variant="secondary" @click="$emit('prev')" class="mr-2 mb-2">
          <img src="../../assets/icon/arrow.svg" class="icon-chevron-right-solid rotate-180" alt="arrow" />
          Vorige
        </b-button>
      </div>
      <div class="text-right">
        <b-button variant="outline-warning" class="btn-lg mb-2" v-if="isDevelopment()" @click="devFillForm()">
          Random (DEV)
        </b-button>
        <b-button :variant="isLast?'success':'secondary'" class="btn-lg ml-2 mb-2" v-bind:disabled="!resultsVisible" @click="$emit('overview')">
          <img v-if="isLast" src="../../assets/icon/arrow.svg" class="icon-chevron-right-solid" alt="arrow"/>
          Overzicht
        </b-button>
        <b-button variant="success" class="btn-lg ml-2 mb-2" v-if="!isLast" v-bind:disabled="!resultsVisible" @click="$emit('next')">
          <img src="../../assets/icon/arrow.svg" class="icon-chevron-right-solid" alt="arrow"/>
          Volgende periode
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import {
  currencyFormat, excelFloor, excelRound,
  fillAndMarkNegativeIfNotZero, isDevelopment, myIsNaN,
  parseNumber, randomBool, randomFloat, randomInt, randomIntLog,
  sanitizeInteger, sum,
} from '@/repository/functions';
import TrancheResult from '@/repository/models/result';
import ResultsRepository from '@/repository/results-repository';
import ModalWithButton from '@/components/ModalComponent.vue';
import SettlementPeriods from "@/repository/settlement-periods";

export default {
  name: 'Tranche2Tool',
  components: { ModalWithButton },
  data() {
    return {
      changeCounter: 0,
      isPdf: false,
      gteJul27_2023: SettlementPeriods.gteJul27_2023(),
      values: {
        bedrijfseconomisch_ontslag: false,
        werkmaatschappij_onderdeel_van_concern: false,
        j6: '',
        h9: '',
        j12: '',
        j13: '',
        j14: '',
        j15: '',
        r15: '',
        e22: '',
      },
      error: {
        j6: '',
        h9: '',
        j12: '',
        j13: '',
        j14: '',
        j15: '',
        r15: '',
        e22: '',
      },
      valid: {
        j6: null,
        h9: null,
        j12: null,
        j13: null,
        j14: null,
        j15: null,
        r15: null,
        e22: null,
      },
      resultsVisible: false,
      showDetails: false,
      scenariosCalculationVisible: {
        j6_lt_20: false,
        no_red: false,
        red_be: false,
        red_ll: false,
        red_be_ll: false,
      },
      output: {
        'no_red_explain_j6': '',
        'no_red_explain_h9': '',
        'no_red_explain_times_4': '',
        'no_red_explain_times_140': '',
        'no_red_explain_times_j6': '',
        'no_red_explain_times_90': '',
        'no_red_explain_end': '',
        'red_be_explain_1_j6': '',
        'red_be_explain_1_h9': '',
        'red_be_explain_1_times_4': '',
        'red_be_explain_1_times_140': '',
        'red_be_explain_1_times_j6': '',
        'red_be_explain_1_times_90': '',
        'red_be_explain_1_end': '',
        'bb_red_be': '',
        'red_be_explain_2_r15': '',
        'red_be_explain_2_times_3': '',
        'red_be_explain_2_times_140': '',
        'red_be_explain_2_times_90': '',
        'red_be_explain_2_end': '',
        'be_red_be': '',
        'be_red_be_class': '',
        'red_ll_explain_1_j6': '',
        'red_ll_explain_1_h9': '',
        'red_ll_explain_1_times_4': '',
        'red_ll_explain_1_times_140': '',
        'red_ll_explain_1_times_j6': '',
        'red_ll_explain_1_times_90': '',
        'red_ll_explain_1_bb': '',
        'bb_red_ll': '',
        'red_ll_explain_2_h9': '',
        'red_ll_explain_2_times_4': '',
        'red_ll_explain_2_j17': '',
        'red_ll_explain_2_diff': '',
        'red_ll_explain_2_times_140': '',
        'red_ll_explain_2_times_90': '',
        'red_ll_explain_2_ll': '',
        'll_red_ll': '',
        'll_red_ll_class': '',
        'red_be_ll_explain_1_j6': '',
        'red_be_ll_explain_1_h9': '',
        'red_be_ll_explain_1_times_4': '',
        'red_be_ll_explain_1_times_140': '',
        'red_be_ll_explain_1_times_j6': '',
        'red_be_ll_explain_1_times_90': '',
        'red_be_ll_explain_1_bb': '',
        'bb_red_be_ll': '',
        'red_be_ll_explain_2_h9': '',
        'red_be_ll_explain_2_times_4': '',
        'red_be_ll_explain_2_j17': '',
        'red_be_ll_explain_2_diff': '',
        'red_be_ll_explain_2_times_140': '',
        'red_be_ll_explain_2_times_90': '',
        'red_be_ll_explain_2_ll': '',
        'll_red_be_ll': '',
        'll_red_be_ll_class': '',
        'red_be_ll_explain_3_r15': '',
        'red_be_ll_explain_3_times_3': '',
        'red_be_ll_explain_3_times_140': '',
        'red_be_ll_explain_3_times_90': '',
        'red_be_ll_explain_3_end': '',
        'be_red_be_ll': '',
        'be_red_be_ll_class': '',
        'e23_no_red': '',
        'e23_red_be': '',
        'e23_red_ll': '',
        'e23_red_be_ll': '',
        'h27': '',
        'h27_class': '',
        'result_text': '',
        'declaration_type': '',
        'settlement_period_open': false,
        'settlement_period_passed': false,
        'settlement_period_text': '',
      },
    };
  },
  mounted() {
    const result = ResultsRepository.getInstance().retrieve(2);
    if (result !== null) {
      this.bedrijfseconomisch_ontslag = result.trancheVars.values.bedrijfseconomisch_ontslag;
      this.werkmaatschappij_onderdeel_van_concern = result.trancheVars.values.werkmaatschappij_onderdeel_van_concern;
      this.j6 = result.trancheVars.values.j6;
      this.h9 = result.trancheVars.values.h9;
      this.j12 = result.trancheVars.values.j12;
      this.j13 = result.trancheVars.values.j13;
      this.j14 = result.trancheVars.values.j14;
      this.j15 = result.trancheVars.values.j15;
      this.r15 = result.trancheVars.values.r15;
      this.e22 = result.trancheVars.values.e22;
    }
  },
  props: {
    isLast: Boolean,
  },
  computed: {
    j6: {
      get() {this.changeCounter; return this.values.j6;},
      set(val) {this.values.j6 = sanitizeInteger(val, 0, 100); this.calculate(); this.changeCounter++;},
    },
    h9: {
      get() {this.changeCounter; return this.values.h9;},
      set(val) {this.values.h9 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    j12: {
      get() {this.changeCounter; return this.values.j12;},
      set(val) {this.values.j12 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    j13: {
      get() {this.changeCounter; return this.values.j13;},
      set(val) {this.values.j13 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    j14: {
      get() {this.changeCounter; return this.values.j14;},
      set(val) {this.values.j14 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    j15: {
      get() {this.changeCounter; return this.values.j15;},
      set(val) {this.values.j15 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    r15: {
      get() {this.changeCounter; return this.values.r15;},
      set(val) {this.values.r15 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    e22: {
      get() {this.changeCounter; return this.values.e22;},
      set(val) {this.values.e22 = sanitizeInteger(val, 0, 500000000); this.calculate(); this.changeCounter++;},
    },
    bedrijfseconomisch_ontslag: {
      get() {this.changeCounter; return this.values.bedrijfseconomisch_ontslag;},
      set(val) {
        this.values.bedrijfseconomisch_ontslag = val;
        if (!val) {
          this.r15 = '0';
        }
        this.calculate();
        this.changeCounter++;
      },
    },
    werkmaatschappij_onderdeel_van_concern: {
      get() {this.changeCounter; return this.values.werkmaatschappij_onderdeel_van_concern;},
      set(val) {this.values.werkmaatschappij_onderdeel_van_concern = val; this.calculate(); this.changeCounter++;},
    },
  },
  methods: {
    isDevelopment,
    /*erase() {
      this.values.bedrijfseconomisch_ontslag = false;
      this.values.werkmaatschappij_onderdeel_van_concern = false;
      this.values.j6 = '';
      this.values.h9 = '';
      this.values.j12 = '';
      this.values.j13 = '';
      this.values.j14 = '';
      this.values.j15 = '';
      this.values.r15 = '';
      this.values.e22 = '';
      this.calculate();
      this.changeCounter++;
    },*/
    allFieldsFilled() {
      return this.values.j6 !== '' && this.values.h9 !== ''
          && this.values.j12 !== '' && this.values.j13 !== '' && this.values.j14 !== '' && this.values.j15 !== ''
          && this.values.e22 !== '';
      // r15 is not required
    },
    validate(validateAll) {
      let hasError = false;

      for (const key in this.values) {
        this.error[key] = '';
        this.valid[key] = this.values[key] === '' ? null : true;
      }

      if (validateAll) {
        for (const key in this.values) {
          if (key !== 'r15' && this.values[key] === '') {
            hasError = true;
            this.error[key] = 'Dit veld is verplicht';
          }
        }
      }

      const extraVal = this.extraValidate();

      return hasError === false && extraVal === false;
    },
    extraValidate() {
      let hasError = false;

      const h9 = parseNumber(this.values.h9);
      const r15 = parseNumber(this.values.r15);

      if (r15 > h9) {
        hasError = true;
        this.error.r15 = "Dit bedrag mag niet groter zijn dan de loonsom maart";
      } else if (r15 !== "" && !myIsNaN(r15)) {
        this.error.r15 = '';
      }

      return hasError;
    },
    calculate(validateAll) {
      if (this.validate(validateAll) && this.allFieldsFilled()) {
        this.calculateNow();
        this.resultsVisible = true;
      } else {
        this.resultsVisible = false;

        ResultsRepository.getInstance().add(2, null);
        this.$emit('updateResults');
      }
    },
    calculateNow() {
      const p4 = 1.4;  // opslagfactor
      const p5 = .9;  // tegemoetkoming
      const p6 = .8;  // voorschot

      // const h5 = parseNumber($('#h5').val()) / 100;  // verwachte omzetdaling
      const j6 = parseNumber(this.values.j6) / 100;  // gerealiseerde omzetdaling
      const h9 = parseNumber(this.values.h9);  // loonsom maart 2020
      // const j10 = h9;  // j10 komt te vervallen: loonsom november 2019
      const j9 = h9;  // =H9
      const j12 = parseNumber(this.values.j12);  // loonsom juni 2020
      const j13 = parseNumber(this.values.j13);  // loonsom juli 2020
      const j14 = parseNumber(this.values.j14);  // loonsom augustus 2020
      const j15 = parseNumber(this.values.j15);  // loonsom september 2020
      let r15 = parseNumber(this.values.r15);  // BE-ontslag

      if (r15 === "" || myIsNaN(r15)) {
        r15 = 0;
      }

      const e22 = parseNumber(this.values.e22);  // voorschotbetaling

      // Loonsom juni, juli, augustus, september
      const j17 = sum([j12, j13, j14, j15]);  // =SUM(J12:J15)

      // j10 wordt niet uitgevraagd
      const p21 = 4 * j9;  // =IF((P19=1),(4*J10),(4*J9))

      // is loonsom jun-sep lager?
      const p23 = j17 < p21 ? 'ja' : 'nee';  // =IF(J17<P21,"Ja","Nee")

      // subsidievaststelling
      const e23 = Math.max(0, j6 > 0.19 ? p23 === 'nee' ? sum([(j6 * p21 * 1.4 * .9)-(r15 * 3 * 1.4 * .9)]) : ((((j6 * p21 * 1.4 * .9) - ((p21 - j17) * 1.4 * .9)))-(r15 * 3 * 1.4 * .9) ) : 0);
      // =MAX(0,IF((J6>0.19),IF(P23="nee",SUM((J6*P21*1.4*0.9)-(R15*3*1.4*0.9)),(((J6*P21*1.4*0.9)-((P21-J17)*1.4*0.9)))-(R15*3*1.4*0.9)),0))

      // is subsidie vastgesteld
      // const r21 = j17 > 0 ? false : true;  // =IF(J17>0,0,1)

      // geen check of de loonsommen gezet zijn (j17 > 0). Zorgt er namelijk voor dat wanneer j12,13,14,15 de waarde 0 hebben
      // er geen volledige terugbetaling wordt getoond voor h27 (zou op 0 uitkomen terwijl er een negatief bedrag uit moet komen)
      // terugvordering / nabetaling
      const h27 = e23 - e22;  // =IF((R21=0),(E23-E22),0)

      // === Laat de verschillende tussen-berekeningen zien ===
      let calcSet = '';

      this.scenariosCalculationVisible.j6_lt_20 = false;
      this.scenariosCalculationVisible.no_red = false;
      this.scenariosCalculationVisible.red_be = false;
      this.scenariosCalculationVisible.red_ll = false;
      this.scenariosCalculationVisible.red_be_ll = false;

      if (j6 < 0.2) {
        calcSet = 'j6_lt_20';
      } else if (p23 === 'nee' && r15 === 0) {
        calcSet = 'no_red';

        let explainValue = h9;

        this.output.no_red_explain_j6 = Math.round(j6 * 100);
        this.output.no_red_explain_h9 = currencyFormat(explainValue);
        this.output.no_red_explain_times_4 = currencyFormat(explainValue *= 4);
        this.output.no_red_explain_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.no_red_explain_times_j6 = currencyFormat(explainValue *= j6);
        this.output.no_red_explain_times_90 = currencyFormat(explainValue *= .9);
        this.output.no_red_explain_end = currencyFormat(e23);
      } else if (p23 === 'nee' && r15 > 0) {
        calcSet = 'red_be';

        let explainValue = h9;

        this.output.red_be_explain_1_j6 = Math.round(j6 * 100);
        this.output.red_be_explain_1_h9 = currencyFormat(explainValue);
        this.output.red_be_explain_1_times_4 = currencyFormat(explainValue *= 4);
        this.output.red_be_explain_1_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.red_be_explain_1_times_j6 = currencyFormat(explainValue *= j6);
        this.output.red_be_explain_1_times_90 = currencyFormat(explainValue *= .9);
        this.output.red_be_explain_1_end = currencyFormat(explainValue);

        const basisbedrag = explainValue;
        this.output['bb_' + calcSet] = currencyFormat(basisbedrag);

        explainValue = r15;

        this.output.red_be_explain_2_r15 = currencyFormat(explainValue);
        this.output.red_be_explain_2_times_3 = currencyFormat(explainValue *= 3);
        this.output.red_be_explain_2_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.red_be_explain_2_times_90 = currencyFormat(explainValue *= .9);
        this.output.red_be_explain_2_end = currencyFormat(explainValue);

        fillAndMarkNegativeIfNotZero(this.output, 'be_' + calcSet, explainValue);
      } else if (p23 === 'ja' && r15 === 0) {
        calcSet = 'red_ll';

        let explainValue = h9;

        this.output.red_ll_explain_1_j6 = Math.round(j6 * 100);
        this.output.red_ll_explain_1_h9 = currencyFormat(h9);
        this.output.red_ll_explain_1_times_4 = currencyFormat(explainValue *= 4);
        this.output.red_ll_explain_1_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.red_ll_explain_1_times_j6 = currencyFormat(explainValue *= j6);
        this.output.red_ll_explain_1_times_90 = currencyFormat(explainValue *= .9);
        this.output.red_ll_explain_1_bb = currencyFormat(explainValue);

        const basisbedrag = explainValue;
        this.output['bb_' + calcSet] = currencyFormat(basisbedrag);

        explainValue = (h9 * 4) - j17;

        this.output.red_ll_explain_2_h9 = currencyFormat(h9);
        this.output.red_ll_explain_2_times_4 = currencyFormat(h9 * 4);
        this.output.red_ll_explain_2_j17 = currencyFormat(j17);
        this.output.red_ll_explain_2_diff = currencyFormat(explainValue);
        this.output.red_ll_explain_2_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.red_ll_explain_2_times_90 = currencyFormat(explainValue *= .9);
        this.output.red_ll_explain_2_ll = currencyFormat(explainValue);

        fillAndMarkNegativeIfNotZero(this.output, 'll_' + calcSet, explainValue);
      } else if (p23 === 'ja' && r15 > 0) {
        calcSet = 'red_be_ll';

        let explainValue = h9;

        this.output.red_be_ll_explain_1_j6 = Math.round(j6 * 100);
        this.output.red_be_ll_explain_1_h9 = currencyFormat(explainValue);
        this.output.red_be_ll_explain_1_times_4 = currencyFormat(explainValue *= 4);
        this.output.red_be_ll_explain_1_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.red_be_ll_explain_1_times_j6 = currencyFormat(explainValue *= j6);
        this.output.red_be_ll_explain_1_times_90 = currencyFormat(explainValue *= .9);
        this.output.red_be_ll_explain_1_bb = currencyFormat(explainValue);

        const basisbedrag = explainValue
        this.output['bb_' + calcSet] = currencyFormat(basisbedrag);

        explainValue = (h9 * 4) - j17;

        this.output.red_be_ll_explain_2_h9 = currencyFormat(h9);
        this.output.red_be_ll_explain_2_times_4 = currencyFormat(h9 * 4);
        this.output.red_be_ll_explain_2_j17 = currencyFormat(j17);
        this.output.red_be_ll_explain_2_diff = currencyFormat(explainValue);
        this.output.red_be_ll_explain_2_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.red_be_ll_explain_2_times_90 = currencyFormat(explainValue *= .9);
        this.output.red_be_ll_explain_2_ll = currencyFormat(explainValue);

        const lagereLoonsom = explainValue;
        fillAndMarkNegativeIfNotZero(this.output, 'll_' + calcSet, lagereLoonsom);

        explainValue = r15;

        this.output.red_be_ll_explain_3_r15 = currencyFormat(explainValue);
        this.output.red_be_ll_explain_3_times_3 = currencyFormat(explainValue *= 3);
        this.output.red_be_ll_explain_3_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.red_be_ll_explain_3_times_90 = currencyFormat(explainValue *= .9);
        this.output.red_be_ll_explain_3_end = currencyFormat(explainValue);

        fillAndMarkNegativeIfNotZero(this.output, 'be_' + calcSet, explainValue);
      }

      const explainValue = h9;

      this.output['e23_' + calcSet] = currencyFormat(e23);
      this.scenariosCalculationVisible[calcSet] = true;

      const h27Result = this.output.h27 = currencyFormat(h27);

      // Set some styling for the final output
      this.output.h27_class = '';
      if (h27 > 0) {
        this.output.result_text = "U ontvangt een nabetaling";
        this.output.h27_class = "positive";
      }
      if (h27 < 0) {
        this.output.h27_class = "negative";
        this.output.result_text = "U moet een bedrag terugbetalen";
        this.output.h27 = currencyFormat(excelRound(h27)*-1);
      }
      if(h27 == 0){
        this.output.result_text = "Terugvordering / Nabetaling";
      }

      // Toon als verklaring meegestuurd moet worden bij welke bedragen.
      if(this.values.werkmaatschappij_onderdeel_van_concern || e22>=100000 || e23>=125000){
        this.output.declaration_type = 3;
      }else if(e22>=20000 || e23>=25000){
        this.output.declaration_type = 2;
      }else{
        this.output.declaration_type = 1;
      }

      this.output.settlement_period_open = SettlementPeriods.isOpen(2);
      this.output.settlement_period_passed = SettlementPeriods.hasPassed(2);
      this.output.settlement_period_text = SettlementPeriods.trancheComponentText(2);

      ResultsRepository.getInstance().add(2, new TrancheResult(this.output.declaration_type, h27Result, {
        values: this.values,
        scenariosCalculationVisible: this.scenariosCalculationVisible,
        output: this.output,
        gteJul27_2023: this.gteJul27_2023,
      }));
      this.$emit('updateResults');
    },
    devFillForm() {
      if (!isDevelopment()) {
        return;
      }
      this.j6 = '' + randomInt(0, 100);
      this.h9 = '' + randomIntLog(0, 500000000);
      this.j12 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.j13 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.j14 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.j15 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.bedrijfseconomisch_ontslag = randomBool();
      this.r15 = this.bedrijfseconomisch_ontslag ? '' + Math.round(parseNumber(this.h9) * randomFloat(0, 1)) : '0';
      this.e22 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.werkmaatschappij_onderdeel_van_concern = randomBool();
    }
  }
};
</script>

<style scoped>
div.card-body {
  padding: 0
}

div.card {
  border: 0;
}
</style>
