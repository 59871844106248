<template>
  <div>
    <!--  <div class="col-12 col-lg-10">-->
    <div class="box bg-blue text-white">
      <div class="">
        <h2>Gegevens 6<sup>e</sup> aanvraagperiode (NOW 4)</h2>

        <div class="form-calculator" id="form">
          <input autocomplete="false" name="hidden" type="text" class="display-none" readonly>

          <div class="mb-5" v-if="output.settlement_period_passed && !gteJul27_2023">
            Voor de 6<sup>e</sup> aanvraagperiode NOW kunt u geen definitieve berekening meer aanvragen.
            Heeft u nog geen definitieve berekening over deze aanvraagperiode gedaan?
            Dan ontvangt u van ons een brief waarin staat wat u moet doen.
            Of ga hiervoor naar <a target="_blank" class="box-link" href="https://www.uwv.nl/werkgevers/overige-onderwerpen/now/mijn-now/index.aspx">Mijn NOW</a>.
          </div>

          <div class="mb-5" v-if="output.settlement_period_passed && gteJul27_2023">
            <b>Let op:</b> Voor de 6<sup>e</sup> aanvraagperiode NOW kunt u geen definitieve berekening meer aanvragen.

            <ModalWithButton title="Loket definitieve berekening gesloten" image="info">
              <p>
                Voor de 6<sup>e</sup> aanvraagperiode NOW kunt u geen definitieve berekening meer aanvragen.
                Als u nog geen definitieve berekening heeft aangevraagd, moet u het ontvangen voorschot terugbetalen.
              </p>
            </ModalWithButton>
          </div>

          <!-- Loonsom februari 2021 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="h9">
                Loonsom februari 2021
              </label>
              <ModalWithButton title="Loonsom februari 2021" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 april 2021 bij de Belastingdienst bekend waren.</li>
                  <li>De maximale loonsom per werknemer is &euro;&nbsp;9.813 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan de loonsom in het 2e aangiftetijdvak van 2021 (1 februari tot en met 28 februari).
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="h9"> </label>
              <div
                  :class="'input-group mb-2' +
                  (error.h9 ? ' is-invalid' : '') + (valid.h9 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text"
                       :class="'form-control numeric' +
                         (error.h9 ? ' is-invalid' : '') + (valid.h9 ? ' is-valid' : '')"
                       id="h9" placeholder=""
                       data-min="0" data-max="500000000" v-model="h9" autocomplete="off">
              </div>
              <!-- tonen als is-invalid gezet wordt -->
              <div class="invalid-feedback text-right mb-2">{{ error.h9 }}</div>
            </div>
          </div>

          <!-- Loonsom juli 2021 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j12">
                Loonsom juli 2021
              </label>
              <ModalWithButton title="Loonsom juli 2021" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 november 2021 bij de Belastingdienst bekend zijn.</li>
                  <li>De maximale loonsom per werknemer is &euro;&nbsp;9.813 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan de loonsom in het 7e aangiftetijdvak van 2021 (21 juni tot en met 18 juli 2021).
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5">
              <label class="sr-only" for="j12"> </label>
              <div
                  :class="'input-group mb-2' +
                  (error.j12 ? ' is-invalid' : '') + (valid.j12 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text"
                       :class="'form-control numeric' +
                         (error.j12 ? ' is-invalid' : '') + (valid.j12 ? ' is-valid' : '')"
                       id="j12" placeholder=""
                       data-min="0" data-max="500000000" v-model="j12" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j12 }}</div>
            </div>
          </div>

          <!-- Loonsom augustus 2021 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j13">
                Loonsom augustus 2021
              </label>
              <ModalWithButton title="Loonsom augustus 2021" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 november 2021 bij de Belastingdienst bekend zijn.</li>
                  <li>De maximale loonsom per werknemer is &euro;&nbsp;9.813 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan de loonsom in het 8e aangiftetijdvak van 2021 (19 juli tot en met 15 augustus 2021).
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5">
              <label class="sr-only" for="j13"> </label>
              <div
                  :class="'input-group mb-2' +
                   (error.j13 ? ' is-invalid' : '') + (valid.j13 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text"
                       :class="'form-control numeric' +
                         (error.j13 ? ' is-invalid' : '') + (valid.j13 ? ' is-valid' : '')"
                       id="j13" placeholder=""
                       data-min="0" data-max="500000000" v-model="j13" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j13 }}</div>
            </div>
          </div>

          <!-- Loonsom september 2021 -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j14">
                Loonsom september 2021
              </label>
              <ModalWithButton title="Loonsom september 2021" image="info">
                <p>Voor dit bedrag gelden de volgende voorwaarden:</p>
                <ul>
                  <li>UWV gaat uit van de gegevens die op 15 november 2021 bij de Belastingdienst bekend zijn.</li>
                  <li>De maximale loonsom per werknemer is &euro;&nbsp;9.813 per maand.</li>
                  <li>Extra periodieke betalingen, zoals vakantiegeld of een dertiende maand, worden niet meegenomen in de loonsom.</li>
                  <li>Extra incidentele betalingen, zoals prestatiebonussen, worden wel meegenomen in de loonsom.</li>
                  <li>Betaalt u het vakantiegeld telkens gelijktijdig met het loon? Vermenigvuldig de loonsom dan met factor 0,926.</li>
                  <li>
                    Betaalt u het loon niet per maand, maar per 4 weken?
                    Neem dan de loonsom in het 9e aangiftetijdvak van 2021 (16 augustus tot en met 12 september 2021).
                    Vermenigvuldig deze loonsom met factor 1,0833 om de loonsom om te rekenen naar een maandbedrag.
                  </li>
                </ul>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="j14"> </label>
              <div
                  :class="'input-group mb-2' +
                   (error.j14 ? ' is-invalid' : '') + (valid.j14 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">€</div>
                </div>
                <input type="text"
                       :class="'form-control numeric' +
                          (error.j14 ? ' is-invalid' : '') + (valid.j14 ? ' is-valid' : '')"
                       id="j14" placeholder=""
                       data-min="0" data-max="500000000" v-model="j14" autocomplete="off">
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j14 }}</div>
            </div>
          </div>

          <!-- Ontvangen voorschot -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="e20">
                Totaal ontvangen voorschot
              </label>
              <ModalWithButton title="Hoogte voorschot" image="info">
                <p>
                  Als u recht had op een voorschot tegemoetkoming NOW in deze aanvraagperiode, heeft u daarover een beslissing ontvangen.
                  In die beslissing staat hoe hoog het totale voorschot is.
                </p>
              </ModalWithButton>
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="e20"> </label>
              <div :class="'input-group mb-2' + (error.e20 ? ' is-invalid' : '') + (valid.e20 ? ' is-valid' : '')">
                <div class="input-group-prepend">
                  <div class="input-group-text">&euro;</div>
                </div>
                <input id="e20" :class="'form-control numeric' + (error.e20 ? ' is-invalid' : '') + (valid.e20 ? ' is-valid' : '')" type="text" placeholder=""
                       data-min="0" data-max="500000000" v-model="e20" autocomplete="off">
              </div>
            </div>
          </div>

          <!-- Werkelijke omzetverlies -->
          <div class="form-row">
            <div class="col-md-7">
              <label class="mt-1" for="j6">
                Werkelijk omzetverlies
              </label>
              <ModalWithButton title="Werkelijk omzetverlies" image="info">
                <p>
                  Dit is het werkelijke percentage omzetverlies over de periode in uw aanvraag van de tegemoetkoming, met een maximum van 80%.
                  Het definitieve percentage weet u pas nadat uw accountant of boekhouder dat heeft vastgesteld.
                  Als u zelf een inschatting van het omzetverlies wilt maken, kunt u hiervoor de
                  <a target="_blank" href="https://www.rekenhulpomzetverlies.nl/">Rekenhulp omzetverlies</a> gebruiken.
                </p>

                <h6>Maximaal 80% omzetverlies</h6>
                <p>
                  Bij de definitieve berekening van de 6<sup>e</sup> aanvraagperiode kunt u maximaal 80% omzetverlies opgeven.
                  Heeft u meer dan 80% omzetverlies, dan vult u 80% in.
                </p>
              </ModalWithButton>
              <!-- Info Button trigger modal -->
            </div>
            <div class="col-md-5 mb-4 mb-md-3">
              <label class="sr-only" for="j6"> </label>
              <div
                  :class="'input-group mb-2' +
                  (error.j6 ? ' is-invalid' : '') + (valid.j6 ? ' is-valid' : '')">
                <input type="text"
                       :class="'form-control numeric' +
                         (error.j6 ? ' is-invalid' : '') + (valid.j6 ? ' is-valid' : '')"
                       id="j6" placeholder=""
                       data-min="0" data-max="100" v-model="j6" autocomplete="off">
                <div class="input-group-prepend">
                  <div class="input-group-text input-group-text-right">%</div>
                </div>
              </div>
              <div class="invalid-feedback text-right mb-2">{{ error.j6 }}</div>
            </div>
          </div>

          <!-- Onderdeel van concern? -->
          <div class="form-row indent">
            <div class="col-md-12">
              <label class="mt-1" for="werkmaatschappij_onderdeel_van_concern">
                <input type="checkbox" name="werkmaatschappij_onderdeel_van_concern"
                       id="werkmaatschappij_onderdeel_van_concern"
                       v-model="werkmaatschappij_onderdeel_van_concern"
                       autocomplete="off"
                /> &nbsp;
                Ik ga uit van het omzetverlies van mijn werkmaatschappij

                <ModalWithButton title="Aanvraag op werkmaatschappij-niveau" image="info">
                  <p>
                    Als u onderdeel bent van een groep of concern en het totale concern heeft minder dan 20% omzetverlies,
                    dan kunt u ervoor kiezen om een aanvraag te doen op werkmaatschappijniveau.
                    Bij de definitieve berekening gaat u dan uit van het omzetverlies van het groeps- of concernonderdeel (de werkmaatschappij) in plaats van het omzetverlies van de groep of het concern als geheel.
                  </p>

                  <p>
                    Bij een aanvraag op werkmaatschappijniveau gelden er extra voorwaarden.
                    Vraag uw accountant om meer informatie hierover of kijk op de <a target="_blank" href="https://www.nba.nl/nba-helpt/helpdesk-now-regeling/">website van de Koninklijke Nederlandse Beroepsorganisatie van Accountants (NBA)</a>.
                  </p>

                  <p>
                    Of u onderdeel bent van een concern of groep kunt u navragen bij uw accountant of financieel adviseur.
                  </p>
                </ModalWithButton>
              </label>

              <div v-if="werkmaatschappij_onderdeel_van_concern">
                Als u onderdeel bent van een groep of concern en een definitieve berekening aanvraagt op werkmaatschappijniveau, gelden er extra voorwaarden.
              </div>
            </div>
          </div>
          <br>

        </div> <!-- /form-calculator -->

        <b-collapse v-model="resultsVisible" id="results">
          <b-card>
            <div id="end-result" class="end-result-calculator">
              <h2 class="inline">Uw tegemoetkoming volgens de ingevulde gegevens</h2>

              <p class="mb-1" v-if="output.h25_class == 'negative'">
                U moet <strong>&euro; {{ output.h25 }}</strong> terugbetalen.
              </p>
              <p class="mb-1" v-else>
                U heeft een bedrag van <strong>&euro; {{ output.h25 }}</strong> tegoed.
              </p>

              <!-- Meesturen verklaring? -->
              <div class="mb-1">
                <span id="declaration1" class="declaration" v-if="output.declaration_type == 1">U hoeft <strong>geen verklaring</strong> mee te sturen met uw aanvraag van de definitieve berekening.</span>
                <span id="declaration2" class="declaration" v-if="output.declaration_type == 2">U moet een <strong>derdenverklaring</strong> meesturen met uw aanvraag van de definitieve berekening.</span>
                <span id="declaration3" class="declaration" v-if="output.declaration_type == 3">U moet een <strong>accountantsverklaring</strong> meesturen met uw aanvraag van de definitieve berekening.</span>

                <ModalWithButton title="Moet u een verklaring meesturen?" image="info">
                  <p>In sommige situaties heeft UWV voor de definitieve berekening van de tegemoetkoming een accountantsverklaring of derdenverklaring nodig. Deze verklaring moet u meesturen met de aanvraag van de definitieve berekening.
                  </p>
                  <p>In de volgende situaties moet u een <b>accountantsverklaring</b> meesturen:</p>
                  <ul>
                    <li>bij een voorschot van &euro; 125.000 of meer
                    </li>
                    <li>bij een definitieve tegemoetkoming van &euro; 125.000 of meer (ongeacht de hoogte van het betaalde voorschot)
                    </li>
                    <li>bij een aanvraag op werkmaatschappijniveau (als u onderdeel bent van een groep of concern, zie voor meer informatie hieronder)
                    </li>
                  </ul>
                  <p>In de volgende situaties moet u een <b>derdenverklaring</b> meesturen:</p>
                  <ul>
                    <li>bij een voorschot tussen &euro; 40.000 en &euro; 125.000
                    </li>
                    <li>bij een definitieve tegemoetkoming tussen &euro; 40.000 en &euro; 125.000 (ongeacht de hoogte van het voorschot)
                    </li>
                  </ul>
                  <h6>Als u onderdeel bent van een groep of concern</h6>
                  <p>Voor werkgevers die onderdeel zijn van een groep of concern geldt het volgende:</p>
                  <ul>
                    <li>Als u een aanvraag op concernniveau doet, dan telt u de bedragen van alle aanvragen (dus van alle loonheffingennummers) binnen uw groep of concern bij elkaar op. Het totaalbedrag bepaalt of u een accountants- of derdenverklaring nodig heeft.</li>
                    <li>Heeft uw concern of groep als geheel minder dan 20% omzetverlies en doet u daarom een aanvraag op werkmaatschappijniveau? Dan heeft u altijd een accountantsverklaring nodig. Bij een definitieve tegemoetkoming van &euro;375.000 of meer is dat een <b>assurancerapport met een redelijke mate van zekerheid</b>. Is uw definitieve tegemoetkoming minder dan &euro;375.000, dan kunt u kiezen tussen een <b>assurancerapport met een redelijke mate van zekerheid</b> of een <b>assurancerapport met een beperkte mate van zekerheid</b>.</li>
                  </ul>
                </ModalWithButton>
              </div>

              <p v-if="output.settlement_period_passed">De aanvraag voor deze periode is al <strong>gesloten</strong>.</p>
              <p v-if="output.settlement_period_open">Uiterlijk <strong>{{ output.settlement_period_text }}</strong> kunt u een aanvraag definitieve berekening indienen.</p>
              <p v-if="!output.settlement_period_passed && !output.settlement_period_open">Vanaf <strong>{{ output.settlement_period_text }}</strong> kunt u een aanvraag definitieve berekening indienen.</p>
            </div> <!-- /end-result-calculator -->

            <div :class="'details-box ' + (showDetails ? 'details-box-show' : '')">
              <b-button variant="success" @click="showDetails = !showDetails; $event.currentTarget.blur()">
                <span v-if="showDetails"><img class="icon-chevron-right-solid up-down" src="../../assets/icon/arrow-up.svg" alt="up" /> Verberg details</span>
                <span v-else><img class="icon-chevron-right-solid up-down" src="../../assets/icon/arrow-down.svg" alt="up" /> Toon details</span>
              </b-button>
              <b-collapse class="mt-1 pb-2" v-model="showDetails">
                <b-card>
                  <!-- (0a) loonsom jul-sep gelijk aan 0 -->
                  <b-collapse id="zero" class="scenarios-calculation"
                              v-model="scenariosCalculationVisible.zero">
                    <b-card>
                      <p>
                        Uw opgetelde loonsom over juli, augustus en september 2021 is &euro; 0.
                        In dat geval heeft u geen recht op een tegemoetkoming NOW.
                      </p>
                      <!-- Definitieve tegemoetkoming -->
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e21-zero">
                            Definitieve tegemoetkoming
                          </label>
                          <ModalWithButton title="Geen recht op tegemoetkoming" image="calc">
                            <p>
                              Uw loonsom over juli, augustus en september 2021 is bij elkaar opgeteld &euro; 0.
                              Bij een loonsom van &euro; 0 heeft u de tegemoetkoming niet gebruikt waarvoor deze bedoeld is, namelijk om het loon van uw werknemers door te betalen.
                              Daarom wordt de definitieve tegemoetkoming vastgesteld op &euro; 0.
                              Dat betekent dat u alles wat u aan voorschot heeft ontvangen, moet terugbetalen.
                            </p>

                            <div class="container-fluid px-0">
                              <div class="row">
                                <div class="col-8">Uw definitieve tegemoetkoming</div>
                                <div class="col-4">&euro; 0</div>
                              </div>
                            </div>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e21-gte"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e21-zero" class="form-control" type="text" placeholder=""
                                   readonly value="0">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- (0b) werkelijke omzetverlies < 20%, alles terugbetalen -->
                  <b-collapse id="j6-lt-20" class="scenarios-calculation" v-model="scenariosCalculationVisible.j6_lt_20">
                    <b-card>
                      <p>
                        Uw heeft minder dan 20% omzetverlies.
                        In dat geval heeft u geen recht op een tegemoetkoming NOW.
                      </p>
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e21-j6_lt_20">
                            Definitieve tegemoetkoming
                          </label>
                          <ModalWithButton title="Geen recht op tegemoetkoming" image="calc">
                            <p>
                              Uw omzetverlies is minder dan 20%.
                              In dat geval heeft u geen recht op een tegemoetkoming.
                              Daarom wordt de definitieve tegemoetkoming vastgesteld op &euro; 0.
                              Dat betekent dat u alles wat u aan voorschot heeft ontvangen, moet terugbetalen.
                            </p>

                            <div class="container-fluid px-0">
                              <div class="row">
                                <div class="col-8">Uw definitieve tegemoetkoming</div>
                                <div class="col-4">&euro; 0</div>
                              </div>
                            </div>
                          </ModalWithButton>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e21-j6_lt_20"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">&euro;</div>
                            </div>
                            <input id="e21-j6_lt_20" class="form-control" type="text" placeholder=""
                                   readonly value="0">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- (1) loonsom jul-sep hoger dan/gelijk aan loonsom feb-->
                  <b-collapse id="gte" class="scenarios-calculation"
                              v-model="scenariosCalculationVisible.gte">
                    <b-card>
                      <!-- Definitieve tegemoetkoming -->
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e21-gte">
                            Definitieve tegemoetkoming
                          </label>

                          <ModalWithButton title="Berekening van de tegemoetkoming"
                                           image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span
                                    id="gte-explain-j6">{{ output.gte_explain_j6 }}</span>%
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom februari 2021
                                </div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-h9">{{ output.gte_explain_h9 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-times-3">{{ output.gte_explain_times_3 }}</span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-times-140">{{
                                    output.gte_explain_times_140
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk omzetverliespercentage
                                </div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-times-j6">{{
                                    output.gte_explain_times_j6
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 85%</div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-times-85">{{
                                    output.gte_explain_times_85
                                  }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">De tegemoetkoming is
                                  dus:
                                </div>
                                <div class="col-4">&euro; <span
                                    id="gte-explain-e21">{{ output.gte_explain_e21 }}</span></div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>
                                Wij gaan uit van de loonsom van februari 2021.
                                De voorwaarden voor dit bedrag staan hierboven.
                              </li>
                              <li>Omdat het gaat om een tegemoetkoming voor drie maanden, wordt de
                                gemaximeerde loonsom vermenigvuldigd met 3.
                              </li>
                              <li>Het bedrag dat daaruit volgt, wordt verhoogd met 40%
                                (forfaitaire verhoging, om kosten als werkgeverspremies,
                                pensioenbijdragen en de opbouw van vakantiegeld te compenseren).
                              </li>
                              <li>Het verhoogde bedrag wordt vermenigvuldigd met het percentage
                                werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 85% daarvan.</li>
                            </ul>
                          </ModalWithButton>

                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e21-gte"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e21-gte" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e21_gte">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- (2) loonsom jul-sep lager dan loonsom feb, daling <= 10% -->
                  <b-collapse id="lt-sm" class="scenarios-calculation"
                              v-model="scenariosCalculationVisible.lt_sm">
                    <b-card>
                      <p>
                        Uw loonsom over juli, augustus en september 2021 is met <span
                          class="percentage-daling">{{ output.percentage_daling }}</span>% gedaald in vergelijking
                        met 3&times; de loonsom van februari 2021. Deze daling heeft geen gevolgen voor de tegemoetkoming.

                        <ModalWithButton title="Lagere loonsom" image="info">
                          <span>
                            De NOW-regeling is bedoeld om de loonsom in de periode waarover u de tegemoetkoming ontvangt zoveel mogelijk gelijk te houden.
                            In de 3e, 4e, 5e en 6e aanvraagperiode mag uw loonsom met 10% dalen zonder dat dit gevolgen heeft voor de hoogte van de tegemoetkoming.
                            Bij een daling van meer dan 10% wordt de tegemoetkoming verlaagd.
                          </span>
                        </ModalWithButton>
                      </p>

                      <!-- Definitieve tegemoetkoming -->
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e21-lt-sm">
                            Definitieve tegemoetkoming
                          </label>

                          <ModalWithButton title="Berekening van de tegemoetkoming"
                                           image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span
                                    id="lt-sm-explain-j6">{{ output.lt_sm_explain_j6 }}</span>%
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom februari 2021
                                </div>
                                <div class="col-4">&euro; <span
                                    id="lt-sm-explain-h9">{{ output.lt_sm_explain_h9 }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span
                                    id="lt-sm-explain-times-3">{{
                                    output.lt_sm_explain_times_3
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span
                                    id="lt-sm-explain-times-140">{{
                                    output.lt_sm_explain_times_140
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk omzetverliespercentage
                                </div>
                                <div class="col-4">&euro; <span
                                    id="lt-sm-explain-times-j6">{{
                                    output.lt_sm_explain_times_j6
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 85%</div>
                                <div class="col-4">&euro; <span
                                    id="lt-sm-explain-times-85">{{
                                    output.lt_sm_explain_times_85
                                  }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">De tegemoetkoming is
                                  dus:
                                </div>
                                <div class="col-4">&euro; <span
                                    id="lt-sm-explain-e21">{{ output.lt_sm_explain_e21 }}</span>
                                </div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>
                                Wij gaan uit van de loonsom van februari 2021.
                                De voorwaarden voor dit bedrag staan hierboven.
                              </li>
                              <li>Omdat het gaat om een tegemoetkoming voor drie maanden, wordt de
                                gemaximeerde loonsom vermenigvuldigd met 3.
                              </li>
                              <li>Het bedrag dat daaruit volgt, wordt verhoogd met 40%
                                (forfaitaire verhoging, om kosten als werkgeverspremies,
                                pensioenbijdragen en de opbouw van vakantiegeld te compenseren).
                              </li>
                              <li>Het verhoogde bedrag wordt vermenigvuldigd met het percentage
                                werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 85% daarvan.</li>
                            </ul>
                          </ModalWithButton>

                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e21-lt-sm"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e21-lt-sm" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e21_lt_sm">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- (3) loonsom jul-sep lager dan loonsom feb, daling > 10% -->
                  <b-collapse id="lt-lg" class="scenarios-calculation"
                              v-model="scenariosCalculationVisible.lt_lg">
                    <b-card>
                      <p>Uw loonsom over juli, augustus en september 2021 is met <span
                          class="percentage-daling">{{ output.percentage_daling }}</span>% gedaald in vergelijking
                        met 3&times; de loonsom van februari 2021. Daarom wordt de tegemoetkoming verlaagd.
                      </p>
                      <!-- Basisbedrag tegemoetkoming -->
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="basisbedrag-lt-lg">
                            Basisbedrag
                          </label>
                          <ModalWithButton title="Berekening van het basisbedrag" image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Werkelijk omzetverlies</div>
                                <div class="col-4"><span
                                    id="lt-lg-explain-1-j6">{{ output.lt_lg_explain_base.j6 }}</span>%
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-8">Loonsom van februari 2021
                                </div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-1-h9">{{ output.lt_lg_explain_base.h9 }}</span>
                                </div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-1-times-3">{{
                                    output.lt_lg_explain_base.times_3
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-1-times-140">{{
                                    output.lt_lg_explain_base.times_140
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; werkelijk
                                  omzetverliespercentage
                                </div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-1-times-j6">{{
                                    output.lt_lg_explain_base.times_j6
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 85%</div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-1-times-85">{{
                                    output.lt_lg_explain_base.times_85
                                  }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het basisbedrag is dus:</div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-1-end">{{ output.lt_lg_explain_base.end }}</span>
                                </div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>
                                Wij gaan uit van de loonsom van februari 2021.
                                De voorwaarden voor dit bedrag staan hierboven.
                              </li>
                              <li>Omdat het gaat om een tegemoetkoming voor drie
                                maanden, wordt de gemaximeerde loonsom
                                vermenigvuldigd met 3.
                              </li>
                              <li>Het bedrag dat daaruit volgt, wordt verhoogd met 40%
                                (forfaitaire verhoging, om kosten als
                                werkgeverspremies, pensioenbijdragen en de opbouw
                                van vakantiegeld te compenseren).
                              </li>
                              <li>Het verhoogde bedrag wordt vermenigvuldigd met het
                                percentage werkelijk omzetverlies.
                              </li>
                              <li>De tegemoetkoming is 85% daarvan.
                              </li>
                            </ul>
                          </ModalWithButton>

                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="basisbedrag-lt-lg"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="basisbedrag-lt-lg" class="form-control" type="text"
                                   placeholder="" readonly v-model="output.basisbedrag_lt_lg">
                          </div>
                        </div>
                      </div>
                      <!-- Verlaging vanwege loonsom -->
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="verlaging-lt-lg">
                            Verlaging wegens een lagere loonsom
                          </label>

                          <ModalWithButton title="Berekening van de verlaging wegens een lagere loonsom"
                                           image="calc">
                            <div class="container-fluid px-0" :calculation="isPdf">
                              <div class="row">
                                <div class="col-8">Loonsom van februari 2021
                                </div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-2-h9">{{ output.lt_lg_explain_lnsm.h9 }}</span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 90% (vrijstelling van
                                  10%)
                                </div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-2-times-90">{{
                                    output.lt_lg_explain_lnsm.times_90
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 3</div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-2-times-3">{{
                                    output.lt_lg_explain_lnsm.times_3
                                  }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">Loonsom van juli, augustus en september 2021
                                </div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-2-j16">{{ output.lt_lg_explain_lnsm.j16 }}</span>
                                </div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">Verschil</div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-2-diff">{{
                                    output.lt_lg_explain_lnsm.diff
                                  }}</span></div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-8">&times; 140%</div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-2-times-140">{{
                                    output.lt_lg_explain_lnsm.times_140
                                  }}</span></div>
                              </div>
                              <div class="row">
                                <div class="col-8">&times; 85%</div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-2-times-85">{{
                                    output.lt_lg_explain_lnsm.times_85
                                  }}</span></div>
                              </div>
                              <br v-if="!isPdf">
                              <div class="row" v-if="!isPdf">
                                <div class="col-8">Het bedrag van de verlaging is
                                  dus:
                                </div>
                                <div class="col-4">&euro; <span
                                    id="lt-lg-explain-2-end">{{ output.lt_lg_explain_lnsm.end }}</span>
                                </div>
                              </div>
                            </div>
                            <br>
                            <h6>Uitleg van de berekening</h6>
                            <ul>
                              <li>Van de loonsom van februari 2021 nemen
                                we 90%. De eerste 10% is namelijk vrijgesteld (naar beneden afgerond, op hele procenten). Deze
                                90% wordt vermenigvuldigd met 3.
                              </li>
                              <li>We nemen het verschil tussen dat bedrag en de
                                opgetelde loonsommen van juli, augustus en
                                september 2021.
                              </li>
                              <li>Het verschil wordt vervolgens vermenigvuldigd met
                                140% (forfaitaire verhoging) &times; 85% (percentage
                                van de tegemoetkoming).
                              </li>
                              <li>Het omzetverliespercentage wordt dus niet in deze berekening
                                meegenomen. Daardoor is dit verlagingsbedrag misschien hoger dan u
                                had verwacht.
                              </li>
                            </ul>
                          </ModalWithButton>

                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="verlaging-lt-lg"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="verlaging-lt-lg" type="text"
                                   placeholder="" readonly v-model="output.verlaging_lt_lg"
                                   v-bind:class="'form-control border-right-0 ' + output.verlaging_lt_lg_class">
                            <div class="input-group-append">
                              <span :class="'input-group-text ' + (output.verlaging_lt_lg_class || 'bg-disabled')">&minus;</span>
                            </div>
                          </div>

                          <hr class="computation-line">
                        </div>
                      </div>
                      <!-- Tegemoetkoming na verlaging -->
                      <div class="form-row">
                        <div class="col-md-7">
                          <label class="mt-1" for="e21-lt-lg">
                            Definitieve tegemoetkoming
                          </label>
                        </div>
                        <div class="col-md-5">
                          <label class="sr-only" for="e21-lt-lg"> </label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">€</div>
                            </div>
                            <input id="e21-lt-lg" class="form-control" type="text" placeholder=""
                                   readonly v-model="output.e21_lt_lg">
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>

                  <!-- Verschil tussen voorschot en definitieve tegemoetkoming -->
                  <b-card class="pt-0">
                    <div class="form-row">
                      <div class="col-md-7">
                        <label class="mt-1" for="e20_out">
                          Ontvangen voorschot
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label class="sr-only" for="e20_out"> </label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">€</div>
                          </div>
                          <input id="e20_out" class="form-control border-right-0" type="text" placeholder="" readonly v-model="e20">
                          <div class="input-group-append">
                            <span class="input-group-text bg-disabled">&minus;</span>
                          </div>
                        </div>

                        <hr class="computation-line">
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-md-7">
                        <label class="mt-1" for="h25">
                          <strong><span id="result-text">{{ output.result_text }}</span></strong>
                        </label>
  
                      </div>
                      <div class="col-md-5">
                        <label class="sr-only" for="h25"> </label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">€</div>
                          </div>
                          <input id="h25" type="text" placeholder="" readonly
                                 v-model="output.h25" v-bind:class="'form-control ' + output.h25_class">
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-card>
              </b-collapse>
            </div> <!-- /end-result-calculator -->
          </b-card>
        </b-collapse><!-- /end #result -->
      </div>
    </div>
    <!--  </div>-->

    <div class="d-flex justify-content-between mb-3">
      <div class="width-more">
        <b-button variant="secondary" @click="$emit('prev')" class="mr-2 mb-2">
          <img src="../../assets/icon/arrow.svg" class="icon-chevron-right-solid rotate-180" alt="arrow" />
          Vorige
        </b-button>
      </div>
      <div class="text-right">
        <b-button variant="outline-warning" class="btn-lg mb-2" v-if="isDevelopment()" @click="devFillForm()">
          Random (DEV)
        </b-button>
        <b-button :variant="isLast?'success':'secondary'" class="btn-lg ml-2 mb-2" v-bind:disabled="!resultsVisible" @click="$emit('overview')">
          <img v-if="isLast" src="../../assets/icon/arrow.svg" class="icon-chevron-right-solid" alt="arrow"/>
          Overzicht
        </b-button>
        <b-button variant="success" class="btn-lg ml-2 mb-2" v-if="!isLast" v-bind:disabled="!resultsVisible" @click="$emit('next')">
          <img src="../../assets/icon/arrow.svg" class="icon-chevron-right-solid" alt="arrow"/>
          Volgende periode
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import {
  currencyFormat, excelFloor,
  fillAndMarkNegativeIfNotZero, isDevelopment, myIsNaN,
  parseNumber, randomBool, randomFloat, randomInt, randomIntLog,
  sanitizeInteger, sum,
} from '@/repository/functions';
import TrancheResult from '@/repository/models/result';
import ResultsRepository from '@/repository/results-repository';
import ModalWithButton from '@/components/ModalComponent.vue';
import SettlementPeriods from "@/repository/settlement-periods";

export default {
  name: 'Tranche6Tool',
  components: { ModalWithButton },
  data() {
    return {
      changeCounter: 0,
      isPdf: false,
      gteJul27_2023: SettlementPeriods.gteJul27_2023(),
      values: {
        werkmaatschappij_onderdeel_van_concern: false,
        j6: '',
        h9: '',
        j12: '',
        j13: '',
        j14: '',
        e20: '',
      },
      error: {
        j6: '',
        h9: '',
        j12: '',
        j13: '',
        j14: '',
        e20: '',
      },
      valid: {
        j6: null,
        h9: null,
        j12: null,
        j13: null,
        j14: null,
        e20: null,
      },
      resultsVisible: false,
      showDetails: false,
      scenariosCalculationVisible: {
        zero: false,
        j6_lt_20: false,
        gte: false,
        lt_sm: false,
        lt_lg: false,
      },
      output: {
        // h20: '',
        // e20: '',
        e21: '',
        h25: '',

        result_text: 'Verschil tussen voorschot en definitieve tegemoetkoming',
        h25_class: '',

        gte_explain_j6: '',
        gte_explain_h9: '',
        gte_explain_times_3: '',
        gte_explain_times_140: '',
        gte_explain_times_j6: '',
        gte_explain_times_85: '',
        gte_explain_e21: '',

        e21_gte: '',

        percentage_daling: '',

        lt_sm_explain_j6: '',
        lt_sm_explain_h9: '',
        lt_sm_explain_times_3: '',
        lt_sm_explain_times_140: '',
        lt_sm_explain_times_j6: '',
        lt_sm_explain_times_85: '',
        lt_sm_explain_e21: '',

        e21_lt_sm: '',

        lt_lg_explain_base: {
          j6: '',
          h9: '',
          times_3: '',
          times_140: '',
          times_j6: '',
          times_85: '',
          end: '',
        },
        basisbedrag_lt_lg: '',
        lt_lg_explain_lnsm: {
          h9: '',
          times_90: '',
          times_3: '',
          j16: '',
          diff: '',
          times_140: '',
          times_85: '',
          end: '',
        },
        verlaging_lt_lg: '',
        verlaging_lt_lg_class: '',
        e21_lt_lg: '',

        def_explain_j6: '',
        def_explain_j16: '',
        def_explain_times_140: '',
        def_explain_times_j6: '',
        def_explain_times_85: '',
        def_explain_e21: '',

        voor_explain_e20: '',

        declaration_type: '',

        settlement_period_open: false,
        settlement_period_passed: false,
        settlement_period_text: '',
      },
    };
  },
  mounted() {
    const result = ResultsRepository.getInstance().retrieve(6);
    if (result !== null) {
      this.werkmaatschappij_onderdeel_van_concern = result.trancheVars.values.werkmaatschappij_onderdeel_van_concern;
      this.j6 = result.trancheVars.values.j6;
      this.h9 = result.trancheVars.values.h9;
      this.j12 = result.trancheVars.values.j12;
      this.j13 = result.trancheVars.values.j13;
      this.j14 = result.trancheVars.values.j14;
      this.e20 = result.trancheVars.values.e20;
    }
    this.output.settlement_period_passed = SettlementPeriods.hasPassed(6);
  },
  props: {
    isLast: Boolean,
  },
  computed: {
    j6: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.changeCounter;
        return this.values.j6;
      },
      set(val) {
        this.values.j6 = sanitizeInteger(val, 0, 100);
        this.calculate();
        this.changeCounter++;
      },
    },
    h9: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.changeCounter;
        return this.values.h9;
      },
      set(val) {
        this.values.h9 = sanitizeInteger(val, 0, 500000000);
        this.calculate();
        this.changeCounter++;
      },
    },
    j12: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.changeCounter;
        return this.values.j12;
      },
      set(val) {
        this.values.j12 = sanitizeInteger(val, 0, 500000000);
        this.calculate();
        this.changeCounter++;
      },
    },
    j13: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.changeCounter;
        return this.values.j13;
      },
      set(val) {
        this.values.j13 = sanitizeInteger(val, 0, 500000000);
        this.calculate();
        this.changeCounter++;
      },
    },
    j14: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.changeCounter;
        return this.values.j14;
      },
      set(val) {
        this.values.j14 = sanitizeInteger(val, 0, 500000000);
        this.calculate();
        this.changeCounter++;
      },
    },
    e20: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.changeCounter;
        return this.values.e20;
      },
      set(val) {
        this.values.e20 = sanitizeInteger(val, 0, 500000000);
        this.calculate();
        this.changeCounter++;
      },
    },
    werkmaatschappij_onderdeel_van_concern: {
      get() {this.changeCounter; return this.values.werkmaatschappij_onderdeel_van_concern;},
      set(val) {this.values.werkmaatschappij_onderdeel_van_concern = val; this.calculate(); this.changeCounter++;},
    },
  },
  methods: {
    isDevelopment,
    /*erase() {
      this.values.werkmaatschappij_onderdeel_van_concern = false;
      this.values.j6 = '';
      this.values.h9 = '';
      this.values.j12 = '';
      this.values.j13 = '';
      this.values.j14 = '';
      this.values.e20 = '';
      this.calculate();
      this.changeCounter++;
    },*/
    allFieldsFilled() {
      return this.values.j6 !== '' && this.values.h9 !== ''
          && this.values.j12 !== '' && this.values.j13 !== '' && this.values.j14 !== ''
          && this.values.e20 !== '';
    },
    validate(validateAll) {
      let hasError = false;

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in this.values) {
        this.error[key] = '';
        this.valid[key] = this.values[key] === '' ? null : true;
      }

      if (validateAll) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.values) {
          if (this.values[key] === '') {
            hasError = true;
            this.error[key] = 'Dit veld is verplicht';
          }
        }
      }

      const extraVal = this.extraValidate();

      return hasError === false && extraVal === false;
    },
    extraValidate() {
      let hasError = false;

      const j6 = parseNumber(this.values.j6);

      if (j6 > 80) {
        hasError = true;
        this.error.j6 = "In de zesde aanvraagperiode NOW geldt voor de berekening van de tegemoetkoming een maximum van 80% omzetverlies. " +
            "Als uw werkelijke omzetverlies meer dan 80% is, vult u hier 80% in.";
      } else if (j6 && !myIsNaN(j6)) {
        this.error.j6 = '';
      }

      return hasError;
    },
    calculate(validateAll) {
      if (this.validate(validateAll) && this.allFieldsFilled()) {
        this.calculateNow();
        this.resultsVisible = true;
      } else {
        this.resultsVisible = false;

        ResultsRepository.getInstance().add(6, null);
        this.$emit('updateResults');
      }
    },
    calculateNow() {
      const p4 = 1.4;
      const p5 = 0.85;
      const p6 = 0.8;
      const p14 = 10; // vrijstellingspercentage

      // TODO: parseNumber nog nodig?
      // const h5 = parseNumber(this.values.h5) / 100; // Verwachte omzetdaling
      const j6 = parseNumber(this.values.j6) / 100; // Gerealiseerde omzetdaling
      const h9 = parseNumber(this.values.h9); // Loonsom februari
      const j12 = parseNumber(this.values.j12); // Loonsom juli
      const j13 = parseNumber(this.values.j13); // Loonsom augustus
      const j14 = parseNumber(this.values.j14); // Loonsom september

      const e20 = parseNumber(this.values.e20); // voorschotbetaling

      const j16 = sum([j12, j13, j14]); // =SUM(J12:J14)

      // var p17 = false;  // =ISBLANK(H9) Aanname dat juni wordt gebruikt
      const p18 = 3 * h9; // =IF((P17=1),(3*H10),(3*H9))
      // Daling loonsom moet 11% of meer zijn
      const p19 = (0.89 * p18) >= j16 ? 'ja' : 'nee'; // =IF((0.9*P18)>J16,"Ja","Nee")
      const p20 = p19 === 'ja' ? j16 : p18; // =IF(P19="Ja",J16,P18)

      // const h20 = (h5 > 0.19) ? sum([h5 * p18 * p4 * p5]) : 0; // =IF((H5>0.19),SUM(H5*P18*P4*P5),0)
      // this.output.h20 = currencyFormat(Math.round(h20)); // Subsidieverlening

      // const e20 = sum([h20 * p6]); // =SUM(H20*P6)
      // this.output.e20 = currencyFormat(Math.round(e20)); // Voorschotbetaling

      // eslint-disable-next-line no-nested-ternary
      const e21 = Math.round(Math.max(
          0,
          (
              j6 > 0.19
                  ? (
                      p19 === 'nee'
                          ? (j6 * p20 * p4 * p5)
                          : (j6 * p18 * p4 * p5) - ((((1 - (p14 / 100)) * p18) - p20) * p4 * p5)
                  )
                  : 0
          )
      ));
      // =MAX(0,IF((J6>0.19),IF(P19="Nee",(J6*P20*P4*P5),
      //  ((J6*P18*P4*P5)-((((1-(P14/100))*P18)-P20)*P4*P5)))),0)
      this.output.e21 = currencyFormat(e21); // Subsidievaststelling

      // geen check of de loonsommen gezet zijn (j16 > 0). Zorgt er namelijk voor dat wanneer
      // j12,13,14 de waarde 0 hebben er geen volledige terugbetaling wordt getoond voor h25 (zou op
      // 0 uitkomen terwijl er een negatief bedrag uit moet komen)
      let h25 = Math.round(e21 - e20); // =IF((P21=1),(E21-E20),0)

      // Nihilstelling: wanneer er geen loonsom is over de subsidiemaanden, dan moet het voorschot
      // worden terugbetaald.
      if (j16 === 0) {
        h25 = -e20;
      }

      const h25Result = this.output.h25 = currencyFormat(h25); // Terugvordering / Nabetaling

      // Set some styling for the final output
      this.output.h25_class = '';
      if (h25 > 0) {
        this.output.result_text = 'Bedrag dat u nog tegoed heeft';
        this.output.h25_class = 'positive';
      }
      if (h25 < 0) {
        this.output.h25_class = 'negative';
        this.output.result_text = 'Bedrag dat u moet terugbetalen';
        this.output.h25 = currencyFormat(h25 * -1);
      }
      if (h25 === 0) {
        this.output.result_text = 'Terugvordering / Nabetaling';
      }

      // ============ Scenarios ============

      this.scenariosCalculationVisible.zero = false;
      this.scenariosCalculationVisible.j6_lt_20 = false;
      this.scenariosCalculationVisible.gte = false;
      this.scenariosCalculationVisible.lt_sm = false;
      this.scenariosCalculationVisible.lt_lg = false;

      if (j16 === 0) { // zero: loonsom jul-sep21 = 0, alles terugbetalen
        this.scenariosCalculationVisible.zero = true;
      } else if (j6 < 0.2) { // j6-lt-20: werkelijke omzetverlies < 20%, alles terugbetalen
        this.scenariosCalculationVisible.j6_lt_20 = true;
      } else if (j16 >= p18) { // gte: loonsom jul-sep21 hoger dan/gelijk aan loonsom feb21
        let explainValue = h9;

        this.output.gte_explain_j6 = Math.round(j6 * 100);
        this.output.gte_explain_h9 = currencyFormat(explainValue);
        this.output.gte_explain_times_3 = currencyFormat(explainValue *= 3);
        this.output.gte_explain_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.gte_explain_times_j6 = currencyFormat(explainValue *= j6);
        this.output.gte_explain_times_85 = currencyFormat(explainValue *= 0.85);
        this.output.gte_explain_e21 = currencyFormat(e21);

        this.output.e21_gte = currencyFormat(e21);

        this.scenariosCalculationVisible.gte = true;
      } else if (p19 === 'nee') { // lt-sm (loonsom jul-sep lager dan loonsom feb, daling <= 10%)
        const percentageDaling = Math.abs((j16 - p18) / p18);
        let explainValue = h9;

        this.output.percentage_daling = excelFloor(percentageDaling * 100);

        this.output.lt_sm_explain_j6 = Math.round(j6 * 100);
        this.output.lt_sm_explain_h9 = currencyFormat(explainValue);
        this.output.lt_sm_explain_times_3 = currencyFormat(explainValue *= 3);
        this.output.lt_sm_explain_times_140 = currencyFormat(explainValue *= 1.4);
        this.output.lt_sm_explain_times_j6 = currencyFormat(explainValue *= j6);
        this.output.lt_sm_explain_times_85 = currencyFormat(explainValue *= 0.85);
        this.output.lt_sm_explain_e21 = currencyFormat(e21);

        this.output.e21_lt_sm = currencyFormat(e21);

        this.scenariosCalculationVisible.lt_sm = true;
      } else if (p19 === 'ja') { // lt-lg loonsom jul-sep21 lager dan loonsom feb21, daling > 10%
        const percentageDaling = Math.abs((j16 - p18) / p18);
        this.output.percentage_daling = excelFloor(percentageDaling * 100);

        const basisbedrag = this.calcBase(h9, j6, this.output.lt_lg_explain_base);
        this.output.basisbedrag_lt_lg = currencyFormat(basisbedrag);

        const verlaging = this.calcLnsm(h9, j16, this.output.lt_lg_explain_lnsm);
        fillAndMarkNegativeIfNotZero(this.output, 'verlaging_lt_lg', verlaging);

        this.output.e21_lt_lg = currencyFormat(e21);

        this.scenariosCalculationVisible.lt_lg = true;
      }

      // ======== Show explanations ========
      // let explainValue = h9;
      // this.output.teg_explain_h5 = Math.round(h5 * 100);
      // this.output.teg_explain_h9 = currencyFormat(explainValue);
      // this.output.teg_explain_times_3 = currencyFormat(explainValue *= 3);
      // this.output.teg_explain_times_140 = currencyFormat(explainValue *= 1.4);
      // this.output.teg_explain_times_h5 = currencyFormat(explainValue *= h5);
      // this.output.teg_explain_times_85 = currencyFormat(explainValue *= 0.85);
      // this.output.teg_explain_h20 = currencyFormat(h20);

      let explainValue = j16;
      this.output.def_explain_j6 = Math.round(j6 * 100);
      this.output.def_explain_j16 = currencyFormat(explainValue);
      this.output.def_explain_times_140 = currencyFormat(explainValue *= 1.4);
      this.output.def_explain_times_j6 = currencyFormat(explainValue *= j6);
      this.output.def_explain_times_85 = currencyFormat(explainValue *= 0.85);
      this.output.def_explain_e21 = currencyFormat(e21);

      // this.output.voor_explain_h20 = currencyFormat(h20);
      this.output.voor_explain_e20 = currencyFormat(e20);

      // Toon als verklaring meegestuurd moet worden bij welke bedragen.
      if(this.values.werkmaatschappij_onderdeel_van_concern || e20>=125000 || e21>=125000){
        this.output.declaration_type = 3;
      } else if (e20>=40000 || e21>=40000){
        this.output.declaration_type = 2;
      } else {
        this.output.declaration_type = 1;
      }

      this.output.settlement_period_open = SettlementPeriods.isOpen(6);
      this.output.settlement_period_passed = SettlementPeriods.hasPassed(6);
      this.output.settlement_period_text = SettlementPeriods.trancheComponentText(6);

      ResultsRepository.getInstance().add(6, new TrancheResult(this.output.declaration_type, h25Result, {
        values: this.values,
        scenariosCalculationVisible: this.scenariosCalculationVisible,
        output: this.output,
        gteJul27_2023: this.gteJul27_2023,
      }));
      this.$emit('updateResults');
    },
    calcBase(h9, j6, values) {
      let basisbedrag = h9;

      values.j6 = Math.round(j6 * 100);
      values.h9 = currencyFormat(basisbedrag);
      values.times_3 = currencyFormat(basisbedrag *= 3);
      values.times_140 = currencyFormat(basisbedrag *= 1.4);
      values.times_j6 = currencyFormat(basisbedrag *= j6);
      values.times_85 = currencyFormat(basisbedrag *= 0.85);
      values.end = currencyFormat(basisbedrag);

      return basisbedrag;
    },
    calcLnsm(h9, j16, values) {
      let verlaging = h9;

      values.h9 = currencyFormat(verlaging);
      values.times_90 = currencyFormat(verlaging *= 0.9);
      values.times_3 = currencyFormat(verlaging *= 3);
      values.j16 = currencyFormat(j16);
      values.diff = currencyFormat(verlaging -= j16);
      values.times_140 = currencyFormat(verlaging *= 1.4);
      values.times_85 = currencyFormat(verlaging *= 0.85);
      values.end = currencyFormat(verlaging);

      return verlaging;
    },
    devFillForm() {
      if (!isDevelopment()) {
        return;
      }
      this.j6 = '' + randomInt(0, 100);
      this.h9 = '' + randomIntLog(0, 500000000);
      this.j12 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.j13 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.j14 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.e20 = '' + Math.round(parseNumber(this.h9) * randomFloat(0.9, 1.1));
      this.werkmaatschappij_onderdeel_van_concern = randomBool();
    }
  },
};
</script>

<style scoped>
div.card-body {
  padding: 0
}

div.card {
  border: 0;
}
</style>
