<template>
  <div class="tranche-tab" :class="active && 'active'" @click="clicked" role="button">
    <div class="tranche-tab-header">
      <div class="row">
        <div class="col-2">
          <h1 class="my-0">{{ number }}<sup>e</sup></h1>
        </div>
        <div class="col-10 align-self-end">
          aanvraagperiode
        </div>
      </div>
      <div class="row">
        <div class="offset-2 col-10">
          <p class="sub-title">{{ period }}</p>
        </div>
      </div>
    </div>
    <div class="tranche-tab-body">
      <div v-if="result !== null">
        <p :class="SettlementPeriods.hasPassed(number) ? '' : 'nabetaling'" v-if="result.amountType === 1">
          + &euro; {{ result.amount }}</p>
        <p :class="SettlementPeriods.hasPassed(number) ? '' : 'terugbetalen'" v-else-if="result.amountType === -1">
          - &euro; {{ result.amount.substring(1) }}</p>
        <p v-else-if="result.amountType === 0">
          &euro; {{ result.amount }}</p>

        <div v-if="result.verklaring !== null">
          <span class="small"> {{ result.verklaring ? result.verklaringText : '' }}</span>
        </div>
        <div>
          <span class="small" v-if="SettlementPeriods.isOpen(number) && openLink"><a :href="openLink" target="_blank">{{ SettlementPeriods.trancheTabText(number) }}</a></span>
          <div v-else>
            <span class="small">{{ SettlementPeriods.trancheTabText(number) }}</span>

            <ModalWithButton v-if="number === 1" title="Loket definitieve berekening gesloten" image="info-blue">
              <p>
                Voor de 1<sup>e</sup> aanvraagperiode NOW kunt u geen definitieve berekening meer aanvragen.
                Als u nog geen definitieve berekening heeft aangevraagd, moet u het ontvangen voorschot terugbetalen.
                <span v-if="!SettlementPeriods.gteJul27_2023()">
                  <br /><br />
                  In uitzonderlijke gevallen kan een werkgever extra tijd krijgen om een definitieve berekening aan te vragen.
                  UWV neemt met die werkgevers contact op.
                </span>
              </p>
            </ModalWithButton>

            <ModalWithButton v-if="number === 2" title="Loket definitieve berekening gesloten" image="info-blue">
              <p>
                Voor de 2<sup>e</sup> aanvraagperiode NOW kunt u geen definitieve berekening meer aanvragen.
                Als u nog geen definitieve berekening heeft aangevraagd, moet u het ontvangen voorschot terugbetalen.
                <span v-if="!SettlementPeriods.gteJul27_2023()">
                  <br /><br />
                  In uitzonderlijke gevallen kan een werkgever extra tijd krijgen om een definitieve berekening aan te vragen.
                  UWV neemt met die werkgevers contact op.
                </span>
              </p>
            </ModalWithButton>
          </div>
        </div>
        <div v-if="result.verklaring === null">&nbsp;</div>

        <p>
          <a class="small" href="" @click="$event.preventDefault();">Gegevens wijzigen</a>
        </p>
      </div>
      <div v-else>
        <p>&nbsp;</p>
        <p class="text-center">
          Vul uw gegevens in
        </p>
        <p>&nbsp;</p>
      </div>
    </div>
    <div class="active-marker text-center">
      <b-icon-triangle-fill flip-v style="color: #002875;"></b-icon-triangle-fill>
    </div>
  </div>
</template>

<script>
import SettlementPeriods from "@/repository/settlement-periods";
import TrancheResult from '@/repository/models/result';
import ModalWithButton from "@/components/ModalComponent";

export default {
  name: 'TrancheTab',
  components: {
    ModalWithButton,
  },
  props: {
    active: Boolean,
    number: Number,
    period: String,
    openLink: String,
    result: TrancheResult,
  },
  methods: {
    clicked() {
      this.$emit('clicked', this.number);
    },
  },
  data() {
    return {
      SettlementPeriods: SettlementPeriods,
    };
  }
};
</script>

<style scoped>
.tranche-tab {
  min-width: calc(68% - 1em);
  border-radius: 4px;
  margin-left: .25em;
  margin-right: .25em;
}

@media (min-width: 576px) {
  .tranche-tab {
    min-width: calc(44% - 1em);
  }
}

@media (min-width: 768px) {
  .tranche-tab {
    min-width: calc(30% - 1em);
  }
}

@media (min-width: 1200px) {
  .tranche-tab {
    min-width: calc(23% - .9em);
  }
}

.tranche-tab:first-of-type {
  margin-left: 0;
}

.tranche-tab:last-of-type {
  margin-right: 0;
}

.tranche-tab > * {
  padding: .5em;
}

.tranche-tab .tranche-tab-header {
  background-color: #7f93bb;
  color: #ffffff;
  border-radius: 5px 5px 0 0;
}

.tranche-tab .tranche-tab-header h1 {
  color: #ffffff;
}

.tranche-tab .tranche-tab-header .sub-title {
  color: #ffffff;
}

.tranche-tab p {
  margin: 0;
  padding: 0;
}

p.nabetaling {
  color: #02924c;
}

p.terugbetalen {
  color: #fd0a21;
}

.tranche-tab.active .tranche-tab-header {
  background-color: #002875;
}

.tranche-tab .active-marker {
  visibility: hidden;
  padding: 0;
}

.tranche-tab.active .active-marker {
  visibility: visible;
}

.tranche-tab .tranche-tab-body {
  background-color: #cdd4e3;
  border-radius: 0 0 5px 5px;
}
</style>
