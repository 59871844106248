<template>
  <div>
    <VueTitle title="NOWchecker: Bereken zelf uw definitieve tegemoetkoming NOW"></VueTitle>

    <header id="header">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 col-lg-10">
            <h1>NOWchecker</h1>
            <router-link :to="{name: 'Home'}" class="btn btn-secondary">
              <img src="../assets/icon/arrow.svg" class="icon-chevron-right-solid rotate-180" alt="arrow" />
              Terug
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <main>
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 col-lg-10">
            <h2>Privacy</h2>

            <b-alert show>
              Graag verwijzen wij u naar ons privacy statement op
              <a href="https://www.uwv.nl/avg-privacystatement" target="_blank">https://www.uwv.nl/avg-privacystatement</a>.
            </b-alert>
          </div>

        </div> <!-- /row -->
      </div> <!-- /container -->

    </main>

    <footer>
      <div id="footer">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12 col-lg-10">
              <div class="d-flex justify-content-between align-content-center flex-wrap">
                <ul class="list-inline">
                  <li>
                    <router-link
                        class="btn btn-link btn-sm link-bullet"
                        :to="{name: 'PrivacyPage'}"
                        target="_blank"
                    >Privacy</router-link>
                  </li>
                </ul>

                <div class="float-right">
                  <ul class="list-inline">
                    <li class="btn-sm">UWV &copy; {{ new Date().getFullYear() }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div> <!-- /row -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import VueTitle from '@/components/TitleComponent.vue';

export default {
  name: 'PrivacyView',
  components: {
    VueTitle,
  },
};
</script>

<style scoped>

</style>
