export default class SettlementPeriods {
    static #openDate = {
        1: new Date(2020, 10 - 1, 7),
        2: new Date(2021, 3 - 1, 15),
        3: new Date(2021, 10 - 1, 4),
        4: new Date(2022, 1 - 1, 31),
        5: new Date(2022, 1 - 1, 31),
        6: new Date(2022, 3 - 1, 31),
        7: new Date(2022, 6 - 1, 1),
        8: new Date(2022, 10 - 1, 3),
    };

    static #closeDate = {
        1: new Date(2021, 10 - 1, 31),
        2: new Date(2022, 3 - 1, 31),
        3: new Date(2023, 2 - 1, 22),
        4: new Date(2023, 2 - 1, 22),
        5: new Date(2023, 2 - 1, 22),
        6: new Date(2023, 2 - 1, 22),
        7: new Date(2023, 6 - 1, 2),
        8: new Date(2023, 6 - 1, 2),
    };

    static #jul27_2023 = new Date(2023, 7 - 1, 27);
    static #nov4_2023 = new Date(2023, 11 - 1, 4);

    static #monthShort = [
        'jan',
        'feb',
        'mrt',
        'apr',
        'mei',
        'jun',
        'jul',
        'aug',
        'sep',
        'okt',
        'nov',
        'dec',
    ];

    static #monthLong = [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
    ];

    static #debugNow = null;

    /**
     * @param {Date} a
     * @param {Date} b
     * @return {int}
     */
    static #compare(a, b) {
        if (a.getFullYear() !== b.getFullYear()) {
            return a.getFullYear() < b.getFullYear() ? -1 : 1;
        }

        if (a.getMonth() !== b.getMonth()) {
            return a.getMonth() < b.getMonth() ? -1 : 1;
        }

        if (a.getDate() !== b.getDate()) {
            return a.getDate() < b.getDate() ? -1 : 1;
        }

        return 0;
    }

    static registerDebugNow() {
        if ((document.URL.indexOf('/test') > -1 || document.URL.indexOf('.local') > -1) && typeof URLSearchParams !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('now')) {
                const now = Date.parse(urlParams.get('now'));
                if (now) {
                    SettlementPeriods.#debugNow = now;
                }
            }
        }
    }

    static #now() {
        if (SettlementPeriods.#debugNow) {
            return new Date(SettlementPeriods.#debugNow);
        }

        return new Date();
    }

    static isOpen(trancheNumber) {
        const now = SettlementPeriods.#now();
        return this.#compare(this.#openDate[trancheNumber], now) <= 0 &&
                this.#compare(now, this.#closeDate[trancheNumber]) <= 0;
    }

    static hasPassed(trancheNumber) {
        const now = SettlementPeriods.#now();
        return this.#compare(now, this.#closeDate[trancheNumber]) > 0;
    }

    static gteJul27_2023() {
        const now = SettlementPeriods.#now();
        return this.#compare(now, this.#jul27_2023) >= 0;
    }

    static gteNov4_2023() {
        const now = SettlementPeriods.#now();
        return this.#compare(now, this.#nov4_2023) >= 0;
    }

    static trancheTabText(trancheNumber) {
        if (this.hasPassed(trancheNumber)) {
            return 'Gesloten';
        }

        const open = this.#openDate[trancheNumber];
        if (!this.isOpen(trancheNumber)) {
            return 'Open vanaf ' + open.getDate() + ' ' + this.#monthShort[open.getMonth()] +  ' ' + open.getFullYear();
        }

        const close = this.#closeDate[trancheNumber];
        return 'Uiterlijk ' + close.getDate() + ' ' + this.#monthShort[close.getMonth()] +  ' ' + close.getFullYear();
    }

    static trancheComponentText(trancheNumber) {
        if (this.isOpen(trancheNumber) || this.hasPassed(trancheNumber)) {
            // The 'hasPassed' situation will be textualized differently
            const close = this.#closeDate[trancheNumber];
            return close.getDate() + ' ' + this.#monthLong[close.getMonth()] +  ' ' + close.getFullYear();
        }

        const open = this.#openDate[trancheNumber];
        return open.getDate() + ' ' + this.#monthLong[open.getMonth()] +  ' ' + open.getFullYear();
    }

    static endOverviewText(trancheNumber) {
        if (this.isOpen(trancheNumber)) {
            const close = this.#closeDate[trancheNumber];
            return 'Uiterlijk ' + close.getDate() + ' ' + this.#monthLong[close.getMonth()] +  ' ' + close.getFullYear();
        }

        const open = this.#openDate[trancheNumber];
        const close = this.#closeDate[trancheNumber];
        return open.getDate() + ' ' + this.#monthLong[open.getMonth()] +  ' ' + open.getFullYear()
            + ' tot en met ' + close.getDate() + ' ' + this.#monthLong[close.getMonth()] +  ' ' + close.getFullYear();
    }
}
