export default class ResultsRepository {
  static instance = null;
  #updateCallbacks = [];

  /**
   * @returns {ResultsRepository}
   */
  static getInstance() {
    if (this.instance === null) {
      this.instance = new ResultsRepository();
    }

    return this.instance;
  }

  /**
   * @type {Object.<number, TrancheResult>}
   */
  #results = {};

  constructor() {
    this.reset();
  }

  /**
   * @param {number} trancheNumber
   * @param {TrancheResult} result
   */
  add(trancheNumber, result) {
    this.#results[trancheNumber] = result;
  }

  /**
   * @returns {TrancheResult|null}
   */
  retrieve(trancheNumber) {
    if (this.#results[trancheNumber]) {
      return this.#results[trancheNumber];
    }

    return null;
  }

  reset() {
    this.#results = {};
  }

  onUpdate(callback) {
    this.#updateCallbacks.push(callback);
  }

  update() {
    for (const callback of this.#updateCallbacks) {
      callback();
    }
  }
}
