export default class TrancheResult {
  static VERKLARING_GEEN = 1;
  static VERKLARING_DERDEN = 2;
  static VERKLARING_ACC = 3;

  /** @var {?int} #verklaring */
  #verklaring;

  /** @var {string} #amount */
  #amount;

  /** @var {number} #amountNumber */
  #amountNumber;

  /** @var {Object} #trancheVars */
  #trancheVars;

  /**
   * @param {?int} verklaring
   * @param {string} amount
   * @param {Object} trancheVars
   */
  constructor(verklaring, amount, trancheVars) {
    this.#verklaring = verklaring;
    this.#amount = amount;

    this.#amountNumber = Number.parseFloat(amount);

    this.#trancheVars = JSON.parse(JSON.stringify(trancheVars));
  }

  get verklaring() {
    return this.#verklaring;
  }

  get verklaringText() {
    if (this.#verklaring === 1) {
      return 'Geen verklaring nodig';
    } else if (this.#verklaring === 2) {
      return 'Derdenverklaring';
    } else if (this.#verklaring === 3) {
      return 'Accountantsverklaring';
    } else {
      return '';
    }
  }

  get amount() {
    return this.#amount;
  }

  get amountType() {
    if (this.#amountNumber > 0.0) {
      return 1;
    }

    if (this.#amountNumber < 0.0) {
      return -1;
    }

    return 0;
  }

  get trancheVars() {
    return this.#trancheVars;
  }
}
