export default class SelectedTranches {
  static instance = null;

  #storeSelection = false;

  /**
   * @returns {SelectedTranches}
   */
  static getInstance() {
    if (this.instance === null) {
      this.instance = new SelectedTranches();
    }

    return this.instance;
  }

  #selection = [];

  constructor() {
    this.#selection = [];

    this.#load();
  }

  add(trancheNumber) {
    if (this.contains(trancheNumber)) {
      return;
    }

    this.#selection.push(trancheNumber);

    this.#store();
  }

  remove(trancheNumber) {
    if (!this.contains(trancheNumber)) {
      return;
    }

    const i = this.#selection.findIndex((value) => value === trancheNumber);
    this.#selection.splice(i, 1);

    this.#store();
  }

  empty() {
    this.#selection = [];

    this.#store();
  }

  isEmpty() {
    return this.#selection.length === 0;
  }

  contains(trancheNumber) {
    return this.#selection.includes(trancheNumber);
  }

  trancheAfter(trancheNumber) {
    if (!this.contains(trancheNumber)) {
      return null;
    }

    const i = this.#selection.findIndex((value) => value === trancheNumber);
    if (i < this.#selection.length - 1) {
      return this.#selection[i + 1];
    }

    return null;
  }

  trancheBefore(trancheNumber) {
    if (trancheNumber === null) {
      let last = null;
      for (last of this.#selection);
      return last;
    }

    if (!this.contains(trancheNumber)) {
      return null;
    }

    const i = this.#selection.findIndex((value) => value === trancheNumber);
    if (i > 0) {
      return this.#selection[i - 1];
    }

    return null;
  }

  first() {
    if (this.#selection.length === 0) {
      return 0;
    }
    return this.#selection.sort()[0];
  }

  #store() {
    if (!this.#storeSelection) {
      return;
    }

    document.cookie = `tranches=${this.#selection.join(',')};samesite=strict`;
  }

  #load() {
    if (!this.#storeSelection) {
      return;
    }

    const cookies = document.cookie.split('; ');
    const i = cookies.findIndex((value) => value.startsWith('tranches='));

    if (i === -1) {
      return;
    }

    const values = cookies[i].substring('tranches='.length).split(',');

    this.#selection = values.map((value) => Number.parseInt(value, 10));
  }
}
