<template>
  <div>
    <b-button variant="null" size="sm" v-model="modalShow" @click="modalShow = !modalShow">
      <img v-if="image === 'calc'" src="../assets/icon/calc.svg" :alt="image"
           width="20" height="20">
      <img v-if="image === 'info'" src="../assets/icon/info.svg" :alt="image"
           width="20" height="20">
      <img v-if="image === 'info-blue'" src="../assets/icon/info-blue.svg" :alt="image"
           width="20" height="20">
    </b-button>
    <b-modal v-model="modalShow" :title="title" hide-footer>
      <slot />
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalWithButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
      validator(value) {
        return ['calc', 'info', 'info-blue'].includes(value);
      },
    },
  },
  data() {
    return {
      modalShow: false,
    };
  },
};
</script>

<style scoped>
div {
  display: inline-block;
}

div > button {
  margin-top: -6px;
}
</style>
