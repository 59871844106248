<template>
  <div>
    <VueTitle title="NOWchecker: Bereken zelf uw definitieve tegemoetkoming NOW"></VueTitle>

    <header id="header">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 col-lg-10">
            <h1>NOWchecker</h1>
            <router-link :to="{name: 'Home'}" class="btn btn-secondary">
              <img src="../assets/icon/arrow.svg" class="icon-chevron-right-solid rotate-180" alt="arrow" />
              Terug
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <main>
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 col-lg-10">
            <h2>Over deze rekenhulp</h2>

            <b-alert show>
              <h3>Hoe werkt het?</h3>
              <p class="mt-1 mb-0">
                <strong>Stap 1:</strong> Verzamel de gegevens die u nodig heeft voor een proefberekening.<br />
                <strong>Stap 2:</strong> Vink de periodes aan waarvoor u een proefberekening wilt maken.<br />
                <strong>Stap 3:</strong> Klik op &lsquo;Verder&rsquo;. U komt nu bij de eerst gekozen periode.<br />
                <strong>Stap 4:</strong> Vul van deze periode alle gegevens in.<br />
                <strong>Stap 5:</strong> Bekijk het resultaat van de proefberekening. Klik op &lsquo;Toon details&rsquo; voor een uitgebreide berekening.<br />
                <strong>Stap 6:</strong> Klik op &lsquo;Volgende periode&rsquo; en herhaal stap 4 en 5.<br />
                <strong>Stap 7:</strong> Alle periodes ingevuld? Klik dan op &lsquo;Overzicht&rsquo; voor alle resultaten op één pagina.<br />
                <strong>Stap 8:</strong> Klik op &lsquo;Download PDF&rsquo; om alle resultaten in één document te bewaren, inclusief berekening en uitleg.<br />
              </p>
            </b-alert>

            <h3>Gegevens die u nodig heeft</h3>

            <p>Voor een proefberekening heeft u de volgende gegevens nodig:</p>
            <ul class="ul-after-p">
              <li>Het werkelijke omzetverlies over de periode in uw aanvraag.</li>
              <li>De loonsom in de vergelijkende maand.</li>
              <li>De loonsom in de NOW-maanden.</li>
              <li>Het voorschot dat u heeft ontvangen.</li>
            </ul>

            <h3>Bedrijfseconomisch ontslag tijdens NOW</h3>
            <p>
              Als u in de 1<sup>e</sup> of 2<sup>e</sup> aanvraagperiode NOW ontslag wegens bedrijfseconomische omstandigheden heeft aangevraagd, heeft dat gevolgen voor de hoogte van uw tegemoetkoming.
              Vink in dit geval het vakje &lsquo;Ik heb bedrijfseconomisch ontslag aangevraagd&rsquo; aan en vul de loonsom in van alle werknemers voor wie u ontslag heeft aangevraagd.
              Vanaf de 3<sup>e</sup> aanvraagperiode NOW geldt dat u een ontslagaanvraag wegens bedrijfseconomische redenen in de betreffende periode via UWV Telefoon NOW had moeten melden.
              Heeft u dat niet gedaan, dan wordt uw tegemoetkoming met 5% verlaagd.
              In deze rekenhulp gaan wij ervan uit dat u die telefonische melding wel heeft gedaan, als u bedrijfseconomisch ontslag heeft aangevraagd.
            </p>

            <b-alert show>
              <div class="d-flex flex-row ">
                <div class=""><img src="../assets/icon/arrow-blue.svg" alt="arrow" /></div>
                <div class=" "><a href="https://www.uwv.nl/werkgevers/overige-onderwerpen/now/index.aspx" target="_blank">Ga naar uwv.nl/now</a> voor de voorwaarden per aanvraagperiode.</div>
              </div>
            </b-alert>

            <h3>Aanvraag op werkmaatschappij-niveau</h3>
            <p>
              Als u onderdeel bent van een groep of concern kunt u onder voorwaarden kiezen om voor de definitieve berekening uit te gaan van het omzetverlies van uw werkmaatschappij,
              in plaats van het omzetverlies van de groep of het concern als geheel.
              Ook dit geeft u aan met een vinkje.
            </p>

            <h3>Accountantsverklaring of derdenverklaring</h3>
            <p>
              Uw aanvraag van de definitieve berekening wordt alleen in behandeling genomen als u de juiste verklaring met de aanvraag meestuurt.
              Dit kan een accountantsverklaring of een derdenverklaring zijn.
              Welke verklaring u meestuurt, hangt af van de hoogte van het ontvangen voorschot én van de geschatte hoogte van de definitieve tegemoetkoming.
              Bent u onderdeel van een groep of concern? Dan gelden de volgende voorwaarden:
            </p>
            <ul class="ul-after-p">
              <li>Bij een aanvraag op concernniveau telt u alle bedragen van alle aanvragen (van de loonheffingennummers) binnen de groep of het concern bij elkaar op. Het totaalbedrag bepaalt of u een accountants- of derdenverklaring nodig heeft.</li>
              <li>Bij een aanvraag op werkmaatschappijniveau (omdat de groep of het concern minder dan 20% omzetverlies heeft) heeft u altijd een accountantsverklaring nodig, ongeacht de hoogte van het voorschot of de geschatte definitieve tegemoetkoming.</li>
            </ul>

            <b-alert show>
              <div class="d-flex flex-row ">
                <div class=""><img src="../assets/icon/arrow-blue.svg" alt="arrow" /></div>
                <div class=""><a href="https://www.uwv.nl/werkgevers/overige-onderwerpen/now/index.aspx" target="_blank">Ga naar uwv.nl/now</a> voor de voorwaarden per aanvraagperiode.
                Daar vindt u ook standaardformulieren voor alle verklaringen.
                </div>
              </div>
            </b-alert>

            <h3>Vraag uw verklaring op tijd aan</h3>
            <p>
              Heeft u een verklaring nodig?
              Neem dan zo snel mogelijk contact op met uw accountant of financieel adviseur.
              Het laten opstellen van een accountantsverklaring of derdenverklaring kan namelijk veel tijd kosten.
              Als u geen verklaring meestuurt met uw aanvraag, of niet de juiste verklaring, moet u het ontvangen voorschot volledig terugbetalen.
            </p>

            <h3>Als u nog een bedrag tegoed heeft</h3>

            <p>
              Als na definitieve berekening blijkt dat uw tegemoetkoming hoger is dan het ontvangen voorschot, heeft u recht op een nabetaling.
              Het exacte bedrag dat u tegoed heeft, staat in de beslissing op uw aanvraag van de definitieve berekening.
              Nadat u deze beslissing heeft ontvangen, maken wij het bedrag zo snel mogelijk naar u over.
            </p>

            <h3>Als u een bedrag moet terugbetalen</h3>
            <p>
              Als blijkt dat u een bedrag moet terugbetalen, hoeft dat niet in één keer.
              U mag het bedrag ook in maandelijkse termijnen terugbetalen.
              Hiervoor spreekt u een betalingsregeling met ons af.
              Het maximale aantal termijnen is 60, dat is dus een periode van 5 jaar.
              Ook kunt u een betaalpauze van maximaal 1 jaar afspreken.
              Het is wel belangrijk dat u eerst een definitieve berekening aanvraagt en wacht op de beslissing op uw aanvraag, voordat u een betalingsregeling of betaalpauze afspreekt.
              Betaal ook nooit zomaar een bedrag aan ons terug, zonder de juiste betaalkenmerken.
              Het duurt dan veel langer voordat de betaling is verwerkt.
            </p>

            <b-alert show>
              <div class="d-flex flex-row ">
                <div class=""><img src="../assets/icon/arrow-blue.svg" alt="arrow" /></div>
                <div class="">Voor elke aanvraagperiode vraagt u apart een betalingsregeling aan.
                <a href="https://inspiratie.uwv.nl/werkgeverschap/betalingsregeling-aanvragen-now" target="_blank">Ga naar het overzicht van alle aanvraagformulieren.</a>
                </div>
              </div>
            </b-alert>

            <b-alert show variant="warning">
              <h3>Disclaimer</h3>
              <p>
                Deze rekenhulp is met veel zorg ontwikkeld, maar uitkomsten kunnen altijd afwijken van de werkelijke definitieve berekening. Aan de uitkomsten kunt u geen rechten ontlenen.
                Heeft u vragen over uw definitieve berekening NOW of over een beslissing die u van UWV heeft ontvangen? Bel dan naar UWV Telefoon NOW via 088 - 898 20 04 (lokaal tarief).
              </p>
            </b-alert>

          </div>

        </div> <!-- /row -->
      </div> <!-- /container -->

    </main>

    <footer>
      <div id="footer">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12 col-lg-10">
              <div class="d-flex justify-content-between align-content-center flex-wrap">
                <ul class="list-inline">
                  <li>
                    <router-link
                        class="btn btn-link btn-sm link-bullet"
                        :to="{name: 'PrivacyPage'}"
                        target="_blank"
                    >Privacy</router-link>
                  </li>
                </ul>

                <div class="float-right">
                  <ul class="list-inline">
                    <li class="btn-sm">UWV &copy; {{ new Date().getFullYear() }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div> <!-- /row -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import VueTitle from '@/components/TitleComponent.vue';

export default {
  name: 'BackPageView',
  components: {
    VueTitle,
  },
};
</script>

<style scoped>

</style>
