// Workaround for the isNan check, which doesn't exists in ie11 and lower
/**
 * @deprecated
 * @param o
 * @returns {boolean}
 */
export function myIsNaN(o) {
  return typeof (o) === 'number' && Number.isNaN(o);
}

export function sum(nums) {
  return nums.reduce((a, b) => a + b);
}

// Check if browser is ie11 or lower
export function msieversion() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');

  // If Internet Explorer, return version number
  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
}

// Check if browser is msedge legacy
export function msedgelegacy() {
  const ua = window.navigator.userAgent;
  const edge = ua.indexOf('Edge');

  return edge > -1;
}

export function applewebkitSafari() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1) {
    return ua.indexOf('chrome') === -1;
  }
  return false;
}

if (!Math.sign) Math.sign = function (x) { return ((x > 0) - (x < 0)) || +x; };
export function excelRound(num) {
  return Math.sign(num) * Math.round(Math.abs(num));
}

export function excelFloor(num) {
  return Math.sign(num) * Math.floor(Math.abs(num));
}

/**
 * @param {number} num
 * @returns {string}
 */
export function currencyFormat(num) {
  let newNum = excelRound(num);
  newNum = newNum.toString();
  if (newNum === 'NaN') {
    return '0';
  }
  return newNum.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export function fillAndMarkNegativeIfNotZero(output, id, value) {
  if (value === 0) {
    // eslint-disable-next-line no-param-reassign
    output[`${id}_class`] = '';
  } else {
    // eslint-disable-next-line no-param-reassign
    output[`${id}_class`] = 'negative';
  }
  // eslint-disable-next-line no-param-reassign
  output[id] = currencyFormat(excelRound(value));
}

export function parseNumber(num) {
  return parseInt(num.replace(/\D/g, ''), 10);
}

export function sanitizeInteger(input, min, max) {
  let value = input.replace(/\D/g, ''); // only allow numbers

  // if the value is not empty check if it's within min and max allowed values
  if (value !== '') {
    value = parseInt(value, 10);

    if (value && typeof min !== 'undefined' && value < min) {
      value = min;
    }
    if (value && typeof max !== 'undefined' && value > max) {
      value = max;
    }
  }

  // Format the number to make it more readable
  value = currencyFormat(value);

  return value;
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function randomInt(min, max) {
  return Math.floor(randomFloat(min, max));
}

export function randomFloat(min, max) {
  return min + Math.random() * (max - min);
}

export function randomIntLog(min, max) {
  if (min <= 0) {
    min = 1;
  }
  return Math.floor(10 ** randomFloat(Math.log10(min), Math.log10(max)));
}

export function randomBool(chance = 0.5) {
  return Math.random() < chance;
}
