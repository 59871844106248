import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFoundView from '@/views/NotFoundView.vue';
import FillView from '@/views/FillView.vue';
import HomeView from '@/views/HomeView.vue';
import SelectedTranches from '@/repository/selected-tranches';
import BackPageView from "@/views/BackPageView";
import PrivacyView from "@/views/PrivacyView";
import SettlementPeriods from "@/repository/settlement-periods";

Vue.use(VueRouter);

let pathPrefix = '/';
if (document.URL.indexOf('/test') > -1) {
  pathPrefix = '/test/';
}

SettlementPeriods.registerDebugNow();

const routes = [
  {
    path: pathPrefix + '',
    name: 'Home',
    component: HomeView,
  },
  {
    path: pathPrefix + 'invullen',
    name: 'Fill',
    component: FillView,
    beforeEnter: (to, from, next) => {
      if (SelectedTranches.getInstance().isEmpty()) {
        return next({ name: 'Home' });
      }

      return next();
    },
  },
  {
    path: pathPrefix + 'over',
    name: 'BackPage',
    component: BackPageView,
  },
  {
    path: pathPrefix + 'privacy',
    name: 'PrivacyPage',
    component: PrivacyView,
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path.endsWith('/') && to.path !== '/') {
    next({
      path: to.path.substring(0, to.path.length - 1),
      hash: to.hash,
      params: to.params,
      query: to.query,
    });
  }

  next();

  window.scrollTo(0, 0);
});

export default router;
