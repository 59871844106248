<template>
  <div>
    <vue-title title="Error 404"></vue-title>
    <div id="main">
      <div class="box">
        <div class="content">
          <h1>Error 404<br/>
            Pagina niet gevonden.</h1>
          <br/>
          <a href="https://www.nowchecker.nl/" class="btn btn-success btn-lg">
            <svg viewBox="0 0 20 22" class="icon-home" width="100%" height="100%">
              <path d="M8 17v-6h4v6h5V9h3L10 0 0 9h3v8z" fill="currentColor"
                    fill-rule="evenodd"></path>
            </svg>
            Naar homepage
          </a>
        </div>
      </div>
    </div>

    <footer>
      <div id="footer">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12 col-lg-10">
              <div class="d-flex justify-content-between align-content-center flex-wrap">
                <ul class="list-inline">
                  <li>
                    <router-link
                        class="btn btn-link btn-sm link-bullet"
                        :to="{name: 'PrivacyPage'}"
                        target="_blank"
                    >Privacy</router-link>
                  </li>
                </ul>

                <div class="float-right">
                  <ul class="list-inline">
                    <li class="btn-sm">UWV &copy; {{ new Date().getFullYear() }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div> <!-- /row -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import VueTitle from '@/components/TitleComponent.vue';

export default {
  name: 'NotFoundView',
  components: { VueTitle },
};
</script>

<style scoped>
* {
  transition: all 0.6s;
}

html {
  height: 100%;
}

body {
  font-family: 'Lato', Tahoma, sans-serif;
  color: #888;
  margin: 0;
}

#main {
  display: table;
  height: 100vh;
  margin: 0 auto;
}

.box {
  display: table-cell;
  vertical-align: middle;
}

.content {
  text-align: left;
  margin-left: 1rem;
}

.content h1 {
  color: #082878;
  font-size: 2.5rem;
  text-align: left;
  font-weight: 800;
  display: inline-block;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  padding-left: 12px;
  animation: type 1.2s alternate infinite;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #28a745;
  border: 1px solid #28a745;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .5rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color
  .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
  margin-left: 15px;
}

.icon-home {
  color: #fff;
  width: 22px;
  height: 22px;
}

@keyframes type {
  from {
    box-shadow: inset 3px 0 0 #082878;
  }
  to {
    box-shadow: inset 3px 0 0 transparent;
  }
}
</style>
