<template>
  <div :class="'tranche-selector' + (SettlementPeriods.hasPassed(number) ? ' tranche-selector-closed' : '')">
    <div class="tranche-closed" v-if="SettlementPeriods.hasPassed(number)">Gesloten</div>
    <label class="check-period-container" :for="number + 'e-tranche'">
      <div class="box bg-blue text-white">
        <div class="row">
          <div class="col-10 col-md-11">
            <h2 class="text-white">{{ number }}<sup>e</sup> aanvraagperiode ({{ tranche }})<br/>
              <span>{{ subTitle }}</span></h2>
          </div>
          <div class="col-2 col-md-1 align-self-center">
            <input type="checkbox" :id="number + 'e-tranche'" @change="toggleTranche(number, $event)"
                   :checked="selectedTranche(number)"/>
            <span class="check-period-overlay"></span>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import SelectedTranches from '@/repository/selected-tranches';
import SettlementPeriods from "@/repository/settlement-periods";

export default {
  name: 'TrancheSelector',
  props: {
    subTitle: String,
    number: Number,
    tranche: String,
  },
  data: () => {
    return {
      SettlementPeriods: SettlementPeriods,
    };
  },
  methods: {
    /**
     * @param {number} trancheNumber
     * @param {InputEvent} event
     */
    toggleTranche: function(trancheNumber, event) {
      if (event.target.checked) {
        SelectedTranches.getInstance().add(trancheNumber);
      } else {
        SelectedTranches.getInstance().remove(trancheNumber);
      }

      this.$emit('checkChange');
    },
    selectedTranche: (trancheNumber) => SelectedTranches.getInstance().contains(trancheNumber),
  },
};
</script>

<style scoped>
.check-period-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-period-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check-period-overlay {
  position: absolute;
  top: -23px;
  /*left: -23px;*/
  right: 33px;
  height: 46px;
  width: 46px;
  background-color: #657caa;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .check-period-overlay {
    left: -23px;
    right: 0;
  }
}

.check-period-container:hover input ~ .check-period-overlay {
  background-color: #7c8aad;
}

.check-period-container input:checked ~ .check-period-overlay {
  background-color: #00a74c;
}

.check-period-container:hover input:checked ~ .check-period-overlay {
  background-color: #008338;
}

.check-period-overlay:after {
  content: '';
  position: absolute;
  display: none;
}

.check-period-container input ~ .check-period-overlay:after {
  display: block;
}

.check-period-container .check-period-overlay:after {
  left: 13px;
  top: 2px;
  width: 21px;
  height: 34px;
  border-color: rgba(255, 255, 255, 0.2);
  border-style: solid;
  border-width: 0 7px 7px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-period-container input:checked ~ .check-period-overlay:after  {
  border-color: white;
}

</style>
