<template>
  <div>
    <div v-if="isPdf">
      <p>{{ current_date }}</p>

      <h1>NOWchecker</h1>

      <p>
        Deze pdf toont alle resultaten in één document, inclusief berekeningen en uitleg.<br />
        <b>Let op:</b> Het document heeft meerdere pagina’s.
      </p>

      <p>
        <b>Disclaimer</b><br />
        Met deze rekenhulp maakt u een proefberekening van uw definitieve tegemoetkoming NOW.
        Aan de uitkomsten kunt u geen rechten ontlenen.
        Heeft u vragen over uw definitieve berekening NOW of over een beslissing die u van UWV heeft ontvangen? Bel dan naar UWV Telefoon NOW via 088 - 898 20 04 (lokaal tarief).
      </p>

      <br />
    </div>

    <b-card id="endOverviewCard">
      <div class="float-right" v-if="!isPdf">
        <span @click="print(true)" role="button" v-if="isDevelopment()" class="mr-2">HTML(DEV)</span>
        <div v-if="printing" class="spinner-border print-spinner" role="status"><span class="sr-only">Loading...</span></div>
        <span v-else @click="print()" role="button"><img class="print-icon" src="../assets/icon/print-blue.svg" alt="result" /></span>
      </div>

      <b-card-title>Uw overzicht van alle aanvraagperiodes</b-card-title>

      <div v-if="!hasAnyTrancheData">
        <p>U heeft nog geen periode ingevuld. Kies een van bovenstaande periodes om een NOW berekening toe te voegen.</p>
      </div>
      <div v-else>
        <p v-if="!isPdf">Hieronder ziet u per gekozen aanvraagperiode NOW de belangrijkste gegevens bij elkaar.</p>

        <p v-if="!isPdf"><b>Let op:</b> Dit is een proefberekening. Om recht te kunnen hebben op een tegemoetkoming moet u altijd een definitieve berekening aanvragen.</p>

        <div class="overview-tranche" v-for="tranche in trancheData" :key="tranche.trancheNumber">
          <div class="font-weight-bold blue">{{ tranche.trancheNumber }}<sup>e</sup> aanvraagperiode NOW ({{ tranche.titleDate }})</div>
          <div class="tranche-result mb-2 mb-sm-0">
            <div class="row">
              <div class="col-12 col-sm-5">
                <span v-if="tranche.trancheResult == null">Nog niet ingevuld</span>
                <span v-if="tranche.trancheResult != null && tranche.trancheResult.amountType > -1">U heeft nog een bedrag tegoed<span class="d-sm-none">:</span></span>
                <span v-if="tranche.trancheResult != null && tranche.trancheResult.amountType == -1">U moet een bedrag terugbetalen<span class="d-sm-none">:</span></span>
              </div>
              <div class="col-sm-1 d-none d-sm-block">:</div>
              <div class="col-6 offset-sm-0 col-sm-4 col-md-3">
                <div v-if="tranche.settlementPeriodPassed && tranche.trancheResult != null">
                  &euro; {{ tranche.trancheResult.amountAbs }}
                </div>
                <div class="tranche-result-zero text-center" v-if="!tranche.settlementPeriodPassed && tranche.trancheResult == null">
                  ?
                </div>
                <div class="tranche-result-nabetaling px-2" v-if="!tranche.settlementPeriodPassed && tranche.trancheResult != null && tranche.trancheResult.amountType == 1">
                  &euro; {{ tranche.trancheResult.amount }}
                </div>
                <div class="tranche-result-terugbetalen px-2" v-if="!tranche.settlementPeriodPassed && tranche.trancheResult != null && tranche.trancheResult.amountType == -1">
                  &euro; {{ tranche.trancheResult.amountAbs }}
                </div>
                <div class="tranche-result-zero px-2" v-if="!tranche.settlementPeriodPassed && tranche.trancheResult != null && tranche.trancheResult.amountType == 0">
                  &euro; {{ tranche.trancheResult.amount }}
                </div>
              </div>
            </div>
          </div>

          <div class="tranche-result mb-2 mb-sm-0" v-if="tranche.trancheResult != null">
            <div class="row">
              <div class="col-12 col-sm-5">
                <span>Verklaring</span><span class="d-sm-none">:</span>
              </div>
              <div class="col-sm-1 d-none d-sm-block">:</div>
              <div class="col-12 col-sm-6">
                <b v-if="tranche.trancheResult.verklaring == TrancheResult.VERKLARING_GEEN">Geen verklaring nodig</b>
                <b v-if="tranche.trancheResult.verklaring == TrancheResult.VERKLARING_DERDEN">
                  <span v-if="isPdf || !tranche.settlementPeriodOpened || !tranche.statementUrl">Derdenverklaring</span>
                  <a v-else :href="tranche.statementUrl" target="_blank">Derdenverklaring</a>
                </b>
                <b v-if="tranche.trancheResult.verklaring == TrancheResult.VERKLARING_ACC">
                  <span v-if="isPdf || !tranche.settlementPeriodOpened || !tranche.statementUrl">Accountantsverklaring</span>
                  <a v-else :href="tranche.statementUrl" target="_blank">Accountantsverklaring</a>
                </b>
              </div>
            </div>
          </div>
          <div class="tranche-result mb-2 mb-sm-0" v-if="tranche.trancheResult != null">
            <div class="row">
              <div class="col-12 col-sm-5">
                <span>Aanvraag definitieve berekening</span><span class="d-sm-none">:</span>
              </div>
              <div class="col-sm-1 d-none d-sm-block">:</div>
              <div class="col-12 col-sm-6">
                <strong v-if="tranche.settlementPeriodPassed">Gesloten</strong>
                <strong v-else>
                  {{ tranche.settlementPeriodText }}
                  <span v-if="!isPdf && tranche.settlementPeriodOpened && tranche.settlementUrl">
                    (<a :href="tranche.settlementUrl" target="_blank">naar formulier</a>)
                  </span>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <div v-if="!isPdf && hasAnyTrancheData" class="my-3">
      <b-button variant="secondary" @click="$emit('prev')" class="mr-2 mb-2">
        <img src="../assets/icon/arrow.svg" class="icon-chevron-right-solid rotate-180" alt="arrow" />
        Vorige
      </b-button>

      <b-button variant="success" @click="print(); $event.currentTarget.blur()" :disabled="printing" class="mb-2">
        <div v-if="printing" class="spinner-border print-spinner" role="status"><span class="sr-only">Loading...</span></div>
        <span v-else>Download PDF</span>
      </b-button>
    </div>

    <form v-if="!isPdf && isDevelopment()" id="printHtmlForm" method="post" action="download-pdf/" target="__blank">
      <input type="hidden" name="html" value="1" />
      <input type="hidden" name="request_id" id="printHtmlFormRequestId" />
      <input type="hidden" name="data" id="printHtmlFormData" />
    </form>
    <form v-if="!isPdf" id="printForm" method="post" action="download-pdf/" target="printIframe">
      <input type="hidden" name="request_id" id="printFormRequestId" />
      <input type="hidden" name="data" id="printFormData" />
    </form>
    <iframe v-if="!isPdf" name="printIframe" class="d-none"></iframe>
  </div>
</template>

<script>
import SelectedTranches from "@/repository/selected-tranches";
import ResultsRepository from "@/repository/results-repository";
import TrancheResult from "@/repository/models/result";
import SettlementPeriods from "@/repository/settlement-periods";
import {isDevelopment} from "@/repository/functions";

export default {
  name: 'EndOverviewComponent',
  mounted() {
    ResultsRepository.getInstance().onUpdate(() => {
      this.updateCounter++;
    });
  },
  data() {
    const settlementPeriodPassed = {};
    const settlementPeriodOpened = {};
    const settlementPeriodText = {};
    for (let tranche = 1; tranche <= 8; tranche++) {
      settlementPeriodPassed[tranche] = SettlementPeriods.hasPassed(tranche);
      settlementPeriodOpened[tranche] = SettlementPeriods.isOpen(tranche) || SettlementPeriods.hasPassed(tranche);
      settlementPeriodText[tranche] = SettlementPeriods.endOverviewText(tranche);
    }

    return {
      updateCounter: 0,
      isPdf: false,
      title_dates: {
        1: 'maart, april en mei 2020',
        2: 'juni, juli, augustus en september 2020',
        3: 'oktober, november en december 2020',
        4: 'januari, februari en maart 2021',
        5: 'april, mei en juni 2021',
        6: 'juli, augustus en september 2021',
        7: 'november en december 2021',
        8: 'januari, februari en maart 2022',
      },
      thirdStatementUrls: {
        1: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/eerste-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-derdenverklaring-nodig-heeft',
        2: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/tweede-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-derdenverklaring-nodig-heeft',
        3: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/derde-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-derdenverklaring-nodig-heeft',
        4: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/vierde-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-derdenverklaring-nodig-heeft',
        5: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/vijfde-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-derdenverklaring-nodig-heeft',
        6: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/zesde-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-derdenverklaring-nodig-heeft',
        7: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/zevende-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-derdenverklaring-nodig-heeft',
        8: false,
      },
      accountantStatementUrls: {
        1: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/eerste-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-accountantsverklaring-nodig-heeft',
        2: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/tweede-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-accountantsverklaring-nodig-heeft',
        3: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/derde-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-accountantsverklaring-nodig-heeft',
        4: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/vierde-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-accountantsverklaring-nodig-heeft',
        5: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/vijfde-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-accountantsverklaring-nodig-heeft',
        6: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/zesde-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-accountantsverklaring-nodig-heeft',
        7: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/zevende-aanvraagperiode-now/detail/accountantsverklaring-of-derdenverklaring/als-u-een-accountantsverklaring-nodig-heeft',
        8: false,
      },
      settlementUrls: {
        1: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/eerste-aanvraagperiode-now/detail/over-de-aanvraag-definitieve-berekening-now',
        2: 'https://www.uwv.nl/werkgevers/overige-onderwerpen/now/tweede-aanvraagperiode-now/detail/over-de-aanvraag-definitieve-berekening-now',
        3: 'https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-derde-aanvraagperiode.aspx',
        4: 'https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-vierde-aanvraagperiode.aspx',
        5: 'https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-vijfde-aanvraagperiode.aspx',
        6: 'https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-zesde-aanvraagperiode.aspx',
        7: 'https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-zevende-aanvraagperiode.aspx',
        8: 'https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-achtste-aanvraagperiode.aspx',
      },
      TrancheResult: TrancheResult,
      settlementPeriodPassed: settlementPeriodPassed,
      settlementPeriodOpened: settlementPeriodOpened,
      settlementPeriodText: settlementPeriodText,
      printing: false,
    };
  },
  props: {},
  computed: {
    selectedTrancheNumbers: function () {
      this.updateCounter;
      const tranches = [];
      for (let i = 1; i <= 8; i++) {
        if (SelectedTranches.getInstance().contains(i)) {
          tranches.push(i);
        }
      }
      return tranches;
    },
    trancheData: function() {
      this.updateCounter;
      return this.getTrancheData();
    },
    hasAnyTrancheData: function() {
      this.updateCounter;

      for (let tranche of this.selectedTrancheNumbers) {
        if (this.trancheData[tranche].trancheResult !== null) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    isDevelopment,
    print(html = false) {
      if (this.printing) {
        return;
      }

      this.printing = true;

      html = html && isDevelopment();

      const data = {};
      for (let tranche of this.selectedTrancheNumbers) {
        const trancheResult = ResultsRepository.getInstance().retrieve(tranche);
        if (trancheResult !== null) {
          data[tranche] = trancheResult.trancheVars;
        }
      }

      data['overview'] = this.getPrintVars();

      const idPrefix = html ? 'printHtmlForm' : 'printForm';
      const requestId = 'pdf' + (+(new Date()));
      document.getElementById(idPrefix + 'Data').setAttribute('value', JSON.stringify(data));
      document.getElementById(idPrefix + 'RequestId').setAttribute('value', requestId);
      document.getElementById(idPrefix + '').submit();

      this.checkPrintLoaded(requestId, new Date());
    },
    checkPrintLoaded(requestId, start) {
      if (document.cookie.indexOf(requestId) > -1 || (new Date() - start) > 60000) {
        this.printing = false;
      } else {
        window.setTimeout(() => this.checkPrintLoaded(requestId, start), 100);
      }
    },
    getTrancheData() {
      const trancheData = {};

      for (let tranche of this.selectedTrancheNumbers) {
        const trancheResult = ResultsRepository.getInstance().retrieve(tranche);

        let settlementUrl = null;
        if (trancheResult && trancheResult.verklaring === TrancheResult.VERKLARING_ACC) {
          settlementUrl = this.accountantStatementUrls[tranche];
        } else if (trancheResult && trancheResult.verklaring === TrancheResult.VERKLARING_DERDEN) {
          settlementUrl = this.thirdStatementUrls[tranche];
        }

        trancheData[tranche] = {
          trancheNumber: tranche,
          trancheResult: trancheResult ? {
            amountType: trancheResult.amountType,
            amount: trancheResult.amount,
            amountAbs: trancheResult.amountType === -1 ? trancheResult.amount.substring(1) : trancheResult.amount,
            verklaring: trancheResult.verklaring,
          } : null,
          titleDate: this.title_dates[tranche],
          statementUrl: settlementUrl,
          settlementUrl: this.settlementUrls[tranche],
          settlementPeriodPassed: this.settlementPeriodPassed[tranche],
          settlementPeriodOpened: this.settlementPeriodOpened[tranche],
          settlementPeriodText: this.settlementPeriodText[tranche],
        };
      }

      return trancheData;
    },
    getPrintVars() {
      const vars = {};

      vars.TrancheResult = {
        VERKLARING_GEEN: TrancheResult.VERKLARING_GEEN,
        VERKLARING_DERDEN: TrancheResult.VERKLARING_DERDEN,
        VERKLARING_ACC: TrancheResult.VERKLARING_ACC,
      };
      vars.trancheData = this.getTrancheData();
      for (let i in vars.trancheData) {
        delete vars.trancheData[i].statementUrl;
        delete vars.trancheData[i].settlementUrl;
      }
      vars.hasAnyTrancheData = this.hasAnyTrancheData;

      return vars;
    }
  },
};
</script>

<style scoped>
.tranche-result-nabetaling {
  background-color: #aecfa7;
}

.tranche-result-terugbetalen {
  background-color: #f09898;
}

.tranche-result-zero {
  border: 1px solid #9eafdd;
}

#endOverviewCard {
  background-color: #cfd5e5;
  border-width: 0;
}
</style>
