<template>
  <div>
    <VueTitle title="NOWchecker: Bereken zelf uw definitieve tegemoetkoming NOW"></VueTitle>

    <header id="header">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 col-lg-10">
            <h1>NOWchecker</h1>
            <p v-if="false">
              Met deze berekening kunt u inschatten hoe hoog het definitieve bedrag van uw
              tegemoetkoming Noodmaatregel Overbrugging Werkgelegenheid (NOW) wordt. Voor de
              geselecteerde NOW periodes berekent u het bedrag dat u ontvangt of terugbetaald, en
              krijgt daarvan een overzicht.
            </p>

            <simplebar>
              <div class="tranche-tabs mb-2" id="trancheTabs">
                <TrancheTab v-if="showTab(1)" :number="1" period="mrt-mei 2020"
                            :active="isActive(1)" @clicked="setActive"
                            :result="trancheData[1]"
                            id="trancheTab1"
                />

                <TrancheTab v-if="showTab(2)" :number="2" period="jun-sep 2020"
                            :active="isActive(2)" @clicked="setActive"
                            openLink="https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-tweede-aanvraagperiode.aspx"
                            :result="trancheData[2]"
                            id="trancheTab2"
                />

                <TrancheTab v-if="showTab(3)" :number="3" period="okt-dec 2020"
                            :active="isActive(3)" @clicked="setActive"
                            openLink="https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-derde-aanvraagperiode.aspx"
                            :result="trancheData[3]"
                            id="trancheTab3"
                />

                <TrancheTab v-if="showTab(4)" :number="4" period="jan-mrt 2021"
                            :active="isActive(4)" @clicked="setActive"
                            openLink="https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-vierde-aanvraagperiode.aspx"
                            :result="trancheData[4]"
                            id="trancheTab4"
                />

                <TrancheTab v-if="showTab(5)" :number="5" period="apr-jun 2021"
                            :active="isActive(5)" @clicked="setActive"
                            openLink="https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-vijfde-aanvraagperiode.aspx"
                            :result="trancheData[5]"
                            id="trancheTab5"
                />

                <TrancheTab v-if="showTab(6)" :number="6" period="jul-sep 2021"
                            :active="isActive(6)" @clicked="setActive"
                            openLink="https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-zesde-aanvraagperiode.aspx"
                            :result="trancheData[6]"
                            id="trancheTab6"
                />

                <TrancheTab v-if="showTab(7)" :number="7" period="nov-dec 2021"
                            :active="isActive(7)" @clicked="setActive"
                            openLink="https://www.uwv.nl/werkgevers/formulieren/aanvragen-definitieve-berekening-now-zevende-aanvraagperiode.aspx"
                            :result="trancheData[7]"
                            id="trancheTab7"
                />

                <TrancheTab v-if="showTab(8)" :number="8" period="jan-mrt 2022"
                            :active="isActive(8)" @clicked="setActive"
                            :result="trancheData[8]"
                            id="trancheTab8"
                />
              </div>
            </simplebar>

            <div class="tranche-panels">
              <keep-alive>
                <Tranche1Tool
                    v-if="isActive(1)"
                    @updateResults="update(1)"
                    @next="openNext(1)"
                    @prev="openPrev(1)"
                    @overview="openEndOverview()"
                    :isLast="isLast(1)"
                />
              </keep-alive>
              <keep-alive>
                <Tranche2Tool
                    v-if="isActive(2)"
                    @updateResults="update(2)"
                    @next="openNext(2)"
                    @prev="openPrev(2)"
                    @overview="openEndOverview()"
                    :isLast="isLast(2)"
                />
              </keep-alive>
              <keep-alive>
                <Tranche3Tool
                    v-if="isActive(3)"
                    @updateResults="update(3)"
                    @next="openNext(3)"
                    @prev="openPrev(3)"
                    @overview="openEndOverview()"
                    :isLast="isLast(3)"
                />
              </keep-alive>
              <keep-alive>
                <Tranche4Tool
                    v-if="isActive(4)"
                    @updateResults="update(4)"
                    @next="openNext(4)"
                    @prev="openPrev(4)"
                    @overview="openEndOverview()"
                    :isLast="isLast(4)"
                />
              </keep-alive>
              <keep-alive>
                <Tranche5Tool
                    v-if="isActive(5)"
                    @updateResults="update(5)"
                    @next="openNext(5)"
                    @prev="openPrev(5)"
                    @overview="openEndOverview()"
                    :isLast="isLast(5)"
                />
              </keep-alive>
              <keep-alive>
                <Tranche6Tool
                    v-if="isActive(6)"
                    @updateResults="update(6)"
                    @next="openNext(6)"
                    @prev="openPrev(6)"
                    @overview="openEndOverview()"
                    :isLast="isLast(6)"
                />
              </keep-alive>
              <keep-alive>
                <Tranche7Tool
                    v-if="isActive(7)"
                    @updateResults="update(7)"
                    @next="openNext(7)"
                    @prev="openPrev(7)"
                    @overview="openEndOverview()"
                    :isLast="isLast(7)"
                />
              </keep-alive>
              <keep-alive>
                <Tranche8Tool
                    v-if="isActive(8)"
                    @updateResults="update(8)"
                    @next="openNext(8)"
                    @prev="openPrev(8)"
                    @overview="openEndOverview()"
                    :isLast="isLast(8)"
                />
              </keep-alive>
              <keep-alive>
                <EndOverviewComponent v-if="isEndOverviewActive()" @prev="openPrev(null)" />
              </keep-alive>
            </div>
          </div>
        </div>
      </div>
    </header>

    <footer>
      <div id="footer">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12 col-lg-10">
              <div class="d-flex justify-content-between align-content-center flex-wrap">
                <ul class="list-inline">
                  <li>
                    <router-link
                        class="btn btn-link btn-sm link-bullet"
                        :to="{name: 'PrivacyPage'}"
                        target="_blank"
                    >Privacy</router-link>
                  </li>
                </ul>

                <div class="float-right">
                  <ul class="list-inline">
                    <li class="btn-sm">UWV &copy; {{ new Date().getFullYear() }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div> <!-- /row -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import VueTitle from '@/components/TitleComponent.vue';
import TrancheTab from '@/components/TrancheTabComponent.vue';
import SelectedTranches from '@/repository/selected-tranches';
import Tranche1Tool from '@/components/tranches/Tranche1Component.vue';
import Tranche2Tool from '@/components/tranches/Tranche2Component.vue';
import Tranche3Tool from '@/components/tranches/Tranche3Component.vue';
import Tranche4Tool from '@/components/tranches/Tranche4Component.vue';
import Tranche5Tool from '@/components/tranches/Tranche5Component.vue';
import Tranche6Tool from '@/components/tranches/Tranche6Component.vue';
import Tranche7Tool from '@/components/tranches/Tranche7Component.vue';
import Tranche8Tool from '@/components/tranches/Tranche8Component.vue';
import EndOverviewComponent from '@/components/EndOverviewComponent.vue';
import ResultsRepository from '@/repository/results-repository';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import router from "@/router";

export default {
  name: 'FillView',
  components: {
    Tranche1Tool,
    Tranche2Tool,
    Tranche3Tool,
    Tranche4Tool,
    Tranche5Tool,
    Tranche6Tool,
    Tranche7Tool,
    Tranche8Tool,
    EndOverviewComponent,
    TrancheTab,
    VueTitle,
    simplebar,
  },
  mounted() {
    // Make sure we scroll to start when opening view
    this.setActive(this.activeTranche);

    const scrollElement = document.getElementsByClassName('simplebar-content-wrapper')[0];
    scrollElement.addEventListener('wheel', function (event) {
      const oldScrollLeft = scrollElement.scrollLeft;

      scrollElement.scrollLeft += event.deltaY;

      if (scrollElement.scrollLeft !== oldScrollLeft) {
        event.preventDefault();
      }
    })
  },
  methods: {
    showTab: (trancheNumber) => SelectedTranches.getInstance().contains(trancheNumber),
    isActive(trancheNumber) {
      return this.activeTranche === trancheNumber;
    },
    isEndOverviewActive() {
      return this.activeTranche === null;
    },
    setActive(trancheNumber) {
      this.activeTranche = trancheNumber;

      window.scrollTo(0, 0);

      const element = document.getElementById('trancheTab' + trancheNumber);
      const scrollContainer = document.getElementsByClassName('simplebar-content-wrapper')[0];
      const elementRect = element.getBoundingClientRect();
      const scrollContainerRect = scrollContainer.getBoundingClientRect();
      const absoluteElementLeft = window.pageXOffset + scrollContainer.scrollLeft + elementRect.left - scrollContainerRect.left + elementRect.width / 2;
      const middle = absoluteElementLeft - (scrollContainerRect.width / 2);
      scrollContainer.scrollTo(middle, 0);
    },
    openEndOverview() {
      this.activeTranche = null;
      window.scrollTo(0, 0);
    },
    openNext(prevTrancheNumber) {
      const nextTrancheNumber = SelectedTranches.getInstance().trancheAfter(prevTrancheNumber);
      if (nextTrancheNumber !== null) {
        this.setActive(nextTrancheNumber);
      } else {
        this.openEndOverview();
      }
    },
    openPrev(trancheNumber) {
      const prevTrancheNumber = SelectedTranches.getInstance().trancheBefore(trancheNumber);
      if (prevTrancheNumber !== null) {
        this.setActive(prevTrancheNumber);
      } else {
        router.push({ name: 'Home' });
      }
    },
    isLast(trancheNumber) {
      const nextTrancheNumber = SelectedTranches.getInstance().trancheAfter(trancheNumber);
      return nextTrancheNumber === null;
    },
    /**
     * @param {number} trancheNumber
     */
    update(trancheNumber) {
      this.trancheData[trancheNumber] = ResultsRepository.getInstance().retrieve(trancheNumber);
      ResultsRepository.getInstance().update();
    },
  },
  data() {
    return {
      activeTranche: SelectedTranches.getInstance().first(),
      trancheData: {
        1: ResultsRepository.getInstance().retrieve(1),
        2: ResultsRepository.getInstance().retrieve(2),
        3: ResultsRepository.getInstance().retrieve(3),
        4: ResultsRepository.getInstance().retrieve(4),
        5: ResultsRepository.getInstance().retrieve(5),
        6: ResultsRepository.getInstance().retrieve(6),
        7: ResultsRepository.getInstance().retrieve(7),
        8: ResultsRepository.getInstance().retrieve(8),
      },
    };
  },
};
</script>

<style scoped>
.tranche-tabs {
  display: flex;
  flex-wrap: nowrap;
}

button.btn-white {
  background-color: white;
}
</style>
