<template>
  <div>
    <VueTitle title="NOWchecker: Bereken zelf uw definitieve tegemoetkoming NOW"></VueTitle>

    <header id="header">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 col-lg-10">
            <h1>NOWchecker</h1>
            <p>
              Maak met deze rekenhulp een proefberekening van uw definitieve tegemoetkoming NOW.
              Per aanvraagperiode ziet u direct tot wanneer u de definitieve berekening kunt indienen.
              En of u een accountantsverklaring of derdenverklaring met de definitieve berekening moet meesturen.
            </p>
            <ul class="check-icons">
              <li class="check-icon">Voor direct inzicht in uw NOW-financi&euml;n </li>
              <li class="check-icon">Aan de hand van uw loonsommen, voorschotbedrag en omzetverlies</li>
              <li class="check-icon">Zodat u weet wat er wanneer van u wordt verwacht</li>
            </ul>
            <p>
              <router-link :to="{name: 'BackPage'}" target="_blank">Meer informatie over deze rekenhulp</router-link>
            </p>
            <p>
              Voor de status van uw NOW-aanvragen, kunt u inloggen in <a href="https://www.uwv.nl/mijnnow" target="_blank">Mijn NOW</a>. 
              Een overzicht van al uw NOW-brieven vindt u terug in het <a href="https://www.uwv.nl/werkgeversportaal" target="_blank">werkgeversportaal</a>. 
              Voor beide portalen heeft u <a href="https://www.uwv.nl/werkgevers/werkgeversportaal/inloggen-eherkenning/index.aspx" target="_blank">eHerkenning</a> nodig.
            </p>
          </div>
        </div>
      </div>
    </header>

    <main>
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 col-lg-10">

            <div class="row">
              <div class="col-12 col-md-8 col-xl-10">
                <h2>Selecteer al uw periodes:</h2>
              </div>
              <div class="col-12 col-md-4 col-xl-2 align-self-end d-flex flex-column">
                <router-link
                    :to="{name: 'Fill'}"
                    :class="'btn btn-success btn-lg align-self-end' + (noSelection ? ' disabled' : '')"
                >
                  <img src="../assets/icon/arrow.svg" class="icon-chevron-right-solid" alt="arrow"/>
                  Verder
                </router-link>
              </div>
            </div>
            <div class="tranches-year">2022</div>
            <TrancheSelector :number="8" tranche="NOW 6" @checkChange="checkChange()" subTitle="januari, februari en maart" />
            <div class="tranches-year">2021</div>
            <TrancheSelector :number="7" tranche="NOW 5" @checkChange="checkChange()" subTitle="november en december" />
            <TrancheSelector :number="6" tranche="NOW 4" @checkChange="checkChange()" subTitle="juli, augustus en september" />
            <TrancheSelector :number="5" tranche="NOW 3.3" @checkChange="checkChange()" subTitle="april, mei en juni" />
            <TrancheSelector :number="4" tranche="NOW 3.2" @checkChange="checkChange()" subTitle="januari, februari en maart" />
            <div class="tranches-year">2020</div>
            <TrancheSelector :number="3" tranche="NOW 3.1" @checkChange="checkChange()" subTitle="oktober, november en december" />
            <TrancheSelector :number="2" tranche="NOW 2" @checkChange="checkChange()" subTitle="juni, juli, augustus en september" />
            <TrancheSelector :number="1" tranche="NOW 1" @checkChange="checkChange()" subTitle="maart, april en mei" />

            <div class="row mb-3">
              <div class="offset-6 offset-xl-10 col-6 col-xl-2 align-self-end d-flex flex-column">
                <router-link
                    :to="{name: 'Fill'}"
                    :class="'btn btn-success btn-lg align-self-end' + (noSelection ? ' disabled' : '')"
                >
                  <img src="../assets/icon/arrow.svg" class="icon-chevron-right-solid" alt="arrow"/>
                  Verder
                </router-link>
              </div>
            </div>
          </div>

        </div> <!-- /row -->
      </div> <!-- /container -->

    </main>

    <footer>
      <div id="footer">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12 col-lg-10">
              <div class="d-flex justify-content-between align-content-center flex-wrap">
                <ul class="list-inline">
                  <li>
                    <router-link
                        class="btn btn-link btn-sm link-bullet"
                        :to="{name: 'PrivacyPage'}"
                        target="_blank"
                    >Privacy</router-link>
                  </li>
                </ul>

                <div class="float-right">
                  <ul class="list-inline">
                    <li class="btn-sm">UWV &copy; {{ new Date().getFullYear() }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div> <!-- /row -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import VueTitle from '@/components/TitleComponent.vue';
import TrancheSelector from '@/components/TrancheSelectorComponent.vue';
import SelectedTranches from "@/repository/selected-tranches";

export default {
  name: 'HomeView',
  components: {
    VueTitle,
    TrancheSelector,
  },
  computed: {
    noSelection: function () {
      this.counter;
      return SelectedTranches.getInstance().isEmpty();
    }
  },
  data: () => {
    return {
      counter: 0,
    }
  },
  methods: {
    checkChange: function () {
      this.counter++;
    }
  }
};
</script>

<style scoped>
  ul.check-icons {
    list-style-image: url("../assets/icon/vink.svg");
    padding-left: 33px;
  }
  li.check-icon  {
    /*margin-left: 5px;*/
  }
  
  li.check-icon::marker  {
    font-size: 2.5rem;
    line-height: .25;
  }
</style>
